<template>
  <div class="evidence-upload-container">
    <UploadBox
      v-show="!uploadView"
      label="Upload Screenshot"
      :dragDrop="true"
      :expanded="true"
      :multiple="true"
      :required="true"
      :localFile="getSelectedUploadedFiles"
      @onChangeFiles="onChangeFiles"
    ></UploadBox>

    <div
      v-show="!uploadView"
      class="evidence-clipboard"
      @paste="onClipboardPaste"
    >
      <img src="@/assets/dmp-entities/paste.png" />
      <p>To Paste Click & Press Ctrl + V</p>
    </div>

    <p v-show="uploadView" class="view-upload-label">Uploaded Screenshot</p>

    <div class="evidence-list-container">
      <div
        class="evidence-list-item"
        v-for="(file, index) in selectedUploadFiles"
        :key="index"
      >
        <p>
          {{ file.name }}
        </p>
        <div class="evidence-list-actions">
          <!-- contains action like view, update, delete -->
          <svg data-name="delete (2)" xmlns="http://www.w3.org/2000/svg" width="11.717" height="14.618" viewBox="0 0 11.717 14.618" @click="handleFileDelete(index)" v-bind:class="'delete-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path data-name="Path 16756" d="M2.034 14.618a.773.773 0 01-.49-.789c-.05-.911-.115-1.82-.176-2.73q-.113-1.678-.229-3.355l-.17-2.488c-.021-.319-.019-.319.308-.319h9.387c.074 0 .1.038.095.109l-.122 1.62-.095 1.307-.164 2.217q-.062.846-.121 1.691l-.149 2.118a.7.7 0 01-.457.619zm1.95-5.05v2.737a.909.909 0 00.023.24.38.38 0 00.4.3.368.368 0 00.364-.319 1.339 1.339 0 00.021-.241V6.854a1.3 1.3 0 00-.023-.255.368.368 0 00-.362-.3.379.379 0 00-.395.284.891.891 0 00-.027.254zm3.729.012V7.755 6.671a.367.367 0 00-.351-.378.38.38 0 00-.419.305.96.96 0 00-.018.212v5.518a.831.831 0 00.018.212.393.393 0 00.466.3.385.385 0 00.306-.433q0-1.416-.002-2.83z" fill="#e10d0d"/><path data-name="Path 16757" d="M5.858 4.144H.238c-.237 0-.237 0-.237-.233v-.713a.949.949 0 01.977-.971h9.703a.958.958 0 011.033 1.038c0 .233-.007.466 0 .7.006.14-.048.18-.181.179H8.609z" fill="#e10d0d"/><path data-name="Path 16758" d="M5.854 1.436H3.616c-.2 0-.212-.008-.212-.205V.518c0-.368.158-.518.523-.518H7.79c.354 0 .5.138.516.495.009.256 0 .513 0 .77 0 .13-.047.175-.176.174-.755-.005-1.516-.003-2.276-.003z" fill="#e10d0d"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="18.78" height="14.619" viewBox="0 0 18.78 14.619" @click="viewFileByIndex(index)" v-bind:class="'view-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(0 -56.735)" fill="#00218a"><circle data-name="Ellipse 1044" cx="3.129" cy="3.129" r="3.129" transform="translate(6.262 60.916)"/><path data-name="Path 16666" d="M18.207 62.026a10.359 10.359 0 00-8.817-5.291 10.359 10.359 0 00-8.816 5.291 3.839 3.839 0 000 4.038 10.359 10.359 0 008.816 5.29 10.359 10.359 0 008.816-5.291 3.839 3.839 0 00.001-4.037zM9.39 68.738a4.693 4.693 0 114.693-4.693 4.693 4.693 0 01-4.693 4.693z"/></g></svg>

          <a :id="`downloadLink${index}`"> </a>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.619" height="14.619" viewBox="0 0 14.619 14.619" @click="downloadFileByIndex(index)" v-bind:class="'download-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.619 14.619H0V13.4h14.619zM8.613 11.64l5.982-6.158h-4.234V0h-6.1v5.482H.043l5.969 6.158a1.792 1.792 0 001.291.542 1.806 1.806 0 001.3-.542z" fill="#00218a"/></svg>
        </div>
      </div>
    </div>

    <div v-if="!isFileSizeValid" class="required-error">
      maximum upload file size 1MB *
    </div>
    <div v-if="!isMaxNoOfFileValid" class="required-error">
      maximum no. of files upload {{ MAX_NO_FILES }} *
    </div>
  </div>
</template>

<script>
import UploadBox from '@/components/DMP/UploadBox.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Snackbar from '@/components/Snackbar'

/** This component contains code that allows for users to upload files and images */
export default {
  name: 'EvidenceFormUpload',
  components: {
    UploadBox
  },

  props: {
    uploadView: Boolean,
    formName: String
  },

  data() {
    return {
      MAX_NO_FILES: 1
    }
  },

  computed: {
    ...mapState('evidence', [
      'isMaxNoOfFileValid',
      'isFileSizeValid',
      'selectedUploadFiles'
    ]),
    ...mapGetters('evidence', ['getSelectedUploadedFiles'])
  },

  methods: {
    ...mapActions('evidence', [
      'updateSelectedUploadFiles',
      'updateChangesInEvidenceFormFlag',
      'deleteFileByIndex'
    ]),

    async onClipboardPaste(e) {
      e.preventDefault() // it disables the default behaviour

      const clipboardItems =
        typeof navigator?.clipboard?.read === 'function'
          ? await navigator.clipboard.read()
          : e.clipboardData.files

      for (const clipboardItem of clipboardItems) {
        let blob
        if (clipboardItem.type?.startsWith('image/')) {
          // For files from `e.clipboardData.files`.
          blob = clipboardItem
          this.appendImageIntoUploadedFiles(blob)
        } else {
          // For files from `navigator.clipboard.read()`.
          const imageTypes = clipboardItem.types?.filter((type) =>
            type.startsWith('image/')
          )
          for (const imageType of imageTypes) {
            blob = await clipboardItem.getType(imageType)
            this.appendImageIntoUploadedFiles(blob)
          }
        }
      }
    },

    appendImageIntoUploadedFiles(blob) {
      // this condition is fallback if the name is undefined when pasting directly
      // also change the image name if they are same
      if (!blob.name) {
        // this part for IE
        let name = 'image.png'
        let index = 1
        this.selectedUploadFiles.forEach((file) => {
          if (name === file.name) {
            name = `image-${index}.png`
            index++
          }
        })
        blob.name = name // assign the calculated name
      } else {
        // this part handle firefox browser
        if (this.selectedUploadFiles && this.selectedUploadFiles.length > 0) {
          let name = 'image.png'
          let index = 0
          this.selectedUploadFiles.forEach((file) => {
            if (name === file.name) {
              name = `image-${index + 1}.png`
              index++
            }
          })
          const fileWithDuplicateName = blob.slice(0, blob.size, 'image/png')
          // assign the calculated name
          blob = new File([fileWithDuplicateName], `${name}`, {
            type: 'image/png'
          })
        }
      }

      const blobArray = [...this.selectedUploadFiles, blob]
      this.onChangeFiles(blobArray)
    },

    viewFileByIndex(index) {
      this.$emit('viewFile', index)
    },

    downloadFileByIndex(index) {
      const url = window.URL.createObjectURL(
        new Blob([this.selectedUploadFiles[index]])
      )
      const link = document.getElementById(`downloadLink${index}`)
      link.href = url
      link.setAttribute('download', `${this.selectedUploadFiles[index].name}`)
      link.click()
      Snackbar({
        message:
          'Your file download request is a success. Please check your system folder for the file.',
        type: 'is-success',
        duration: 3000
      })
    },

    onChangeFiles(files) {
      this.MAX_NO_FILES = this.formName === 'RelationshipsForm' ? 5 : 1
      this.updateSelectedUploadFiles({
        uploadedFiles: files,
        formName: this.formName
      })
      this.updateChangesInEvidenceFormFlag(true)
    },

    handleFileDelete(index) {
      this.deleteFileByIndex(index)
      this.updateChangesInEvidenceFormFlag(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.evidence-upload-container {
  margin-top: 16px;

  .view-upload-label {
    font-size: 14px;
    color: #9b9b9b;
  }

  .evidence-clipboard {
    color: #1138f7;
    border: 1px dashed #1138f7;
    width: 100%;
    border-radius: 4px;
    padding: 12px;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    gap: 8px;
    cursor: pointer;

    img {
      height: 12px;
      width: 12px;
      margin-top: 4px;
    }

    p {
      text-align: center;
      font-size: 14px;
    }
  }

  .evidence-list-container {
    font-size: 14px;
    margin-top: 8px;

    .evidence-list-item {
      display: flex;
      justify-content: space-between;

      .evidence-list-actions {
        .delete-icon {
          cursor: pointer;
          color: #e10d0d;
          margin-right: 10px;
        }

        .view-icon {
          cursor: pointer;
          margin-right: 10px;
        }

        .download-icon {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
