<template>
  <div class="data-grid-container">
    <Grid
      :style="'height: auto;maxWidth: 100%'"
      :data-items="result"
      :sortable="true"
      :sort="sort"
      :columns="columnsData"
      :pageable="gridPageable"
      :resizable="true"
      :take="take"
      :skip="skip"
      :total="total"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
      :loader="loader"
    >
      <template v-slot:viewEvidenceTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          <div
            class="relationships-evidence-contaier"
            v-if="props.dataItem?.relationshipEvd"
          >
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(
                  `${relationshipEvdKey}${props.dataItem.relId}`
                )
              "
              @click.native="
                toggleEvidenceForm(
                  `${relationshipEvdKey}${props.dataItem.relId}`
                )
              "
              :btnLoading="
                getBtnLoadingStatus(
                  `${relationshipEvdKey}${props.dataItem.relId}`
                )
              "
              :isDisabled="false"
              :formName="'Relationship'"
            ></AddEvidence>
          </div>
        </td>
      </template>
      <template v-slot:actionButtonTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          <div class="relationship-button-container">
            <b-icon
              @click.native="deleteRelationship(props.dataItem.relationshipId)"
              class="delete-icon"
              icon="delete"
              size="is-small"
              type="is-danger"
            ></b-icon>
          </div>
        </td>
      </template>
      <template v-slot:sgaIdTemplate="{ props }">
        <td class="k-table-td worktype-column-td" role="gridcell" colspan="1">
          <div
            class="relationship-button-container"
            @click="navigateToViewEntity(props.dataItem.sgaId)"
          >
            {{ props.dataItem.sgaId }}
          </div>
        </td>
      </template>
    </Grid>
  </div>
</template>
<script>
import { orderBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    Grid: Grid,
    AddEvidence
  },
  props: {
    dataItems: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    isSubmittedEntity: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      loader: false,
      sort: [],
      columnsData: this.setColumnsSortable(this.columns),
      inputNameMapping: {},
      relationshipEvdKey: 'Relationship Evidence for REL_ID ',
      skip: 0,
      take: 10,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      }
    }
  },
  computed: {
    ...mapState('evidence', ['evidenceIdList']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    result: {
      get: function () {
        // View evidence mapping on dataItems load
        for (const dataItem of this.dataItems) {
          if (dataItem.relationshipEvd) {
            this.updateEvidenceMapping(
              `${this.relationshipEvdKey}${dataItem.relId}`,
              dataItem.relationshipEvd
            )
          }
        }
        return orderBy(
          this.dataItems.slice(this.skip, this.take + this.skip),
          this.sort
        )
      }
    },
    total() {
      return this.dataItems ? this.dataItems.length : 0
    }
  },
  async mounted() {
    this.resetEvidenceStore()
  },
  methods: {
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore'
    ]),
    ...mapActions('dmp', ['updateEntitiesStatus']),
    deleteRelationship(relationshipId) {
      this.$emit('deleteRelationship', relationshipId)
    },
    setColumnsSortable: function (columns) {
      // Remove 'Action' / Delete Relation column if entity is submitted
      columns = columns?.filter((column) => {
        return this.isSubmittedEntity && column.field === 'Action'
          ? false
          : true
      })
      return columns.map((column) => {
        // Add a 'sortable' property to each column configuration
        return {
          ...column,
          sortable: column.field !== 'Evidence' && column.field !== 'Action'
        }
      })
    },
    pageChangeHandler: function (event) {
      this.loader = true

      setTimeout(() => {
        this.loader = false
        this.skip = event.page.skip
        this.take = event.page.take
      }, 300)
    },
    sortChangeHandler: function (e) {
      this.loader = true
      setTimeout(() => {
        this.loader = false
        this.sort = e.sort
      }, 200)
    },
    async toggleEvidenceForm(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)

      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },
    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },
    updateEvidenceMapping(inputName, evdId) {
      this.updateEvidenceIdList(inputName)
      this.$set(this.inputNameMapping, inputName, evdId)
    },
    async navigateToViewEntity(sgaId) {
      this.$router.push({
        name: 'viewEntity',
        params: {
          viewSGAId: sgaId
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.relationship-button-container {
  color: #f57777;
}

.relationship-button-container:hover {
  cursor: pointer;
  color: #535eeb;
}
.data-grid-container {
  ::v-deep .relationships-evidence-contaier {
    .evidence-button-container {
      .evidence-button {
        height: auto;
        width: auto;
      }
    }
  }
  // .relationship-button-container {
  ::v-deep .mdi-delete::before {
    font-size: 20px;
    cursor: pointer;
  }
  // }

  ::v-deep .k-alt {
    background-color: none;
  }

  ::v-deep .highlight-row {
    background-color: rgb(245, 245, 245);
  }

  ::v-deep .k-column-title {
    font-size: 14px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
    overflow-x: auto;
    text-overflow: ellipsis;
  }

  ::v-deep .k-table-td {
    font-size: 14px;
    letter-spacing: 0.16px;
    color: #000000;
  }

  ::v-deep .k-table-thead {
    background: #dfdfdf 0% 0% no-repeat padding-box;
  }

  ::v-deep .k-columnmenu-item-content {
    display: none;
  }

  ::v-deep .k-table-th {
    vertical-align: middle;
  }
  ::v-deep .k-column-title {
    font-size: 14px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
    white-space: normal;
  }
}
</style>
