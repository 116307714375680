var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasClientData || _vm.hasBVDData)?_c('div',{staticClass:"bvd-container"},[_c('div',{staticClass:"title-row"},[_c('div',{staticClass:"title-container client"},[_c('div',{staticClass:"title"},[_vm._v("Client Attributes")]),(false)?_c('label',{staticClass:"select-all"},[_c('Checkbox',{staticClass:"attr-checkbox",attrs:{"checked":_vm.selectAllClient},on:{"change":_vm.toggleAllClient}}),_vm._v(" Select All ")],1):_vm._e()]),_c('div',{staticClass:"title-container bvd"},[_c('div',{staticClass:"title"},[_vm._v(" BVD Attributes "),(_vm.attributesData?.bvdIdNumber)?_c('span',[_vm._v("("+_vm._s(_vm.attributesData.bvdIdNumber)+")")]):_vm._e()]),(false)?_c('label',{staticClass:"select-all"},[_c('Checkbox',{staticClass:"attr-checkbox",attrs:{"type":"checkbox","checked":_vm.selectAllBVD},on:{"change":_vm.toggleAllBVDDetails}}),_vm._v(" Select All ")],1):_vm._e()])]),_c('div',{staticClass:"attribute-container"},[_c('div',{staticClass:"attribute-item"},[(_vm.hasClientData)?_c('div',{staticClass:"client-identifier-section"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"5"}},[_vm._v(_vm._s(_vm.fieldMapping.identifier.label))])])]),_c('tbody',_vm._l((_vm.clientData?.identifierPairs),function(item,index){return _c('tr',{key:index,staticClass:"identifier-row"},[_c('td',[_c('Checkbox',{class:'attr-checkbox',attrs:{"value":`client_${_vm.fieldMapping.identifier.name}_${index}`,"checked":_vm.fieldMapping.identifier.clientSelected[index],"disabled":_vm.isDisabled(item)},on:{"change":($event) =>
                      _vm.handleFieldSelect(
                        _vm.fieldMapping.identifier.name,
                        item,
                        false,
                        index,
                        $event
                      )}})],1),_c('td',[_vm._v(_vm._s(item.value))]),_c('td',[_vm._v(_vm._s(item.type))])])}),0)])]):_c('div',{staticClass:"client-attributes no-data-message"},[_c('div',[_vm._v("No data available.")])]),(_vm.hasBVDData)?_c('div',{staticClass:"bvd-identifier-section"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"5"}},[_vm._v(_vm._s(_vm.fieldMapping.identifier.label))])])]),_c('tbody',_vm._l((_vm.bvdData?.identifierPairs),function(item,index){return _c('tr',{key:index,staticClass:"identifier-row"},[_c('td',[_c('Checkbox',{class:'attr-checkbox',attrs:{"checked":!_vm.isNull(_vm.nationalIdentifierTypes[index]),"disabled":_vm.isNull(_vm.nationalIdentifierTypes[index])},on:{"change":function($event){return _vm.handleFieldSelect(
                      _vm.fieldMapping.identifier.name,
                      item,
                      true,
                      index,
                      $event
                    )}}})],1),_c('td',[_vm._v(_vm._s(item.value))]),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_c('SearchableSelect',{staticClass:"form-input-field",attrs:{"value":_vm.nationalIdentifierTypes[index],"placeholder":'Select National Identifier Type',"value_field":"value","text_field":"text","data":_vm.nationalITypeOpts},on:{"onChangeName":(value) => {
                      _vm.updateNationalIdentifierType(value, index)
                      _vm.handleFieldSelect(
                        _vm.fieldMapping.identifier.name,
                        item,
                        true,
                        index
                      )
                    }}})],1)])}),0)])]):_c('div',{staticClass:"bvd-attributes no-data-message"},[_c('div',[_vm._v("No data available.")])])]),_c('div',{staticClass:"attribute-item"},[_c('div',{staticClass:"client-attributes"},[(_vm.hasClientData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":_vm.fieldMapping.legalProceedDate.name,"checked":_vm.fieldMapping.legalProceedDate.clientSelected &&
                !_vm.isDisabled(_vm.clientData?.legalProceedDate),"disabled":_vm.isDisabled(_vm.clientData?.legalProceedDate)},on:{"change":function($event){return _vm.handleFieldSelect(
                  _vm.fieldMapping.legalProceedDate.name,
                  _vm.clientData?.legalProceedDate
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.legalProceedDate.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatDate(_vm.clientData?.legalProceedDate)))])])]:_vm._e()],2),_c('div',{staticClass:"bvd-attributes"},[(_vm.hasBVDData)?[_c('table',{staticClass:"legal-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.bvdData?.legalEvents),function(item,index){return _c('tr',{key:index},[_c('td',[_c('RadioButton',{class:'attr-radio attr-radio-td',attrs:{"name":_vm.fieldMapping.legalProceedDate.name,"checked":_vm.fieldMapping.legalProceedDate.bvdSelected[index] &&
                      !_vm.isDisabled(item?.legalProceedDate),"disabled":_vm.isDisabled(item?.legalProceedDate)},on:{"change":function($event){return _vm.handleFieldSelect(
                        _vm.fieldMapping.legalProceedDate.name,
                        item,
                        true,
                        index,
                        $event
                      )}}})],1),_c('td',[_vm._v(_vm._s(item?.legalProcessSource))]),_c('td',[_vm._v(_vm._s(item?.legalProcessDesc))]),_c('td',[_vm._v(" "+_vm._s(Array.isArray(item?.legalProcessValue) ? item?.legalProcessValue.join(', ') : item?.legalProcessValue)+" ")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(item?.legalProceedDate)))])])}),0)])]:_vm._e()],2)]),_c('div',{staticClass:"attribute-item"},[_c('div',{staticClass:"client-attributes"},[(_vm.hasClientData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":_vm.fieldMapping.legalProceedStatus.name,"value":`client_${_vm.fieldMapping.legalProceedStatus.name}`,"checked":_vm.fieldMapping.legalProceedStatus.clientSelected &&
                !_vm.isDisabled(_vm.clientData?.legalProceedStatus),"disabled":_vm.isDisabled(_vm.clientData?.legalProceedStatus)},on:{"change":function($event){return _vm.handleFieldSelect(
                  _vm.fieldMapping.legalProceedStatus.name,
                  _vm.clientData?.legalProceedStatus
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.legalProceedStatus.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.clientData?.legalProceedStatus))])])]:_vm._e()],2),_c('div',{staticClass:"bvd-attributes"},[(_vm.hasBVDData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":_vm.fieldMapping.legalProceedStatus.name,"value":`bvd_${_vm.fieldMapping.legalProceedStatus.name}`,"checked":_vm.fieldMapping.legalProceedStatus.bvdSelected &&
                !_vm.isDisabled(_vm.legalProceedStatusDropDown?.text),"disabled":_vm.isDisabled(_vm.legalProceedStatusDropDown?.text)},on:{"change":function($event){return _vm.handleFieldSelect(
                  _vm.fieldMapping.legalProceedStatus.name,
                  _vm.legalProceedStatusDropDown?.text,
                  true
                )}}})],1),_c('div',{staticClass:"bvd-legal-status"},[_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.legalProceedStatus.label))])]),_c('div',[_c('SearchableSelect',{staticClass:"form-input-field legal-dropdown",attrs:{"value":_vm.legalProceedStatusDropDown,"placeholder":'Select Status of Legal Proceedings',"value_field":"value","text_field":"text","data":_vm.legalProceedStatusOpts},on:{"onChangeName":(value) => {
                    _vm.updateStatusOfLegalProceedings(value)
                    _vm.handleFieldSelect(
                      _vm.fieldMapping.legalProceedStatus.name,
                      value?.text,
                      true
                    )
                  }}})],1)])]:_vm._e()],2)]),_c('div',{staticClass:"attribute-item"},[_c('div',{staticClass:"client-attributes"},[(_vm.hasClientData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'enterpriseSizeDate',"value":'client_enterpriseSizeDate',"checked":_vm.fieldMapping.enterpriseSizeDate.clientSelected &&
                !_vm.isDisabled(_vm.clientData?.enterpriseSizeDate),"disabled":_vm.isDisabled(_vm.clientData?.enterpriseSizeDate)},on:{"change":function($event){return _vm.handleFieldSelect(
                  'enterpriseSizeDate',
                  _vm.clientData?.enterpriseSizeDate
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.enterpriseSizeDate.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatDate(_vm.clientData?.enterpriseSizeDate)))])])]:_vm._e()],2),_c('div',{staticClass:"bvd-attributes"},[(_vm.hasBVDData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'enterpriseSizeDate',"value":'bvd_enterpriseSizeDate',"checked":_vm.fieldMapping.enterpriseSizeDate.bvdSelected &&
                !_vm.isDisabled(_vm.bvdData?.enterpriseSizeDate),"disabled":_vm.isDisabled(_vm.bvdData?.enterpriseSizeDate)},on:{"change":function($event){return _vm.handleFieldSelect(
                  'enterpriseSizeDate',
                  _vm.bvdData?.enterpriseSizeDate,
                  true
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.enterpriseSizeDate.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatDate(_vm.bvdData?.enterpriseSizeDate)))])])]:_vm._e()],2)]),_c('div',{staticClass:"attribute-item"},[_c('div',{staticClass:"client-attributes"},[(_vm.hasClientData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'noOfEmployees',"value":'client_noOfEmployees',"checked":_vm.fieldMapping.noOfEmployees.clientSelected &&
                !_vm.isDisabled(_vm.clientData?.noOfEmployees),"disabled":_vm.isDisabled(_vm.clientData?.noOfEmployees)},on:{"change":function($event){return _vm.handleFieldSelect('noOfEmployees', _vm.clientData?.noOfEmployees)}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.noOfEmployees.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.clientData?.noOfEmployees))])])]:_vm._e()],2),_c('div',{staticClass:"bvd-attributes"},[(_vm.hasBVDData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'noOfEmployees',"value":'bvd_noOfEmployees',"checked":_vm.fieldMapping.noOfEmployees.bvdSelected &&
                !_vm.isDisabled(_vm.bvdData?.noOfEmployees),"disabled":_vm.isDisabled(_vm.bvdData?.noOfEmployees)},on:{"change":function($event){return _vm.handleFieldSelect(
                  'noOfEmployees',
                  _vm.bvdData?.noOfEmployees,
                  true
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.noOfEmployees.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.bvdData?.noOfEmployees))])])]:_vm._e()],2)]),_c('div',{staticClass:"attribute-item"},[_c('div',{staticClass:"client-attributes"},[(_vm.hasClientData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'balanceSheetTotal',"value":'client_balanceSheetTotal',"checked":_vm.fieldMapping.balanceSheetTotal.clientSelected &&
                !_vm.isDisabled(_vm.clientData?.balanceSheetTotal),"disabled":_vm.isDisabled(_vm.clientData?.balanceSheetTotal)},on:{"change":function($event){return _vm.handleFieldSelect(
                  'balanceSheetTotal',
                  _vm.clientData?.balanceSheetTotal
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.balanceSheetTotal.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.formateCurrency(_vm.clientData?.balanceSheetTotal)))])])]:_vm._e()],2),_c('div',{staticClass:"bvd-attributes"},[(_vm.hasBVDData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'balanceSheetTotal',"value":'bvd_balanceSheetTotal',"checked":_vm.fieldMapping.balanceSheetTotal.bvdSelected &&
                !_vm.isDisabled(_vm.bvdData?.balanceSheetTotal),"disabled":_vm.isDisabled(_vm.bvdData?.balanceSheetTotal)},on:{"change":function($event){return _vm.handleFieldSelect(
                  'balanceSheetTotal',
                  _vm.bvdData?.balanceSheetTotal,
                  true
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.balanceSheetTotal.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.formateCurrency(_vm.bvdData?.balanceSheetTotal)))])])]:_vm._e()],2)]),_c('div',{staticClass:"attribute-item"},[_c('div',{staticClass:"client-attributes"},[(_vm.hasClientData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'annualTurnover',"value":'client_annualTurnover',"checked":_vm.fieldMapping.annualTurnover.clientSelected &&
                !_vm.isDisabled(_vm.clientData?.annualTurnover),"disabled":_vm.isDisabled(_vm.clientData?.annualTurnover)},on:{"change":function($event){return _vm.handleFieldSelect(
                  'annualTurnover',
                  _vm.clientData?.annualTurnover
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.annualTurnover.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.formateCurrency(_vm.clientData?.annualTurnover)))])])]:_vm._e()],2),_c('div',{staticClass:"bvd-attributes"},[(_vm.hasBVDData)?[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio',attrs:{"name":'annualTurnover',"value":'bvd_annualTurnover',"checked":_vm.fieldMapping.annualTurnover.bvdSelected &&
                !_vm.isDisabled(_vm.bvdData?.annualTurnover),"disabled":_vm.isDisabled(_vm.bvdData?.annualTurnover)},on:{"change":function($event){return _vm.handleFieldSelect(
                  'annualTurnover',
                  _vm.bvdData?.annualTurnover,
                  true
                )}}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v(_vm._s(_vm.fieldMapping.annualTurnover.label))]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.formateCurrency(_vm.bvdData?.annualTurnover)))])])]:_vm._e()],2)]),(_vm.hasBVDData)?_c('div',{staticClass:"attribute-item"},[_c('div',{staticClass:"client-attributes"}),_c('div',{staticClass:"bvd-attributes"},[_c('div',{staticClass:"radio-group"},[_c('RadioButton',{class:'attr-radio attr-radio-hidden',attrs:{"name":'enterpriseSize',"value":'bvd_enterpriseSize'}})],1),_c('div',{staticClass:"label-group"},[_c('label',{staticClass:"attr-title"},[_vm._v("Enterprise Size")]),_c('label',{staticClass:"value"},[_vm._v(_vm._s(_vm.bvdData?.enterpriseSize))])])])]):_vm._e()]),(_vm.hasClientData || _vm.hasBVDData)?_c('div',{staticClass:"button-container"},[_c('b-button',{staticClass:"action-button cancel-button",attrs:{"size":'is-small'},on:{"click":_vm.resetBVD}},[_vm._v("Reset")]),_c('b-button',{staticClass:"action-button confirm-button",attrs:{"size":'is-small'},on:{"click":_vm.saveData}},[_vm._v("Update Form")])],1):_vm._e()]):_c('div',[_vm._v("No data available.")])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"5"}},[_vm._v("Select One")])]),_c('tr',[_c('th'),_c('th',[_vm._v("Events")]),_c('th',[_vm._v("Event Type")]),_c('th',[_vm._v("Event Source")]),_c('th',[_vm._v("Legal Events Date "),_c('br'),_vm._v("(DD/MM/YYYY)")])])])
}]

export { render, staticRenderFns }