<!-- BVDRelationship.vue -->
<template>
  <div class="bvd-relationship" v-if="isDataPresent">
    <div class="title-container">
      BVD ID:
      <span v-if="attributesData?.bvdIdNumber">{{
        attributesData.bvdIdNumber
      }}</span>
    </div>
    <Grid
      :data-items="gridData"
      :columns="columns"
      :sortable="false"
      :resizable="true"
      :pageSize="pageSize"
      :total="total"
      :skip="skip"
      :take="take"
      :pageable="gridPageable"
      :loader="loading"
      @datastatechange="dataStateChange"
    >
    </Grid>
  </div>
  <div v-else>
    <div v-if="loading">Loading...</div>
    <div v-else>No data available.</div>
  </div>
</template>

<script>
import { process } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
export default {
  name: 'BVDRelationships',
  components: {
    Grid
  },
  props: {
    attributesData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      relationshipData: this.attributesData?.relationships || [],
      gridData: [],
      skip: 0,
      take: 20,
      pageSize: 20,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [5, 10, 20, 50, 100],
        previousNext: true
      },
      columns: [
        {
          field: 'bvdId',
          title: 'BVD ID',
          width: '110px',
          columnMenu: false,
          filterable: false,
          groupable: false
        },
        {
          field: 'cshName',
          title: 'Name',
          width: '160px',
          columnMenu: false,
          filterable: false,
          groupable: false
        },
        {
          field: 'cshLei',
          title: 'LEI',
          width: '180px',
          resizable: true,
          className: 'text-center',
          columnMenu: false,
          filterable: false,
          groupable: false
        },
        {
          field: 'cshCountryIsoCode',
          title: 'Country ISO code',
          width: '90px',
          resizable: true,
          className: 'text-center',
          columnMenu: false,
          filterable: false,
          groupable: false
        },
        {
          field: 'cshStateProvince',
          title: 'State or province',
          width: '85px',
          resizable: true,
          className: 'text-center',
          columnMenu: false,
          filterable: false,
          groupable: true
        },
        {
          field: 'cshDirectPercent',
          title: 'Direct %',
          width: '85px',
          filterable: false,
          groupable: false
        },
        {
          field: 'cshTotalPercent',
          title: 'Total %',
          width: '85px',
          sortable: false,
          columnMenu: false,
          filterable: false,
          groupable: false
        },
        {
          field: 'cshLevel',
          title: 'Level',
          width: '65px',
          sortable: false,
          groupable: false
        },
        {
          field: 'cshInformationSource',
          title: 'Information Source',
          width: '110px',
          sortable: false,
          columnMenu: false,
          filterable: false,
          groupable: false
        },
        {
          field: 'cshInformationDate',
          title: 'Information Date',
          width: '110px',
          sortable: false,
          columnMenu: false,
          filterable: false,
          groupable: false
        }
      ]
    }
  },
  computed: {
    total() {
      return this.relationshipData ? this.relationshipData.length : 0
    },
    isDataLoaded() {
      return !this.loading && this.relationshipData.length > 0
    },
    isDataPresent() {
      return (
        !this.loading &&
        this.relationshipData &&
        this.relationshipData.length > 0
      )
    }
  },
  watch: {
    attributesData: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.relationships) {
          this.relationshipData = newVal.relationships
          this.refreshGrid()
        } else {
          this.relationshipData = []
        }
        this.refreshGrid()
      }
    }
  },
  created: function () {
    this.gridData = this.getData(this.relationshipData)
  },
  methods: {
    refreshGrid() {
      if (this.attributesData && this.attributesData.relationships) {
        this.gridData = this.getData(this.attributesData.relationships)
      }
    },
    createAppState: function (dataState) {
      this.take = dataState.take
      this.skip = dataState.skip

      this.gridData = this.getData(this.relationshipData)
    },
    dataStateChange: function (event) {
      this.createAppState(event.data)
    },
    getData(data) {
      return process(data, {
        take: this.take,
        skip: this.skip
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bvd-relationship {
  font-family: Arial, sans-serif;
}

.title-container {
  color: #f57777;
  padding-bottom: 0.5em;
}

h2 {
  margin-bottom: 20px;
}

.display-info {
  margin-bottom: 20px;
}

.info-row {
  margin-bottom: 10px;
}

.info-label {
  font-weight: bold;
  margin-right: 10px;
}

.save-button-container {
  display: flex;
  flex-direction: row-reverse;
  .button-container {
    display: flex;
    flex-direction: row-reverse;
  }
}
.save-button {
  padding: 10px 20px;
  background: #535eeb 0% 0% no-repeat padding-box;
  font: normal normal 600 14px/17px Quicksand;
  letter-spacing: 0.18px;
  color: #ffffff;
  border-radius: 3px;
}
.save-button:hover {
  background-color: #45a049;
}

::v-deep .k-grid-header {
  padding: 0px !important;
}

::v-deep .k-alt {
  background-color: none;
}

::v-deep .highlight-row {
  background-color: rgb(245, 245, 245);
}

::v-deep .k-label {
  font-weight: 600;
  font-family: 'Quicksand';
}

::v-deep .k-column-title {
  font-size: 14px;
  font-family: Quicksand;
  color: #000000;
  letter-spacing: 0.18px;
  overflow-x: auto;
  text-overflow: ellipsis;
}

::v-deep .k-table-td {
  font-size: 14px;
  letter-spacing: 0.18px;
  font-family: 'Quicksand';
  color: #000000;
  font-weight: 600 !important;
  text-overflow: ellipsis;
}

::v-deep .k-table-thead {
  background: #dfdfdf 0% 0% no-repeat padding-box;
}

::v-deep .k-columnmenu-item-content {
  display: none;
}

::v-deep .k-table-th {
  vertical-align: middle;
}
::v-deep .k-column-title {
  font-size: 14px;
  font-family: Quicksand;
  color: #000000;
  letter-spacing: 0.18px;
  white-space: normal;
}
</style>
