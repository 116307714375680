<template>
  <div>
    <div class="dmp-datepicker-container">
      <div class="wrapper">
        <div class="datepicker-label">
          {{ label }}<span v-if="isMandatory" class="mandatory">*</span>
        </div>
        <b-datepicker
          class="custom-date-picker"
          icon-right-clickable
          @icon-right-click="handleClearDate"
          icon-right="close-circle"
          :placeholder="'Select Date'"
          icon="calendar-today"
          v-model="dateValue"
          @input="handleChange"
          :unselectable-dates="noFutureDate"
          trap-focus>
        </b-datepicker>
      </div>
    </div>
    <div v-if="errorToDisplay" class="required-error">
      {{ errorToDisplay }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'DatePicker',
  components: {},
  props: {
    label: String,
    placeholder: String,
    isMandatory: Boolean,
    isFutureDateAllowed: Boolean,
    date: Date,
    onChangeName: {
      type: Function
    },
    submitted: {
      type: Boolean
    },
    showSecondaryError: {
      type: Boolean,
      default: false
    },
    secondaryError: {
      type: String,
      default: ''
    },
    isDefaultErrorMessage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      value: null
    }
  },
  computed: {
    dateValue: {
      get: function () {
        return this.date
      },
      set: function (newValue) {
        this.date = newValue
      }
    },
    errorToDisplay () {
      if (
        !this.dateValue &&
        this.submitted &&
        this.isMandatory &&
        this.isDefaultErrorMessage
      ) {
        return `${this.label} is required`
      } else if (
        this.submitted &&
        this.showSecondaryError &&
        this.secondaryError
      ) {
        return this.secondaryError
      } else {
        return ''
      }
    }
  },
  methods: {
    handleChange () {
      this.$emit('onChangeDate', this.date)
    },
    handleYearChange () {
      this.$emit('handleYearChange')
    },
    handleMonthChange () {
      this.$emit('handleMonthChange')
    },
    handleClearDate () {
      this.$emit('onChangeDate', null)
    },
    noFutureDate (date) {
      if (this.isFutureDateAllowed) {
        const currentDate = new Date()
        return date > currentDate
      }
    }
  }
}
</script>

<style lang="scss">
.dmp-datepicker-container {
  .wrapper {
    display: flex;
    flex-direction: column;

    .datepicker-label {
      color: #7d7d7d;
      font-size: 12px;
      font-family: Quicksand;
      padding: 0px 20px 0px 0px;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
    .custom-date-picker {
      width: 100%;
      position: relative;
    }
    input {
      color: #1e1e1e;
      font-weight: 600;
      font-size: 13px;
      height: 36px;
      padding: 4px 10px;

      &::placeholder {
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.18px;
        color: #cccccc;
        opacity: 1;
      }
      box-sizing: border-box;
    }
    .datepicker .datepicker-table .datepicker-cell {
      padding: 0rem 0.7rem;
    }
    .mdi-24px.mdi:before {
      font-size: 16px;
    }
    .datepicker .datepicker-header {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .mandatory {
      color: red;
      font-size: 1.25em;
    }
  }
}
@media (min-width: 1450px) and (max-width: 1700px) {
  .dmp-datepicker-container {
    .wrapper {
      .datepicker-label {
        font-size: 14px;
      }
      input {
        font-size: 15px;
      }
    }
  }
}
@media (min-width: 1700px) {
  .dmp-datepicker-container {
    .wrapper {
      .datepicker-label {
        font-size: 16px;
      }
      input {
        font-size: 18px;
      }
    }
  }
}
</style>
