<template>
  <div class="quick-reference">
    <div class="section-title" :class="{ 'collapsed-title': collapsed }">
      <p>Quick Reference: Received Details</p>
      <button class="collapse-button" @click="collapsed = !collapsed">
        <span v-if="collapsed">
          <span class="k-icon k-i-arrow-60-down icon-size"></span>
        </span>
        <span v-else>
          <span class="k-icon k-i-arrow-60-up icon-size"></span>
        </span>
      </button>
    </div>
    <section
      v-show="!collapsed"
      class="section quick-reference-details-content">
      <!-- 1st row Request ID && Legal Entity Name -->
      <div class="two-columns">
        <!-- Request ID -->
        <div class="form-group">
          <label for="requestId">{{ quickReferenceData.requestId.label }}:</label>
          <span class="blue-text" id="requestId" readonly>
            {{ quickReferenceData.requestId.value }}
          </span>
        </div>
        <!-- Legal Entity Name -->
        <div class="form-group">
          <label for="legalEntityName">{{ quickReferenceData.legalEntityName.label }}:</label>
          <span class="blue-text" id="legalEntityName" readonly>
            {{ quickReferenceData.legalEntityName.value }}
          </span>
        </div>
      </div>

      <!-- 2nd row (tax id type, tax id value, lei and anacreditApplicability) -->
      <div class="three-columns">
        <!-- Tax ID Type -->
        <div class="form-group">
          <label for="taxIdType">{{ quickReferenceData.taxIdType.label }}:</label>
          <span class="blue-text" id="taxIdType" readonly>
            {{ quickReferenceData.taxIdType.value }}
          </span>
        </div>
        <!-- Tax ID Value -->
        <div class="form-group">
          <label for="taxId">{{ quickReferenceData.taxId.label }}:</label>
          <span class="blue-text" id="taxId" readonly>
            {{ quickReferenceData.taxId.value }}
          </span>
        </div>
        <!-- LEI -->
        <div class="form-group">
          <label for="lei">{{ quickReferenceData.lei.label }}:</label>
          <span class="blue-text" id="lei" readonly>
            {{ quickReferenceData.lei.value }}
          </span>
        </div>
      </div>
      <div class="single-column">
         <!-- anacreditApplicability -->
        <div class="form-group">
          <label for="anacreditApplicability">{{ quickReferenceData.anacreditApplicability.label }}:</label>
          <span class="blue-text" id="anacreditApplicability" readonly>
            {{ quickReferenceData.anacreditApplicability.value }}
          </span>
        </div>
      </div>

      <!-- 3rd row / Domicile -->
      <div v-show="!collapsed" class="two-columns extra-margin-top">
        <div class="parent-header-label">Domicile</div>
      </div>
      <div class="single-column">
        <!-- Domicile 1st row / Address -->
        <div class="form-group">
          <label for="domicileAddress">{{ quickReferenceData.domicileAddress.label }}:</label>
          <span class="blue-text" id="domicileAddress" readonly>
            {{ quickReferenceData.domicileAddress.value }}
          </span>
        </div>
      </div>
      <!-- Domicile 2nd row -->
      <div class="four-columns">
        <!-- City -->
        <div class="form-group">
          <label for="domicileCityName">{{ quickReferenceData.domicileCityName.label }}:</label>
          <span class="blue-text" id="domicileCityName" readonly>
            {{ quickReferenceData.domicileCityName.value }}
          </span>
        </div>
        <!-- State/Province Code -->
        <div class="form-group">
          <label for="domicileStateProvinceCode">{{
              quickReferenceData.domicileStateProvinceCode.label
            }}:</label>
          <span class="blue-text" id="domicileStateProvinceCode">
            {{ quickReferenceData.domicileStateProvinceCode.value }}
          </span>
        </div>

         <!-- Country Code -->
        <div class="form-group">
          <label for="domicileCountryCodeIso">{{ quickReferenceData.domicileCountryCodeIso.label }}:</label>
          <span class="blue-text" id="domicileCountryCodeIso" readonly>
            {{ quickReferenceData.domicileCountryCodeIso.value }}
          </span>
        </div>
        <!-- Postal Code -->
        <div class="form-group">
          <label for="domicilePostalCode">{{ quickReferenceData.domicilePostalCode.label }}:</label>
          <span class="blue-text" id="domicilePostalCode" readonly>
            {{ quickReferenceData.domicilePostalCode.value }}
          </span>
        </div>
      </div>

      <!-- 4th row / Registration -->
      <div v-show="!collapsed" class="two-columns extra-margin-top">
        <div class="parent-header-label">Registration</div>
      </div>
      <!-- Registration 1st row / Address -->
      <div class="single-column">
        <!-- Address -->
        <div class="form-group">
          <label for="registrationAddress">{{ quickReferenceData.registrationAddress.label }}:</label>
          <span class="blue-text" id="registrationAddress" readonly>
            {{ quickReferenceData.registrationAddress.value }}
          </span>
        </div>
      </div>
      <div class="four-columns">
        <!-- City -->
        <div class="form-group">
          <label for="registrationCityName">{{ quickReferenceData.registrationCityName.label }}:</label>
          <span class="blue-text" id="registrationCityName" readonly>
            {{ quickReferenceData.registrationCityName.value }}
          </span>
        </div>
         <!-- State/Province Code -->
        <div class="form-group">
          <label for="registrationStateProvinceCode">{{
              quickReferenceData.registrationStateProvinceCode.label
            }}:</label>
          <span class="blue-text" id="registrationStateProvinceCode">
            {{ quickReferenceData.registrationStateProvinceCode.value }}
          </span>
        </div>

         <!-- Country Code / ISO -->
        <div class="form-group">
          <label for="registrationCountryCodeIso">{{
              quickReferenceData.registrationCountryCodeIso.label
            }}:</label>
          <span class="blue-text" id="registrationCountryCodeIso">
            {{ quickReferenceData.registrationCountryCodeIso.value }}
          </span>
        </div>

         <!-- Postal Code -->
        <div class="form-group">
          <label for="registrationPostalCode">{{
              quickReferenceData.registrationPostalCode.label
            }}:</label>
          <span class="blue-text" id="registrationPostalCode">
            {{ quickReferenceData.registrationPostalCode.value }}
          </span>
        </div>
      </div>

      <!-- 5th row / Ultimate Parent -->
      <div v-show="!collapsed" class="two-columns extra-margin-top">
        <div class="parent-header-label">Ultimate Parent</div>
      </div>
      <div v-show="!collapsed" class="three-columns">
        <div class="form-group">
          <label for="ultimateParentRequestId">{{ quickReferenceData.ultimateParentRequestId.label }}:</label>
          <span class="blue-text" id="ultimateParentRequestId">
            {{ quickReferenceData.ultimateParentRequestId.value }}
          </span>
        </div>
        <div class="form-group">
          <label for="ultimateParentPartyName">{{ quickReferenceData.ultimateParentPartyName.label }}:</label>
          <span class="blue-text" id="ultimateParentPartyName">
            {{ quickReferenceData.ultimateParentPartyName.value }}
          </span>
        </div>
        <div class="form-group">
          <label for="ultimateParentPartyCountryOfDomicile">{{
              quickReferenceData.ultimateParentPartyCountryOfDomicile.label
            }}:</label>
          <span class="blue-text" id="ultimateParentPartyCountryOfDomicile">
            {{ quickReferenceData.ultimateParentPartyCountryOfDomicile.value }}
          </span>
        </div>
      </div>
      <div class="three-columns">
        <!-- Country of Registration -->
        <div class="form-group">
          <label for="ultimateParentPartyCountryOfRegistration">{{ quickReferenceData.ultimateParentPartyCountryOfRegistration.label }}:</label>
          <span class="blue-text" id="ultimateParentPartyCountryOfRegistration" readonly>
            {{ quickReferenceData.ultimateParentPartyCountryOfRegistration.value }}
          </span>
        </div>
      </div>

      <!-- 6th row / Immediate Parent -->
      <div v-show="!collapsed" class="two-columns extra-margin-top">
        <div class="parent-header-label">Immediate Parent</div>
      </div>

      <div class="three-columns">
        <!-- Request ID -->
        <div class="form-group">
          <label for="immediateParentRequestId">{{ quickReferenceData.immediateParentRequestId.label }}:</label>
          <span class="blue-text" id="immediateParentRequestId" readonly>
            {{ quickReferenceData.immediateParentRequestId.value }}
          </span>
        </div>
        <!-- Party Name -->
        <div class="form-group">
          <label for="immediateParentPartyName">{{ quickReferenceData.immediateParentPartyName.label }}:</label>
          <span class="blue-text" id="immediateParentPartyName" readonly>
            {{ quickReferenceData.immediateParentPartyName.value }}
          </span>
        </div>
        <!-- Party Country Of Domicile -->
        <div class="form-group">
          <label for="immediateParentPartyCountryOfDomicile">{{
              quickReferenceData.immediateParentPartyCountryOfDomicile.label
            }}:</label>
          <span class="blue-text" id="immediateParentPartyCountryOfDomicile">
            {{ quickReferenceData.immediateParentPartyCountryOfDomicile.value }}
          </span>
        </div>
      </div>
      <div class="three-columns">
        <!-- Country of Registration -->
        <div class="form-group">
          <label for="immediateParentPartyCountryOfRegistration">{{ quickReferenceData.immediateParentPartyCountryOfRegistration.label }}:</label>
          <span class="blue-text" id="immediateParentPartyCountryOfRegistration" readonly>
            {{ quickReferenceData.immediateParentPartyCountryOfRegistration.value }}
          </span>
        </div>
      </div>

      <div class="three-columns">
        <div class="form-group">
          <!-- empty -->
        </div>
        <div class="form-group">
          <!-- empty -->
        </div>
        <div class="form-group">
          <label>Reference Document:</label>
          <span @click="downloadProcessFlow" class="doc-link">
            <img
              src="../../assets/dmp-entities/docs.png"
              class="doc-icon"
              alt="info-icon" />
            Process Flow.docx
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'QuickReference',
  props: {
    quickReferenceData: Object
  },
  data () {
    return {
      bestAddress: '',
      countryOfRisk: '',
      countryOfRegistry: '',
      leiNumber: '',
      partyId: '',
      countryOfDomicile: '',
      collapsed: false
    }
  },
  watch: {
    quickReferenceData: {
      handler(newData) {
        const isAutoEnrolledValue = newData.isAutoEnrolled.value;
        if ([null, undefined].includes(isAutoEnrolledValue) || isAutoEnrolledValue === true) {
          this.collapsed = true;
        } else {
          this.collapsed = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
  },

  methods: {
    downloadProcessFlow () {
      const filePath = 'Process Flow.docx'
      const link = document.createElement('a')
      link.href = '/' + filePath
      link.download = filePath
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-reference {
  .section-title {
    text-align: left;
    font: normal normal 14px/18px Quicksand;
    font-weight: 500;
    letter-spacing: 0px;
    color: #888888;
    opacity: 1;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
  }
  .collapse-button {
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
  }
  .collapsed-title {
    background: #dfdfdf 0% 0% no-repeat padding-box;
  }
  .icon-size {
    font-size: 24px;
    color: #7d7d7d;
  }

  .section {
    flex: 1;
    padding: 10px;
    margin-bottom: 20px;
  }

  .quick-reference-details {
    position: relative;
  }

  .quick-reference-details-content {
    border: 1px solid #c4c8f8;
    padding-top: 10px;
    font-size: 0.9rem;
    font-weight: 100;
    .quick-reference-details-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .form-group {
        flex-basis: calc(95% - 20px);
        margin-right: 20px;

        label {
          text-align: left;
          font: normal normal 13px/16px Quicksand;
          font-weight: 500;
          letter-spacing: 0.18px;
          color: #7d7d7d;
          opacity: 1;
        }
        .blue-text {
          text-align: left;
          font: normal normal bold 13px/16px Quicksand;
          letter-spacing: 0.18px;
          color: #535eeb;
          opacity: 1;
        }
        .doc-link {
          cursor: pointer;
          display: inline-flex;
          background: #f1f1f1;
          color: black;
          font-weight: 600;
          padding: 4px;
          border-radius: 4px;
          gap: 5px;
        }
        .doc-icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .single-column {
      width: 100%;
      margin-bottom: 10px;
      .form-group {
        flex-basis: calc(95% - 20px);
        // margin-right: 20px;

        label {
          text-align: left;
          font: normal normal 13px/16px Quicksand;
          font-weight: 500;
          letter-spacing: 0.18px;
          color: #7d7d7d;
          opacity: 1;
        }
        .blue-text {
          text-align: left;
          font: normal normal bold 13px/16px Quicksand;
          letter-spacing: 0.18px;
          color: #535eeb;
          opacity: 1;
        }
        .doc-link {
          cursor: pointer;
          display: inline-flex;
          background: #f1f1f1;
          color: black;
          font-weight: 600;
          padding: 4px;
          border-radius: 4px;
          gap: 5px;
        }
        .doc-icon {
          width: 15px;
          height: 15px;
        }
      }
      .parent-header-label {
        text-align: left;
        font: normal normal 13px/16px Quicksand;
        font-weight: 600;
        letter-spacing: 0.18px;
        color: #f57777;
        opacity: 1;
      }
    }

    .two-columns {
      display: grid;
      grid-template-columns: 28% 68%;
      gap: 2%;
      width: 100%;
      margin-bottom: 10px;
      .form-group {
        flex-basis: calc(95% - 20px);
        // margin-right: 20px;

        label {
          text-align: left;
          font: normal normal 13px/16px Quicksand;
          font-weight: 500;
          letter-spacing: 0.18px;
          color: #7d7d7d;
          opacity: 1;
        }
        .blue-text {
          text-align: left;
          font: normal normal bold 13px/16px Quicksand;
          letter-spacing: 0.18px;
          color: #535eeb;
          opacity: 1;
        }
        .doc-link {
          cursor: pointer;
          display: inline-flex;
          background: #f1f1f1;
          color: black;
          font-weight: 600;
          padding: 4px;
          border-radius: 4px;
          gap: 5px;
        }
        .doc-icon {
          width: 15px;
          height: 15px;
        }
      }
      .parent-header-label {
        text-align: left;
        font: normal normal 13px/16px Quicksand;
        font-weight: 600;
        letter-spacing: 0.18px;
        color: #f57777;
        opacity: 1;
      }
    }

    .three-columns {
      display: grid;
      // grid-template-columns: 31% 31% 37%;
      grid-template-columns: 28% 34% 34%;
      gap: 2%;
      width: 100%;
      margin-bottom: 10px;
      .form-group {
        flex-basis: calc(95% - 20px);
        // margin-right: 20px;

        label {
          text-align: left;
          font: normal normal 13px/16px Quicksand;
          font-weight: 500;
          letter-spacing: 0.18px;
          color: #7d7d7d;
          opacity: 1;
        }
        .blue-text {
          text-align: left;
          font: normal normal bold 13px/16px Quicksand;
          letter-spacing: 0.18px;
          color: #535eeb;
          opacity: 1;
        }
        .doc-link {
          cursor: pointer;
          display: inline-flex;
          background: #f1f1f1;
          color: black;
          font-weight: 600;
          padding: 4px;
          border-radius: 4px;
          gap: 5px;
        }
        .doc-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
    .four-columns {
      display: grid;
      // grid-template-columns: 31% 31% 37%;
      grid-template-columns: 28% 25% 20% 20%;
      gap: 2%;
      width: 100%;
      margin-bottom: 10px;
      .form-group {
        flex-basis: calc(95% - 20px);
        // margin-right: 20px;

        label {
          text-align: left;
          font: normal normal 13px/16px Quicksand;
          font-weight: 500;
          letter-spacing: 0.18px;
          color: #7d7d7d;
          opacity: 1;
        }
        .blue-text {
          text-align: left;
          font: normal normal bold 13px/16px Quicksand;
          letter-spacing: 0.18px;
          color: #535eeb;
          opacity: 1;
        }
        .doc-link {
          cursor: pointer;
          display: inline-flex;
          background: #f1f1f1;
          color: black;
          font-weight: 600;
          padding: 4px;
          border-radius: 4px;
          gap: 5px;
        }
        .doc-icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .extra-margin-top {
      // margin-top: 1rem;
      padding-top: 0.9rem;
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .quick-reference {
    .section-title {
      font: normal normal 15px/21px Quicksand;
    }
    .quick-reference-details-content {
      .two-columns {
        .form-group {
          label {
            font: normal normal 15px/19px Quicksand;
          }
          .blue-text {
            font: normal normal bold 15px/19px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
      }
      .parent-header-label {
        font: normal normal 15px/19px Quicksand;
      }

      .three-columns {
        .form-group {
          label {
            font: normal normal 15px/19px Quicksand;
          }
          .blue-text {
            font: normal normal bold 15px/19px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
      }

      .single-column {
        .form-group {
          label {
            font: normal normal 15px/19px Quicksand;
          }
          .blue-text {
            font: normal normal bold 15px/19px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
        .parent-header-label {
        font: normal normal 15px/19px Quicksand;
      }
      }

      .four-columns {
        .form-group {
          label {
            font: normal normal 15px/19px Quicksand;
          }
          .blue-text {
            font: normal normal bold 15px/19px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
@media (min-width: 1700px) {
  .quick-reference {
    .section-title {
      font: normal normal 19px/24px Quicksand;
    }
    .quick-reference-details-content {
      .two-columns {
        .form-group {
          label {
            font: normal normal 18px/23px Quicksand;
          }
          .blue-text {
            font: normal normal bold 18px/23px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
        .parent-header-label {
          font: normal normal 15px/19px Quicksand;
          font-weight: 600;
          letter-spacing: 0.18px;
          color: #f57777;
          opacity: 1;
        }
      }

      .three-columns {
        .form-group {
          label {
            font: normal normal 18px/23px Quicksand;
          }
          .blue-text {
            font: normal normal bold 18px/23px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
      }

      .single-column {
        .form-group {
          label {
            font: normal normal 18px/23px Quicksand;
          }
          .blue-text {
            font: normal normal bold 18px/23px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
        .parent-header-label {
          font: normal normal 15px/19px Quicksand;
          font-weight: 600;
          letter-spacing: 0.18px;
          color: #f57777;
          opacity: 1;
        }
      }

      .four-columns {
        .form-group {
          label {
            font: normal normal 18px/23px Quicksand;
          }
          .blue-text {
            font: normal normal bold 18px/23px Quicksand;
          }
          .doc-link {
            cursor: pointer;
            display: inline-flex;
            background: #f1f1f1;
            color: black;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            gap: 5px;
          }
          .doc-icon {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
</style>
