<template>
  <div class="bvd-container" v-if="hasClientData || hasBVDData">
    <div class="title-row">
      <div class="title-container client">
        <div class="title">Client Attributes</div>
        <label class="select-all" v-if="false">
          <Checkbox
            :checked="selectAllClient"
            @change="toggleAllClient"
            class="attr-checkbox"
          />
          Select All
        </label>
      </div>
      <div class="title-container bvd">
        <div class="title">
          BVD Attributes
          <span v-if="attributesData?.bvdIdNumber"
            >({{ attributesData.bvdIdNumber }})</span
          >
        </div>
        <label class="select-all" v-if="false">
          <Checkbox
            type="checkbox"
            :checked="selectAllBVD"
            @change="toggleAllBVDDetails"
            class="attr-checkbox"
          />
          Select All
        </label>
      </div>
    </div>
    <div class="attribute-container">
      <div class="attribute-item">
        <div class="client-identifier-section" v-if="hasClientData">
          <table>
            <thead>
              <tr>
                <th colspan="5">{{ fieldMapping.identifier.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in clientData?.identifierPairs"
                :key="index"
                class="identifier-row"
              >
                <td>
                  <Checkbox
                    @change="
                      ($event) =>
                        handleFieldSelect(
                          fieldMapping.identifier.name,
                          item,
                          false,
                          index,
                          $event
                        )
                    "
                    :value="`client_${fieldMapping.identifier.name}_${index}`"
                    :class="'attr-checkbox'"
                    :checked="fieldMapping.identifier.clientSelected[index]"
                    :disabled="isDisabled(item)"
                  />
                </td>
                <td>{{ item.value }}</td>
                <td>{{ item.type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="client-attributes no-data-message" v-else>
          <div>No data available.</div>
        </div>
        <div class="bvd-identifier-section" v-if="hasBVDData">
          <table>
            <thead>
              <tr>
                <th colspan="5">{{ fieldMapping.identifier.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in bvdData?.identifierPairs"
                :key="index"
                class="identifier-row"
              >
                <td>
                  <Checkbox
                    @change="
                      handleFieldSelect(
                        fieldMapping.identifier.name,
                        item,
                        true,
                        index,
                        $event
                      )
                    "
                    :class="'attr-checkbox'"
                    :checked="!isNull(nationalIdentifierTypes[index])"
                    :disabled="isNull(nationalIdentifierTypes[index])"
                  />
                </td>
                <td>{{ item.value }}</td>
                <td>{{ item.type }}</td>
                <td>
                  <SearchableSelect
                    :value="nationalIdentifierTypes[index]"
                    :placeholder="'Select National Identifier Type'"
                    value_field="value"
                    text_field="text"
                    class="form-input-field"
                    @onChangeName="
                      (value) => {
                        updateNationalIdentifierType(value, index)
                        handleFieldSelect(
                          fieldMapping.identifier.name,
                          item,
                          true,
                          index
                        )
                      }
                    "
                    :data="nationalITypeOpts"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bvd-attributes no-data-message" v-else>
          <div>No data available.</div>
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <template v-if="hasClientData">
            <div class="radio-group">
              <RadioButton
                :name="fieldMapping.legalProceedDate.name"
                :checked="
                  fieldMapping.legalProceedDate.clientSelected &&
                  !isDisabled(clientData?.legalProceedDate)
                "
                @change="
                  handleFieldSelect(
                    fieldMapping.legalProceedDate.name,
                    clientData?.legalProceedDate
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(clientData?.legalProceedDate)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.legalProceedDate.label
              }}</label>
              <label class="value">{{
                formatDate(clientData?.legalProceedDate)
              }}</label>
            </div>
          </template>
        </div>
        <div class="bvd-attributes">
          <template v-if="hasBVDData">
            <table class="legal-table">
              <thead>
                <tr>
                  <th colspan="5">Select One</th>
                </tr>
                <tr>
                  <th></th>
                  <th>Events</th>
                  <th>Event Type</th>
                  <th>Event Source</th>
                  <th>Legal Events Date <br />(DD/MM/YYYY)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in bvdData?.legalEvents" :key="index">
                  <td>
                    <RadioButton
                      :name="fieldMapping.legalProceedDate.name"
                      :checked="
                        fieldMapping.legalProceedDate.bvdSelected[index] &&
                        !isDisabled(item?.legalProceedDate)
                      "
                      @change="
                        handleFieldSelect(
                          fieldMapping.legalProceedDate.name,
                          item,
                          true,
                          index,
                          $event
                        )
                      "
                      :class="'attr-radio attr-radio-td'"
                      :disabled="isDisabled(item?.legalProceedDate)"
                    />
                  </td>
                  <td>{{ item?.legalProcessSource }}</td>
                  <td>{{ item?.legalProcessDesc }}</td>
                  <td>
                    {{
                      Array.isArray(item?.legalProcessValue)
                        ? item?.legalProcessValue.join(', ')
                        : item?.legalProcessValue
                    }}
                  </td>
                  <td>{{ formatDate(item?.legalProceedDate) }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <template v-if="hasClientData">
            <div class="radio-group">
              <RadioButton
                :name="fieldMapping.legalProceedStatus.name"
                :value="`client_${fieldMapping.legalProceedStatus.name}`"
                :checked="
                  fieldMapping.legalProceedStatus.clientSelected &&
                  !isDisabled(clientData?.legalProceedStatus)
                "
                @change="
                  handleFieldSelect(
                    fieldMapping.legalProceedStatus.name,
                    clientData?.legalProceedStatus
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(clientData?.legalProceedStatus)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.legalProceedStatus.label
              }}</label>
              <label class="value">{{ clientData?.legalProceedStatus }}</label>
            </div>
          </template>
        </div>
        <div class="bvd-attributes">
          <template v-if="hasBVDData">
            <div class="radio-group">
              <RadioButton
                :name="fieldMapping.legalProceedStatus.name"
                :value="`bvd_${fieldMapping.legalProceedStatus.name}`"
                :checked="
                  fieldMapping.legalProceedStatus.bvdSelected &&
                  !isDisabled(legalProceedStatusDropDown?.text)
                "
                @change="
                  handleFieldSelect(
                    fieldMapping.legalProceedStatus.name,
                    legalProceedStatusDropDown?.text,
                    true
                  )
                "
                :disabled="isDisabled(legalProceedStatusDropDown?.text)"
                :class="'attr-radio'"
              />
            </div>
            <div class="bvd-legal-status">
              <div class="label-group">
                <label class="attr-title">{{
                  fieldMapping.legalProceedStatus.label
                }}</label>
              </div>
              <div>
                <SearchableSelect
                  :value="legalProceedStatusDropDown"
                  :placeholder="'Select Status of Legal Proceedings'"
                  value_field="value"
                  text_field="text"
                  class="form-input-field legal-dropdown"
                  @onChangeName="
                    (value) => {
                      updateStatusOfLegalProceedings(value)
                      handleFieldSelect(
                        fieldMapping.legalProceedStatus.name,
                        value?.text,
                        true
                      )
                    }
                  "
                  :data="legalProceedStatusOpts"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <template v-if="hasClientData">
            <div class="radio-group">
              <RadioButton
                :name="'enterpriseSizeDate'"
                :value="'client_enterpriseSizeDate'"
                :checked="
                  fieldMapping.enterpriseSizeDate.clientSelected &&
                  !isDisabled(clientData?.enterpriseSizeDate)
                "
                @change="
                  handleFieldSelect(
                    'enterpriseSizeDate',
                    clientData?.enterpriseSizeDate
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(clientData?.enterpriseSizeDate)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.enterpriseSizeDate.label
              }}</label>
              <label class="value">{{
                formatDate(clientData?.enterpriseSizeDate)
              }}</label>
            </div>
          </template>
        </div>
        <div class="bvd-attributes">
          <template v-if="hasBVDData">
            <div class="radio-group">
              <RadioButton
                :name="'enterpriseSizeDate'"
                :value="'bvd_enterpriseSizeDate'"
                :checked="
                  fieldMapping.enterpriseSizeDate.bvdSelected &&
                  !isDisabled(bvdData?.enterpriseSizeDate)
                "
                @change="
                  handleFieldSelect(
                    'enterpriseSizeDate',
                    bvdData?.enterpriseSizeDate,
                    true
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(bvdData?.enterpriseSizeDate)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.enterpriseSizeDate.label
              }}</label>
              <label class="value">{{
                formatDate(bvdData?.enterpriseSizeDate)
              }}</label>
            </div>
          </template>
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <template v-if="hasClientData">
            <div class="radio-group">
              <RadioButton
                :name="'noOfEmployees'"
                :value="'client_noOfEmployees'"
                :checked="
                  fieldMapping.noOfEmployees.clientSelected &&
                  !isDisabled(clientData?.noOfEmployees)
                "
                @change="
                  handleFieldSelect('noOfEmployees', clientData?.noOfEmployees)
                "
                :class="'attr-radio'"
                :disabled="isDisabled(clientData?.noOfEmployees)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.noOfEmployees.label
              }}</label>
              <label class="value">{{ clientData?.noOfEmployees }}</label>
            </div>
          </template>
        </div>
        <div class="bvd-attributes">
          <template v-if="hasBVDData">
            <div class="radio-group">
              <RadioButton
                :name="'noOfEmployees'"
                :value="'bvd_noOfEmployees'"
                :checked="
                  fieldMapping.noOfEmployees.bvdSelected &&
                  !isDisabled(bvdData?.noOfEmployees)
                "
                @change="
                  handleFieldSelect(
                    'noOfEmployees',
                    bvdData?.noOfEmployees,
                    true
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(bvdData?.noOfEmployees)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.noOfEmployees.label
              }}</label>
              <label class="value">{{ bvdData?.noOfEmployees }}</label>
            </div>
          </template>
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <template v-if="hasClientData">
            <div class="radio-group">
              <RadioButton
                :name="'balanceSheetTotal'"
                :value="'client_balanceSheetTotal'"
                :checked="
                  fieldMapping.balanceSheetTotal.clientSelected &&
                  !isDisabled(clientData?.balanceSheetTotal)
                "
                @change="
                  handleFieldSelect(
                    'balanceSheetTotal',
                    clientData?.balanceSheetTotal
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(clientData?.balanceSheetTotal)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.balanceSheetTotal.label
              }}</label>
              <label class="value">{{
                formateCurrency(clientData?.balanceSheetTotal)
              }}</label>
            </div></template
          >
        </div>
        <div class="bvd-attributes">
          <template v-if="hasBVDData">
            <div class="radio-group">
              <RadioButton
                :name="'balanceSheetTotal'"
                :value="'bvd_balanceSheetTotal'"
                :checked="
                  fieldMapping.balanceSheetTotal.bvdSelected &&
                  !isDisabled(bvdData?.balanceSheetTotal)
                "
                @change="
                  handleFieldSelect(
                    'balanceSheetTotal',
                    bvdData?.balanceSheetTotal,
                    true
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(bvdData?.balanceSheetTotal)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.balanceSheetTotal.label
              }}</label>
              <label class="value">{{
                formateCurrency(bvdData?.balanceSheetTotal)
              }}</label>
            </div></template
          >
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <template v-if="hasClientData">
            <div class="radio-group">
              <RadioButton
                :name="'annualTurnover'"
                :value="'client_annualTurnover'"
                :checked="
                  fieldMapping.annualTurnover.clientSelected &&
                  !isDisabled(clientData?.annualTurnover)
                "
                @change="
                  handleFieldSelect(
                    'annualTurnover',
                    clientData?.annualTurnover
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(clientData?.annualTurnover)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.annualTurnover.label
              }}</label>
              <label class="value">{{
                formateCurrency(clientData?.annualTurnover)
              }}</label>
            </div>
          </template>
        </div>
        <div class="bvd-attributes">
          <template v-if="hasBVDData">
            <div class="radio-group">
              <RadioButton
                :name="'annualTurnover'"
                :value="'bvd_annualTurnover'"
                :checked="
                  fieldMapping.annualTurnover.bvdSelected &&
                  !isDisabled(bvdData?.annualTurnover)
                "
                @change="
                  handleFieldSelect(
                    'annualTurnover',
                    bvdData?.annualTurnover,
                    true
                  )
                "
                :class="'attr-radio'"
                :disabled="isDisabled(bvdData?.annualTurnover)"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{
                fieldMapping.annualTurnover.label
              }}</label>
              <label class="value">{{
                formateCurrency(bvdData?.annualTurnover)
              }}</label>
            </div>
          </template>
        </div>
      </div>
      <div class="attribute-item" v-if="hasBVDData">
        <div class="client-attributes"></div>
        <div class="bvd-attributes">
          <div class="radio-group">
            <RadioButton
              :name="'enterpriseSize'"
              :value="'bvd_enterpriseSize'"
              :class="'attr-radio attr-radio-hidden'"
            />
          </div>
          <div class="label-group">
            <label class="attr-title">Enterprise Size</label>
            <label class="value">{{ bvdData?.enterpriseSize }}</label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasClientData || hasBVDData" class="button-container">
      <b-button
        class="action-button cancel-button"
        :size="'is-small'"
        @click="resetBVD"
        >Reset</b-button
      >
      <b-button
        class="action-button confirm-button"
        :size="'is-small'"
        @click="saveData"
        >Update Form</b-button
      >
    </div>
  </div>
  <div v-else>No data available.</div>
</template>

<script>
import SearchableSelect from '@/components/DMP/SearchableSelect'
import { Checkbox, RadioButton } from '@progress/kendo-vue-inputs'
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BVDAnacreditForm',
  components: {
    RadioButton,
    SearchableSelect,
    Checkbox
  },
  props: {
    attributesData: {
      type: Object,
      required: true,
      default: () => {}
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState('anaCredit', ['nationalITypeOpts', 'legalProceedStatusOpts']),
    hasClientData() {
      return (
        this.clientData &&
        !Object.keys(this.clientData).every((item) =>
          isEmpty(this.clientData[item])
        )
      )
    },
    hasBVDData() {
      return this.bvdData && Object.keys(this.bvdData).length > 0
    }
  },
  data() {
    return {
      clientData: null,
      bvdData: null,
      selectedClientValues: {},
      selectedBVDFields: {},
      selectAllClient: false,
      selectAllBVD: false,
      fieldMapping: {
        identifier: {
          name: 'identifier',
          label: 'National Identifiers',
          clientSelected: {},
          bvdSelected: {}
        },
        legalProceedStatus: {
          name: 'legalProceedStatus',
          label: 'Status of Legal Proceedings',
          clientSelected: false,
          bvdSelected: false
        },
        balanceSheetTotal: {
          name: 'balanceSheetTotal',
          label: 'Balance Sheet Total (EUR)',
          clientSelected: false,
          bvdSelected: false
        },
        enterpriseSizeDate: {
          name: 'enterpriseSizeDate',
          label: 'Date of Enterprise Size (DD/MM/YYYY)',
          clientSelected: false,
          bvdSelected: false
        },
        noOfEmployees: {
          name: 'noOfEmployees',
          label: 'Number of Employees',
          clientSelected: false,
          bvdSelected: false
        },
        annualTurnover: {
          name: 'annualTurnover',
          label: 'Annual Turnover as Per Year (EUR)',
          clientSelected: false,
          bvdSelected: false
        },
        legalProceedDate: {
          name: 'legalProceedDate',
          label: 'Date of Initiation of Legal Proceedings (DD/MM/YYYY)',
          clientSelected: false,
          bvdSelected: {}
        }
      },
      selectedValues: {
        identifier: null,
        legalProceedStatus: null,
        balanceSheetTotal: null,
        enterpriseSizeDate: null,
        noOfEmployees: null,
        annualTurnover: null,
        legalProceedDate: null
      },
      legalProceedStatusDropDown: null,
      nationalIdentifierTypes: {},
      isInit: false,
      isResetForm: false
    }
  },
  watch: {
    attributesData: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.clientData = newVal?.entityChangeHistory
        this.bvdData = newVal?.bvd
        this.isInit = true
        this.toggleAllClient()
        this.isInit = false
      }
    }
  },
  mounted() {
    this.toggleAllClient()
  },
  methods: {
    ...mapActions('anaCredit', ['patchAnaCreditDetails']),
    toggleAllClient() {
      this.selectAllClient = !this.selectAllClient
      this.selectAllBVD = false
      if (!this.hasClientData) return
      for (const key in this.fieldMapping) {
        if (key === this.fieldMapping.identifier.name) {
          this.selectedValues[key] = []
          if (this.selectAllClient) {
            this.clientData?.identifierPairs?.forEach((item, index) => {
              this.handleFieldSelect(key, item, false, index)
            })
          }
        } else {
          if (this.selectAllClient) {
            this.handleFieldSelect(key, this.clientData[key])
          } else {
            this.handleFieldSelect(key, null)
          }
        }
      }
    },
    toggleAllBVDDetails() {
      this.selectAllBVD = !this.selectAllBVD
      this.selectAllClient = false
      for (const key in this.fieldMapping) {
        if (this.bvdData && key === this.fieldMapping.identifier.name) {
          this.selectedValues[key] = []
          if (this.selectAllBVD) {
            this.bvdData['identifierPairs'].forEach((item) => {
              this.handleFieldSelect(key, item, true)
            })
          }
        } else if (key === this.fieldMapping.legalProceedDate.name) {
          this.selectedValues[key] = null
          if (this.selectAllBVD) {
            this.bvdData['legalEvents'].forEach((item) => {
              this.handleFieldSelect(key, item, true)
            })
          }
        } else {
          if (this.selectAllBVD) {
            this.handleFieldSelect(key, this.bvdData[key], true)
          } else {
            this.handleFieldSelect(key, null)
          }
        }
      }
    },

    /**
     * Notes:
     * handleFieldSelect used for all the fields to select and deselect values
     * Legal Proceed Status:
     *  - Client Data: Taken label
     *  - BVD Data: Taken dropdown label
     */
    handleFieldSelect(key, value, isBVD = false, index = null, event = null) {
      let isChecked = false
      switch (key) {
        case this.fieldMapping.identifier.name:
          isChecked = event
            ? event.e.target.checked
            : (!isBVD && this.isInit) || this.isResetForm

          if (event && !isChecked && isBVD) {
            // Unchecking BVD selected values
            this.nationalIdentifierTypes = {
              ...this.nationalIdentifierTypes,
              [index]: null
            }
          }
          this.selectedValues[key] = this.selectedValues[key]?.length
            ? [...this.selectedValues[key]]
            : []
          if (
            isChecked ||
            (isBVD && !this.isNull(this.nationalIdentifierTypes[index]))
          ) {
            this.selectedValues[key].push(
              isBVD
                ? {
                    type: this.nationalIdentifierTypes[index]?.text,
                    value: value.value
                  }
                : value
            )
          } else {
            this.selectedValues[key] = this.selectedValues[key].filter(
              (item) => item?.value !== value.value
            )
          }
          break
        case this.fieldMapping.legalProceedDate.name:
          const selctedDate =
            value instanceof Object ? value?.legalProceedDate : value
          this.selectedValues[key] = {
            isBVD,
            value: selctedDate
          }
          break
        default:
          this.selectedValues[key] = {
            isBVD,
            value
          }
      }

      // Select Radio button
      if (this.isResetForm) return
      switch (key) {
        case this.fieldMapping.identifier.name:
          this.fieldMapping[key][isBVD ? 'bvdSelected' : 'clientSelected'] = {
            ...this.fieldMapping[key][isBVD ? 'bvdSelected' : 'clientSelected'],
            [index]: isChecked
          }
          break
        case this.fieldMapping.legalProceedDate.name:
          // Reset all options
          this.bvdData?.legalEvents?.forEach((item, index) => {
            this.fieldMapping[key]['bvdSelected'] = {
              ...this.fieldMapping[key]['bvdSelected'],
              [index]: false
            }
          })
          this.fieldMapping[key]['clientSelected'] = false

          // Select the selected option
          if (isBVD) {
            const isSelected = event.event.target.checked
            this.fieldMapping[key]['bvdSelected'][index] = isSelected
          } else {
            this.fieldMapping[key]['clientSelected'] = true
          }
          break
        default:
          this.fieldMapping[key][
            isBVD ? 'bvdSelected' : 'clientSelected'
          ] = true
          // Unselect the opposite option
          this.fieldMapping[key][isBVD ? 'clientSelected' : 'bvdSelected'] =
            null
      }
    },

    resetForm() {
      for (const key in this.fieldMapping) {
        switch (key) {
          case this.fieldMapping.identifier.name:
            this.clientData?.identifierPairs?.forEach((pair, index) => {
              this.fieldMapping[key].clientSelected[index] = true
            })
            this.bvdData?.identifierPairs?.forEach((pair, index) => {
              this.fieldMapping[key].bvdSelected[index] = false
              this.nationalIdentifierTypes = {
                ...this.nationalIdentifierTypes,
                [index]: null
              }
            })
            break
          case this.fieldMapping.legalProceedDate.name:
            this.bvdData?.legalEvents?.forEach((item, index) => {
              this.fieldMapping[key].bvdSelected[index] = null
            })
            this.fieldMapping[key].clientSelected = true
            break
          case this.fieldMapping.legalProceedStatus.name:
            this.legalProceedStatusDropDown = null
            this.fieldMapping[key].bvdSelected = null
            setTimeout(() => {
              // Manually added the selected value
              this.fieldMapping[key].clientSelected = true
              this.selectedValues.legalProceedStatus = {
                isBVD: false,
                value: this.clientData?.legalProceedStatus
              }
            }, 500)
            break
          default:
            this.fieldMapping[key].clientSelected = true
            this.fieldMapping[key].bvdSelected = null
        }
      }
    },

    updateStatusOfLegalProceedings(status) {
      if (this.fieldMapping.legalProceedStatus.bvdSelected) {
        this.selectedValues.legalProceedStatus = {
          isBVD: true,
          value: status
        }
      }
      this.legalProceedStatusDropDown = status
    },

    updateNationalIdentifierType(value, index) {
      // Remove old selected value
      const identifierValue = this.bvdData?.identifierPairs[index]?.value
      const identifierType = this.nationalIdentifierTypes[index]?.text
      this.selectedValues.identifier = this.selectedValues.identifier?.filter(
        (item) => item.value !== identifierValue && item.type !== identifierType
      )

      // Update dropdown value
      this.nationalIdentifierTypes = {
        ...this.nationalIdentifierTypes,
        [index]: value
      }
    },

    isDisabled(value) {
      return value instanceof Object ? isEmpty(value) : this.isNull(value)
    },

    formatDate(value) {
      if (value) {
        const date = new Date(value)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }
      return ''
    },

    formateCurrency(value) {
      if (this.isNull(value)) return
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR'
      }).format(value)
    },

    saveChanges() {
      const updatedData = {}
      for (const key in this.selectedBasicDetailsFields) {
        if (
          this.selectedBasicDetailsFields[key] &&
          this.selectedClientValues[key]
        ) {
          updatedData[key] = this.selectedClientValues[key]
        }
      }
      this.$emit('save', updatedData)
    },

    saveData() {
      this.patchAnaCreditDetails(this.selectedValues)
    },

    resetBVD() {
      this.isResetForm = true
      this.selectAllClient = false
      this.resetForm()
      this.toggleAllClient()
      this.isResetForm = false
    },

    isNull(value) {
      return [undefined, null, ''].includes(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-data-message {
  display: flex;
  align-items: start !important;
  justify-content: center;
  padding: 20px;
  color: #424242;
}
.dynamic-form {
  max-width: 100%;
  margin: 0 auto;
}
.attr-radio::v-deep,
.attr-checkbox::v-deep {
  input {
    border: 1px solid #707070;
  }
}
table {
  border: 1px solid #cccccc;
  margin: 0.5em;
  width: 100%;
  font-size: 12px;
  thead {
    tr {
      th {
        padding: 0.3em;
        color: #7d7d7d;
      }
    }
  }
  tbody {
    tr,
    .identifier-row {
      td {
        padding: 0.3em;
        color: #1e1e1e;
      }
      td:first-child {
        vertical-align: middle;
      }
    }
    .identifier-row {
      td {
        vertical-align: middle;
      }
    }
    tr {
      td:not(:first-child) {
        padding-bottom: 0.8em;
      }
    }
  }
}

.legal-table {
  thead,
  tbody {
    th,
    td {
      border: 1px solid #cccccc;
    }
  }
}

.bvd-container {
  padding: 0.5em;
  .title-row::v-deep {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 49%;
      .title {
        font-size: 22px;
      }
    }
    .title-container:first-child {
      width: 40%;
    }
    .title-container:last-child {
      width: 58%;
    }
    .client {
      .title {
        color: #00218a;
      }
      .k-checkbox:checked {
        background-color: #00218a;
        border-color: transparent;
        box-shadow: none;
      }
    }
    .bvd {
      .title {
        color: #f57777;
      }
      .k-checkbox:checked {
        background-color: #f57777;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .button-container {
    justify-content: end;
    .save-buttons {
      width: 15%;
      display: flex;
      justify-content: space-around;
    }
  }
}
.attribute-container {
  .attribute-item::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .client-attributes,
    .bvd-attributes,
    .client-identifier-section,
    .bvd-identifier-section {
      width: 49%;
      display: flex;
      flex-direction: row;
      padding-bottom: 1em;
      background-color: #f9f9f9;
      align-items: center;
      line-height: 1.7em;
      border: 1px solid #cccccc;
      border-width: 0 1px;
      .radio-group {
        padding: 1em;
      }
      .label-group {
        display: flex;
        flex-direction: column;

        .attr-title {
          color: #7d7d7d;
          font-size: 12px;
        }
        .value {
          color: #1e1e1e;
          font-size: 13px;
        }
      }
      .attr-radio-td {
        display: inline;
      }
      .attr-radio-hidden {
        opacity: 0;
      }
      .bvd-legal-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.3em;
      }
    }
    .client-attributes,
    .client-identifier-section {
      width: 40%;
      table {
        line-height: 3.6em;
      }
      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #00218a;
        box-shadow: none;
      }
    }
    .bvd-attributes,
    .bvd-identifier-section {
      width: 58%;
      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #f57777;
        box-shadow: none;
      }
    }
  }
  .attribute-item:first-child {
    .client-attributes,
    .bvd-attributes,
    .client-identifier-section,
    .bvd-identifier-section {
      background-color: #f9f9f9;
      border: 1px solid #cccccc;
      border-bottom: none;
    }
  }
  .attribute-item:last-child {
    .client-attributes,
    .bvd-attributes {
      border-bottom: 1px solid #cccccc;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  height: 5rem;
  padding-bottom: 0.6rem;
  width: 100%;
  gap: 1rem;

  .action-button {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    min-width: 104px;
    height: 46px;
  }
  .confirm-button {
    background-color: #535eeb; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: left;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
  }
  .confirm-button:hover {
    background-color: #00218a; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    opacity: 0.9;
  }

  .cancel-button {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;
  }
  .cancel-button:hover {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    opacity: 0.9;
  }
}
</style>
