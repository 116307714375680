<template>
  <div class="address-form-layout">
    <div class="column-view-full">
      <input-vue
        :value="address.addrLine1"
        :min="1"
        type="text"
        :max="500"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeAddrLine1"
        class="field-container"
        :readonly="true"
        :class="'form-control-disabled'"
        :placeholder="'Enter Address'"
        label="Address"
      />
    </div>
    <div class="column-view-full">
      <input-vue
        :value="address.fullAddress"
        :min="1"
        type="text"
        :max="500"
        :hashSpecialCharValidation="true"
        :pipeSpecialCharValidation="true"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeFullAddress"
        class="field-container"
        :disabled="false"
        :placeholder="'Enter Address'"
        label="Full Address"
      />
    </div>
    <div class="column-view-full">
      <input-vue
        :value="address.companyName"
        :min="1"
        type="text"
        :max="125"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeCompanyName"
        class="field-container"
        placeholder="Enter C/O or Company Name"
        label="C/O or Company Name"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.department"
        :min="1"
        type="text"
        :max="100"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeDepartment"
        :placeholder="'Enter Department'"
        :readonly="departmentReadonly"
        :disabled="departmentReadonly"
        :class="departmentReadonly ? 'form-control-disabled' : ''"
        label="Department"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.subDepartment"
        :min="1"
        type="text"
        :max="100"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeSubDepartment"
        label="Sub Department"
        :readonly="subDepartmentReadOnly"
        :disabled="subDepartmentReadOnly"
        :class="subDepartmentReadOnly ? 'form-control-disabled' : ''"
        :placeholder="'Enter Sub Department'"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.streetName"
        :min="1"
        type="text"
        :max="100"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeStreetName"
        class="field-container"
        label="Street Name"
        :placeholder="'Enter Street Name'"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.buildingNumber"
        :min="1"
        type="text"
        :max="100"
        :alphaNumSpaceAndDash="true"
        @onChangeName="onChangeBuildingNumber"
        class="field-container"
        label="Building Number"
        :placeholder="'Enter Building number'"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.buildingName"
        :min="1"
        type="text"
        :max="100"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeBuildingName"
        class="field-container"
        :placeholder="'Enter Building Name'"
        label="Building Name"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.floor"
        :min="1"
        type="text"
        :max="100"
        :alphaNumSpaceAndDash="true"
        @onChangeName="onChangeFloor"
        label="Floor"
        class="field-container"
        :placeholder="'Enter Floor Number'"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.postBox"
        :min="1"
        type="text"
        :max="100"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangePostBox"
        placeholder="Enter Postbox"
        class="field-container"
        label="Postbox"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.room"
        :min="1"
        type="text"
        :max="100"
        @onChangeName="onChangeRoom"
        placeholder="Enter Room"
        class="field-container"
        :alphaNumSpaceAndDash="true"
        label="Room"
      />
    </div>
    <div class="column-view">
      <input-vue
        :value="address.postCode"
        :min="1"
        type="text"
        :max="100"
        :alphaNumSpaceAndDash="true"
        :required="true"
        @onChangeName="onChangePostCode"
        :submitted="submitted"
        placeholder="Enter Postcode"
        class="field-container"
        label="Postcode"
      />
    </div>
    <div class="column-view">
      <SearchableSelect
        :value="address.country"
        label="Country"
        :placeholder="'Select Country'"
        value_field="value"
        text_field="text"
        @onChangeName="onChangeCountry"
        class="field-container"
        :data="countryData"
        :hasEvidenceButton="false"
        :submitted="submitted"
        :isMandatory="true"
        :mandatorySubmit="true"
      />
    </div>
    <div class="column-view">
      <input-vue
        :value="address.town"
        :min="1"
        type="text"
        :max="100"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeTownName"
        class="field-container"
        placeholder="Enter Town Name"
        label="Town Name"
      />
    </div>
    <div class="column-view">
      <input-vue
        :value="address.townLocation"
        :min="1"
        type="text"
        :max="500"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeTownLocation"
        class="field-container"
        placeholder="Enter Town Location Name"
        label="Town Location Name"
      />
    </div>

    <div class="column-view">
      <input-vue
        :value="address.districtName"
        :min="1"
        type="text"
        :max="100"
        :isAsciiEnglishCharactersOnly="true"
        @onChangeName="onChangeDistrictName"
        class="field-container"
        placeholder="Enter District Name"
        label="District Name"
      />
    </div>

    <div class="column-view">
      <SearchableSelect
        :value="address.subDivision"
        label="Country Sub Division"
        :placeholder="'Select Country Sub Division'"
        value_field="value"
        text_field="text"
        @onChangeName="onChangeSubDivision"
        class="field-container"
        :data="countrySubDivision"
        :hasEvidenceButton="false"
        :submitted="submitted"
        :isMandatory="true"
        :mandatorySubmit="true"
      />
    </div>
    <div class="column-view">
      <SearchableSelect
        :value="address.nuts3"
        label="NUTS3 Code"
        :placeholder="'Select NUTS3 Code'"
        @onChangeName="onChangeNutsCode"
        class="field-container"
        :data="nuts3Data"
        text_field="text"
        value_field="value"
        :hasEvidenceButton="false"
      />
    </div>
  </div>
</template>

<script>
import SearchableSelect from '@/components/DMP/SearchableSelect'
import InputVue from '@/views/Admin/components/Input.vue'
import { mapActions } from 'vuex'

export default {
  name: 'AddressForm',
  components: {
    SearchableSelect,
    InputVue
  },
  props: {
    label: {
      type: String
    },
    setAddress: {
      type: Object
    },
    countryList: {
      type: Array
    },
    departmentReadonly: {
      type: Boolean
    },
    subDepartmentReadOnly: {
      type: Boolean
    },
    submitted: {
      type: Boolean,
      default: false
    },
    subDivisionId: {
      type: Number
    },
    countrySubDivision: {
      type: Array
    },
    nuts3Data: {
      type: Array
    },
    getSubDivision: {
      type: Function
    },
    getNuts3Code: {
      type: Function
    }
  },
  data() {
    return {
      isPostcodeValid: '',
      isCountryValid: '',
      isValidField: {}
    }
  },
  computed: {
    countryData() {
      return this.countryList
    },
    address() {
      return this.setAddress
    }
  },
  watch: {
    address: {
      handler(newData) {
        this.updateAddressField()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('dmp', [
      'updateRegisteredAddress',
      'updatePhysicalAddress',
      'updateBusinessAddress',
      'getStateList',
      'updateStateList',
      'updateNuts3List'
    ]),

    updateAddressField() {
      let isValid = true
      for (const key in this.isValidField) {
        if (!this.isValidField[key]) {
          isValid = false
          break // Stop the loop once a false value is encountered
        }
      }

      if (this.label === 'registeredAddress') {
        this.$emit('updateRegisterAddress', this.address)
      } else if (this.label === 'physicalAddress') {
        this.$emit('updatePhysicalAddress', this.address)
      }
    },

    onChangeAddrLine1(addr1) {
      if (this.address) {
        this.address.addrLine1 = addr1
      }
    },

    onChangeFullAddress(addr1) {
      if (this.address) {
        this.address.fullAddress = addr1
      }
    },
    onChangeDepartment(department) {
      if (this.address) {
        this.address.department = department
      }
    },
    onChangeSubDepartment(subDepartment) {
      if (this.address) {
        this.address.subDepartment = subDepartment
      }
    },
    onChangeStreetName(streetName) {
      if (this.address) {
        this.address.streetName = streetName
      }
    },
    onChangeBuildingNumber(buildingNumber) {
      if (this.address) {
        this.address.buildingNumber = buildingNumber
      }
    },
    onChangeBuildingName(buildingName) {
      if (this.address) {
        this.address.buildingName = buildingName
      }
    },
    onChangeFloor(floor) {
      if (this.address) {
        this.address.floor = floor
      }
    },
    onChangePostBox(postBox) {
      if (this.address) {
        this.address.postBox = postBox
      }
    },
    onChangeRoom(room) {
      if (this.address) {
        this.address.room = room
      }
    },
    onChangePostCode(postCode) {
      if (this.address) {
        this.address.postCode = postCode
      }
    },

    onChangeCountry(countryObj) {
      if (this.address) {
        if (!countryObj) {
          this.address.nuts3 = null
          this.address.subDivision = null
        }
        this.address.country = countryObj
      }
      this.$emit('getSubDivision', this.label, countryObj)
      this.$emit('getNuts3Code', this.label, countryObj)
    },
    onChangeDistrictName(districtName) {
      if (this.address) {
        this.address.districtName = districtName
      }
    },
    onChangeNutsCode(nuts3) {
      if (this.address) {
        this.address.nuts3 = nuts3
      }
    },
    onChangeCompanyName(companyName) {
      if (this.address) {
        this.address.companyName = companyName
      }
    },
    onChangeSubDivision(subDivision) {
      if (this.address) {
        this.address.subDivision = subDivision
      }
    },

    onChangeTownName(town) {
      if (this.address) {
        this.address.town = town
      }
    },
    onChangeTownLocation(townLocation) {
      if (this.address) {
        this.address.townLocation = townLocation
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address-form-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 85%;
  .column-view {
    width: 44%;
    margin-top: 2rem;
  }
  .column-view-full {
    width: 100%;
    margin-top: 2rem;
  }
}
</style>

<style lang="scss">
.address-form-layout {
  .column-view,
  .column-view-full {
    .form-group {
      p {
        display: flex;
        margin: 0px;
        color: #7d7d7d;
        font-size: 12px;
        font-family: Quicksand;
        padding: 0px 20px 0px 0px;
        display: flex;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 0.15rem;
        min-height: 21px;
      }
      .input {
        text-align: left;
        font: normal normal 13px/23px Quicksand;
        font-weight: 600;
        color: #1e1e1e;
        opacity: 1;
        border-color: #cccccc;
      }
    }
    .form-control-disabled > .form-control {
      background-color: rgb(83, 94, 235, 0.1);
      p {
        font-size: 12px;
        min-height: 21px;
        margin-bottom: 0.15rem;
      }
      .input {
        text-align: left;
        letter-spacing: 0.18px;
        color: #00218a !important;
        opacity: 1;
        font: normal normal bold 13px/23px Quicksand;
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .address-form-layout {
    .column-view,
    .column-view-full {
      .form-group {
        p {
          font-size: 14px;
          min-height: 25px;
        }
        .input {
          font: normal normal 16px/23px Quicksand;
          font-weight: 600;
        }
      }
      .form-control-disbled > .form-control {
        p {
          font-size: 14px;
          min-height: 25px;
        }
        .input {
          font: normal normal 16px/23px Quicksand;
          font-weight: 600;
        }
      }
    }
  }
}
@media (min-width: 1700px) {
  .address-form-layout {
    width: 85%;
    .column-view,
    .column-view-full {
      width: 44%;
      .form-group {
        p {
          font-size: 16px;
          min-height: 28px;
        }
        .input {
          font: normal normal 18px/23px Quicksand;
          font-weight: 600;
        }
      }
      .form-control-disbled > .form-control {
        p {
          font-size: 16px;
          min-height: 28px;
        }
        .input {
          font: normal normal 18px/23px Quicksand;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
