<template>
  <div class="industry-details-section">
    <h2 class="section-title">Industry Details</h2>
    <div class="mandatory-note">
      <p>
        Text boxes marked as
        <span class="mandatory">*</span> are mandatory. Industry details are
        dependent on Entity Type and Subtype.
      </p>
    </div>
    <div class="add-evidence">
      <AddEvidence
        :hasEvidenceButton="true"
        :btnLoading="getBtnLoadingStatus(INDUSTRY_DETAILS_LABEL)"
        :evidenceFormStatus="getEvidenceFormStatus(INDUSTRY_DETAILS_LABEL)"
        @click.native="toggleEvidenceForm(INDUSTRY_DETAILS_LABEL)"
        :isDisabled="isAllEvidenceBtnDisabled()"
      />
    </div>

    <span class="required-error" v-if="submitStatus[INDUSTRY_DETAILS_LABEL]">
      {{ getEvdError(INDUSTRY_DETAILS_LABEL) }}
    </span>

    <div>
      <div class="industry-form">
        <div class="local-loader-container" v-if="loading">
          <div class="inava-loader"></div>
        </div>
        <div class="column-view-full">
          <div class="dmp-textarea">
            <DMPTextArea
              :label="NATURE_OF_BUSINESS"
              :type="'textarea'"
              :rows="5"
              :min="1"
              :max="NATURE_OF_BUSINESS_TOTAL_CHARS"
              :required="true"
              :isAsciiEnglishCharactersOnly="true"
              :placeholder="`${NATURE_OF_BUSINESS_TOTAL_CHARS} Characters`"
              v-model="newIndustryDetails.businessNature"
              @onChangeName="updateBusinessNature"
              :submitted="submitStatus[NATURE_OF_BUSINESS] || isMandatory"
              :isDefaultErrorMessage="isMandatory"
              :showSecondaryError="false"
              :secondaryError="
                submitStatus[NATURE_OF_BUSINESS]
                  ? getEvdError(NATURE_OF_BUSINESS)
                  : ''
              "
            ></DMPTextArea>
          </div>
        </div>
        <!-- NAICS -->
        <div class="column-view">
          <div class="searchable-select">
            <SearchableSelect
              :value="newIndustryDetails.naics"
              @onChangeName="updateNaics"
              :data="naicsList"
              value_field="value"
              text_field="text"
              :isLoading="loadingFlags[NAICS]"
              :submitted="submitStatus[NAICS] || isMandatory"
              :label="NAICS"
              :placeholder="getPlaceholder(NAICS)"
              :isMandatory="true"
              :mandatorySubmit="isMandatory"
              :showSecondaryError="false"
              :secondaryError="submitStatus[NAICS] ? getEvdError(NAICS) : ''"
            />
          </div>
        </div>

        <!-- SECONDARY_NAICS -->
        <div class="column-view">
          <div class="searchable-select">
            <SearchableSelect
              :value="newIndustryDetails.secondaryNaics"
              @onChangeName="updateSecondaryNaics"
              :data="secondaryNaicsList"
              :label="SECONDARY_NAICS"
              value_field="value"
              text_field="text"
              :isLoading="loadingFlags[SECONDARY_NAICS]"
              :submitted="submitStatus[SECONDARY_NAICS] || isMandatory"
              :placeholder="getPlaceholder(SECONDARY_NAICS)"
              :hasEvidenceButton="true"
              :showSecondaryError="false"
              :secondaryError="
                submitStatus[SECONDARY_NAICS]
                  ? getEvdError(SECONDARY_NAICS)
                  : ''
              "
              :disabled="isNotHoldingCompanyDisabled"
            />
          </div>
        </div>

        <!-- NACE -->
        <div class="column-view">
          <div class="searchable-select">
            <SearchableSelect
              :value="newIndustryDetails.nace"
              @onChangeName="updateNace"
              :data="naceList"
              :label="NACE"
              value_field="value"
              text_field="text"
              :isLoading="loadingFlags[NACE]"
              :submitted="submitStatus[NACE] || isMandatory"
              :placeholder="getPlaceholder(NACE)"
              :isMandatory="true"
              :mandatorySubmit="isMandatory"
              :showSecondaryError="false"
              :secondaryError="submitStatus[NACE] ? getEvdError(NACE) : ''"
            />
          </div>
        </div>

        <!-- SECONDARY_NACE -->
        <div class="column-view">
          <div class="searchable-select">
            <SearchableSelect
              :value="newIndustryDetails.secondaryNace"
              @onChangeName="updateSecondaryNace"
              :data="secondaryNaceList"
              :label="SECONDARY_NACE"
              :placeholder="getPlaceholder(SECONDARY_NACE)"
              value_field="value"
              text_field="text"
              :isLoading="loadingFlags[SECONDARY_NACE]"
              :submitted="submitStatus[SECONDARY_NACE] || isMandatory"
              :isMandatory="checkNace"
              :mandatorySubmit="isMandatory"
              :showSecondaryError="false"
              :secondaryError="
                submitStatus[SECONDARY_NACE] ? getEvdError(SECONDARY_NACE) : ''
              "
              :disabled="isNotHoldingCompanyDisabled"
            />
          </div>
        </div>

        <!-- SIC -->
        <div class="column-view">
          <div class="searchable-select">
            <SearchableSelect
              :value="newIndustryDetails.sic"
              @onChangeName="updatesic"
              :data="sicList"
              :label="SIC"
              :placeholder="getPlaceholder(SIC)"
              value_field="value"
              text_field="text"
              :isLoading="loadingFlags[SIC]"
              :submitted="submitStatus[SIC] || isMandatory"
              :isMandatory="true"
              :mandatorySubmit="isMandatory"
              :showSecondaryError="false"
              :secondaryError="submitStatus[SIC] ? getEvdError(SIC) : ''"
            />
          </div>
        </div>

        <!-- GERMAN_EXTENDED_NACE -->
        <div class="column-view">
          <div class="searchable-select">
            <SearchableSelect
              :value="newIndustryDetails.germanExtNace"
              @onChangeName="updateGermanExtNace"
              :data="germanExtNaceList"
              :label="GERMAN_EXTENDED_NACE"
              :placeholder="getPlaceholder(GERMAN_EXTENDED_NACE)"
              value_field="value"
              text_field="text"
              :isLoading="loadingFlags[GERMAN_EXTENDED_NACE]"
              :submitted="submitStatus[GERMAN_EXTENDED_NACE]"
              :showSecondaryError="false"
              :secondaryError="
                submitStatus[GERMAN_EXTENDED_NACE]
                  ? getEvdError(GERMAN_EXTENDED_NACE)
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
    <template>
      <ActionButton
        @SaveData="saveData"
        @saveAndNext="saveAndNext"
        @goBack="goBack"
      ></ActionButton>
    </template>
    <ConfirmationDialog
      :visible="dialog.visible"
      :title="dialog.title"
      :bodyText="dialog.text"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="dialog.triggerFunction"
    />
  </div>
</template>
<script>
import SearchableSelect from '@/components/DMP/SearchableSelect'
import ActionButton from '@/components/DMP/ActionButton.vue'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { indstryDetailEvdId, activeStepIndexName } from '@/constant/data'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import { isEqual, isEmpty, cloneDeep } from 'lodash'
import Snackbar from '@/components/Snackbar'
import { isValidEnglishASCII } from '@/util/util'
import DMPTextArea from '@/components/DMP/DMPTextArea'

// constants
const {
  NAICS,
  SECONDARY_NAICS,
  NACE,
  SECONDARY_NACE,
  SIC,
  GERMAN_EXTENDED_NACE,
  NATURE_OF_BUSINESS
} = indstryDetailEvdId

export default {
  name: 'IndustryForm',

  components: {
    ActionButton,
    SearchableSelect,
    AddEvidence,
    ConfirmationDialog,
    DMPTextArea
  },

  props: {
    // Define the updateStepper prop
    updateStepper: {
      type: Function,
      required: true
    },

    countryCode: Number
  },

  data() {
    return {
      sgaId: '',

      // constants declared
      INDUSTRY_DETAILS_LABEL: 'Industry Details',
      NAICS,
      SECONDARY_NAICS,
      NACE,
      SECONDARY_NACE,
      SIC,
      GERMAN_EXTENDED_NACE,
      NATURE_OF_BUSINESS,
      activeStepIndexName,

      // Bank Entity
      BANK: 1,
      BANK_HOLDING_COMPANY: 1,
      // Financial Entity
      FINANCIAL_CORPORATION: 4,
      HOLDING_COMPANY_NON_BANK: 25,

      // input variables
      newIndustryDetails: {
        naics: null,
        secondaryNaics: null,
        nace: null,
        secondaryNace: null,
        sic: null,
        germanExtNace: null,
        businessNature: null
      },

      loading: false, // form loader
      evidenceIdMapping: null, // e.g { naicsEvdId: evdId, naceEvdId: evdId,.. }
      inputNameToEvdIdMapper: {}, // e.g { NAICS: evdId, NACE: evdId,.. }
      submitStatus: {},
      loadingFlags: {}, // loader flag obj for dropdown
      isMandatory: false,
      localEvidenceIdList: [], // old evidenceid coming from api

      // confirmation popup
      dialog: {
        visible: false,
        title: 'Discard changes',
        text: 'Would you like to discard the changes & go back <br>to Address details form?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },

      NATURE_OF_BUSINESS_TOTAL_CHARS: 5000,
      fieldNameList: [
        'naics',
        'secondaryNaics',
        'nace',
        'secondaryNace',
        'sic',
        'germanExtNace'
        // 'businessNature'
      ]
    }
  }, // end data

  computed: {
    ...mapState('evidence', ['evidenceIdList', 'tempS3data', 'isBVD']),
    ...mapState('industry', [
      'naicsList',
      'secondaryNaicsList',
      'naceList',
      'secondaryNaceList',
      'sicList',
      'germanExtNaceList',
      'industryDetail',
      'isIndustryDetailsEmpty',
      'naicsUpdateOnlyOnce',
      'secondaryNaicsUpdateOnlyOnce',
      'naceUpdateOnlyOnce',
      'isNotHoldingCompanyDisabled',
      'industryDetailCopy'
    ]),
    ...mapState('basicDetails', ['basicDetails']),
    ...mapGetters('evidence', [
      'getEvidenceFormStatus',
      'getBtnLoadingStatus',
      'isEvidencePresent'
    ]),
    ...mapGetters('dmp', ['getEntityDetails']),

    entityTypeId() {
      return this.basicDetails.entityTypeId
    },

    entitySubTypeId() {
      return this.basicDetails.entitySubTypeId
    },

    checkNace() {
      return !!this.newIndustryDetails.secondaryNaics
    }
  }, // end computed

  watch: {
    industryDetail() {
      this.syncData()
    },

    newIndustryDetails: {
      handler() {
        // when form initial form is empty and current form is also made empty
        // retain the evd in that case
        const {
          naics: newNaics,
          nace: newNace,
          sic: newSic,
          germanExtNace: newGermanExtNace,
          secondaryNaics: newSecondaryNaics,
          secondaryNace: newSecondaryNace,
          businessNature: newBusinessNature
        } = this.newIndustryDetails

        const noChange =
          isEmpty(newNaics) &&
          isEmpty(newNace) &&
          isEmpty(newSic) &&
          isEmpty(newGermanExtNace) &&
          isEmpty(newSecondaryNaics) &&
          isEmpty(newSecondaryNace) &&
          isEmpty(newBusinessNature) &&
          isEmpty(this.industryDetail)

        if (noChange || isEqual(this.newIndustryDetails, this.industryDetail)) {
          // set submit check for all field to false
          this.$set(this.submitStatus, this.NAICS, false)
          this.$set(this.submitStatus, this.NACE, false)
          this.$set(this.submitStatus, this.SIC, false)
          this.$set(this.submitStatus, this.GERMAN_EXTENDED_NACE, false)
          this.$set(this.submitStatus, this.SECONDARY_NAICS, false)
          this.$set(this.submitStatus, this.SECONDARY_NACE, false)
          this.$set(this.submitStatus, this.NATURE_OF_BUSINESS, false)
          this.$set(this.submitStatus, this.INDUSTRY_DETAILS_LABEL, false)

          // set mandatory check for all field to false
          this.isMandatory = false

          const { industryEvdId } = this.industryDetail

          this.removeEvidence(this.INDUSTRY_DETAILS_LABEL)

          // mapping evidence id and update evidence id tracker
          if (industryEvdId) {
            this.updateEvdIdInfo(this.INDUSTRY_DETAILS_LABEL, industryEvdId)
          }
        }

        // case: when current form is equal to initial form
        // retain the evidence in that case

        const isEqualToInitial = this.compareWithIndustryDetailCopy()

        if (isEqualToInitial) {
          // set submit check for all field to false
          this.$set(this.submitStatus, this.NAICS, false)
          this.$set(this.submitStatus, this.NACE, false)
          this.$set(this.submitStatus, this.SIC, false)
          this.$set(this.submitStatus, this.GERMAN_EXTENDED_NACE, false)
          this.$set(this.submitStatus, this.SECONDARY_NAICS, false)
          this.$set(this.submitStatus, this.SECONDARY_NACE, false)
          this.$set(this.submitStatus, this.NATURE_OF_BUSINESS, false)
          this.$set(this.submitStatus, this.INDUSTRY_DETAILS_LABEL, false)

          const { industryEvdId } = this.industryDetailCopy

          this.removeEvidence(this.INDUSTRY_DETAILS_LABEL)

          // mapping evidence id and update evidence id tracker
          if (industryEvdId) {
            this.updateEvdIdInfo(this.INDUSTRY_DETAILS_LABEL, industryEvdId)
          }
        }
      },
      deep: true
    },

    entitySubTypeId: async function () {
      this.loading = true
      this.updateIsBvdReady(false)
      if (this.entitySubTypeId) {
        // dropdown list loader flag
        this.$set(this.loadingFlags, this.NAICS, true)
        this.$set(this.loadingFlags, this.SECONDARY_NAICS, true)

        await this.getNaics({ entitySubTypeId: this.entitySubTypeId })
        await this.getIndustryDetail(this.sgaId)

        if (
          this.entityTypeId === this.BANK &&
          this.entitySubTypeId === this.BANK_HOLDING_COMPANY
        ) {
          await this.getSecondaryNaics({
            entityTypeId: this.entityTypeId,
            entitySubTypeId: '',
            isAllNaics: false
          })
        } else if (
          this.entityTypeId === this.FINANCIAL_CORPORATION &&
          this.entitySubTypeId === this.HOLDING_COMPANY_NON_BANK
        ) {
          await this.getSecondaryNaics({
            entityTypeId: '',
            entitySubTypeId: '',
            isAllNaics: true
          })
        } else {
          this.updateNotHoldingCompanyFlag(true)
          // await this.getSecondaryNaics({ entityTypeId: '', entitySubTypeId: this.entitySubTypeId })
        }

        // dropdown list loader flag
        this.$set(this.loadingFlags, this.NAICS, false)
        this.$set(this.loadingFlags, this.SECONDARY_NAICS, false)
      }
      this.loading = false
      this.updateIsBvdReady(true)
    },

    germanExtNaceList() {
      if (
        isEqual(this.newIndustryDetails.nace, this.industryDetail.nace) &&
        this.germanExtNaceList.length === 0
      ) {
        this.newIndustryDetails.germanExtNace =
          this.industryDetail?.germanExtNace
      }
    }
  }, // end watch

  async mounted() {
    this.loading = true
    const { sgaId } = this.getEntityDetails
    this.sgaId = sgaId
    this.resetEvidenceStore()
    this.resetIndustryDetails()
    this.resetIndustryVariables()
    this.updateNotHoldingCompanyFlag(false)
    this.updateIsBvdReady(false)

    // set submit check for all field to false
    this.$set(this.submitStatus, this.NAICS, false)
    this.$set(this.submitStatus, this.NACE, false)
    this.$set(this.submitStatus, this.SIC, false)
    this.$set(this.submitStatus, this.GERMAN_EXTENDED_NACE, false)
    this.$set(this.submitStatus, this.SECONDARY_NAICS, false)
    this.$set(this.submitStatus, this.SECONDARY_NACE, false)
    this.$set(this.submitStatus, this.NATURE_OF_BUSINESS, false)

    if (this.entitySubTypeId) {
      // dropdown list loader flag
      this.$set(this.loadingFlags, this.NAICS, true)
      this.$set(this.loadingFlags, this.SECONDARY_NAICS, true)

      await this.getIndustryDetail(this.sgaId)
      await this.getNaics({ entitySubTypeId: this.entitySubTypeId })

      if (
        this.entityTypeId === this.BANK &&
        this.entitySubTypeId === this.BANK_HOLDING_COMPANY
      ) {
        await this.getSecondaryNaics({
          entityTypeId: this.entityTypeId,
          entitySubTypeId: '',
          isAllNaics: false
        })
      } else if (
        this.entityTypeId === this.FINANCIAL_CORPORATION &&
        this.entitySubTypeId === this.HOLDING_COMPANY_NON_BANK
      ) {
        await this.getSecondaryNaics({
          entityTypeId: '',
          entitySubTypeId: '',
          isAllNaics: true
        })
      } else {
        this.updateNotHoldingCompanyFlag(true)
        // await this.getSecondaryNaics({ entityTypeId: '', entitySubTypeId: this.entitySubTypeId })
      }

      // dropdown list loader flag
      this.$set(this.loadingFlags, this.NAICS, false)
      this.$set(this.loadingFlags, this.SECONDARY_NAICS, false)
    }

    this.loading = false
    this.updateIsBvdReady(true)
  }, // end mounted

  methods: {
    ...mapActions('evidence', [
      'uploadEvidence',
      'resetEvidenceStore',
      'viewEvidence',
      'deleteEvidence',
      'updateEvidenceIdList',
      'updateSelectedUploadFilesId',
      'removeEvidence',
      'updateIsBVD'
    ]),

    ...mapActions('industry', [
      'getNaics',
      'getSecondaryNaics',
      'getNaceList',
      'getGermanNaceList',
      'getSecondaryNaceList',
      'getSicList',
      'updateNaceList',
      'updateSicList',
      'updateSecondaryNaceList',
      'getIndustryDetail',
      'updateIndustryDetail',
      'updateGermanNaceList',
      'resetIndustryDetails',
      'updateNaicsUpdateOnlyOnce',
      'updateSecondaryNaicsUpdateOnlyOnce',
      'updateNaceUpdateOnlyOnce',
      'resetIndustryVariables',
      'updateNotHoldingCompanyFlag',
      'updateIsBvdReady',
      'updateBusinessNatureState'
    ]),

    ...mapActions('assignedEntities', ['checkAndUpdateTodoStatus']),

    getPlaceholder(inputName) {
      return `Select ${inputName}`
    },

    syncData() {
      //  copy data present vuex store to component local
      this.newIndustryDetails = cloneDeep(this.industryDetail)
      // this.newIndustryDetails = this.copyIndustryDetailState()

      const { industryEvdId } = this.industryDetail

      const fileIdArr = []

      // mapping evidence id and update evidence id tracker
      if (industryEvdId) {
        this.updateEvdIdInfo(this.INDUSTRY_DETAILS_LABEL, industryEvdId)
      }

      this.updateSelectedUploadFilesId(fileIdArr)
    },

    copyIndustryDetailState() {
      const tempNewIndustryDetails = JSON.parse(
        JSON.stringify(this.newIndustryDetails)
      )

      const combinedKeys = new Set([
        ...Object.keys(tempNewIndustryDetails),
        ...Object.keys(this.industryDetail)
      ])

      combinedKeys.forEach((key) => {
        if (!isEmpty(this.industryDetail)) {
          // key is present in both and industryDetail is not empty, assign the value from industryDetail state
          tempNewIndustryDetails[key] = this.industryDetail[key]
        } else if (!(key in tempNewIndustryDetails)) {
          // key not present in newIndustryDetails, add it from industryDetail state
          tempNewIndustryDetails[key] = this.industryDetail[key]
        }
      })

      return tempNewIndustryDetails
    },

    updateEvdIdInfo(inputName, evdId) {
      this.updateEvidenceIdList(inputName)
      this.updateLocalEvidenceIdList(inputName)
      this.mapInputNameToEvdId(inputName, evdId)
    },

    updateBusinessNature(newBusinessNature) {
      newBusinessNature = isEmpty(newBusinessNature) ? null : newBusinessNature
      this.updateBusinessNatureState(newBusinessNature)
      if (this.newIndustryDetails.businessNature !== newBusinessNature) {
        this.$set(this.newIndustryDetails, 'businessNature', newBusinessNature)
        this.mandateReupload()
      }
    },

    /* DROPDOWN CHANGE METHODS */
    async updateNaics(naicsObj) {
      this.$set(this.newIndustryDetails, 'naics', naicsObj)
      if (
        !isEqual(
          this.newIndustryDetails.naics?.value,
          this.industryDetail.naics?.value
        )
      ) {
        this.mandateReupload()
      }

      // resetting the selected values of nace,sic and german ext nace
      this.$set(this.newIndustryDetails, 'nace', null)
      this.$set(this.newIndustryDetails, 'sic', null)

      // resetting the existing nace and sic list in store
      this.updateNaceList({ naceList: [] })
      this.updateSicList({ sicList: [] })

      // dropdown list loader flag
      this.$set(this.loadingFlags, this.NACE, true)
      this.$set(this.loadingFlags, this.SIC, true)

      if (naicsObj) {
        // make api call to get a list of nace and sic
        await this.getNaceList({ naicsId: naicsObj.value })
        await this.getSicList({ naicsId: naicsObj.value })

        // dropdown list loader flag
        this.$set(this.loadingFlags, this.NACE, false)
        this.$set(this.loadingFlags, this.SIC, false)
        // only pass at initial load
        if (
          isEqual(
            this.newIndustryDetails.naics?.value,
            this.industryDetail?.naics?.value
          ) &&
          this.naicsUpdateOnlyOnce
        ) {
          this.$set(
            this.newIndustryDetails,
            'nace',
            this.industryDetail?.nace || null
          )
          this.$set(
            this.newIndustryDetails,
            'sic',
            this.industryDetail?.sic || null
          )

          this.updateNaicsUpdateOnlyOnce(false)
        } // end if
      } else {
        // dropdown list loader flag
        this.$set(this.loadingFlags, this.NACE, false)
        this.$set(this.loadingFlags, this.SIC, false)
      }
    },

    async updateSecondaryNaics(secondaryNaicsObj) {
      this.$set(this.newIndustryDetails, 'secondaryNaics', secondaryNaicsObj)
      if (
        !isEqual(
          this.newIndustryDetails.secondaryNaics?.value,
          this.industryDetail.secondaryNaics?.value
        )
      ) {
        this.mandateReupload()
      }

      // resetting the selected values of secondary nace
      this.$set(this.newIndustryDetails, 'secondaryNace', null)
      // resetting the existing secondary nace
      this.updateSecondaryNaceList({ secondaryNaceList: [] })

      // dropdown list loader flag
      this.$set(this.loadingFlags, this.SECONDARY_NACE, true)

      if (this.newIndustryDetails.secondaryNaics) {
        // make api call to get a list of nace
        await this.getSecondaryNaceList({
          naicsId: this.newIndustryDetails.secondaryNaics.value
        })

        // dropdown list loader flag
        this.$set(this.loadingFlags, this.SECONDARY_NACE, false)

        // only pass at initial load
        if (
          isEqual(
            this.newIndustryDetails.secondaryNaics?.value,
            this.industryDetail?.secondaryNaics?.value
          ) &&
          this.secondaryNaicsUpdateOnlyOnce
        ) {
          this.$set(
            this.newIndustryDetails,
            'secondaryNace',
            this.industryDetail?.secondaryNace || null
          )

          this.updateSecondaryNaicsUpdateOnlyOnce(false)
        } // end if
      } else {
        // dropdown list loader flag
        this.$set(this.loadingFlags, this.SECONDARY_NACE, false)
      }
    },

    async updateNace(naceObj) {
      if (this.newIndustryDetails.nace?.value !== naceObj?.value) {
        this.$set(this.newIndustryDetails, 'nace', naceObj)
        if (
          !isEqual(
            this.newIndustryDetails.nace?.value,
            this.industryDetail.nace?.value
          )
        ) {
          this.mandateReupload()
        }
      }

      // resetting the selected values of german ext nace
      this.$set(this.newIndustryDetails, 'germanExtNace', null)

      // resetting the existing german nace list in store
      this.updateGermanNaceList({ naceList: [] })

      // dropdown list loader flag
      this.$set(this.loadingFlags, this.GERMAN_EXTENDED_NACE, true)

      if (this.newIndustryDetails.nace) {
        await this.getGermanNaceList({ naceId: naceObj.value }) // for german extended

        // only pass at initial load
        if (
          isEqual(
            this.newIndustryDetails.nace?.value,
            this.industryDetail?.nace?.value
          ) &&
          this.naceUpdateOnlyOnce
        ) {
          this.$set(
            this.newIndustryDetails,
            'germanExtNace',
            this.industryDetail?.germanExtNace || null
          )

          this.updateNaceUpdateOnlyOnce(false)
        } // end if
      }

      this.$set(this.loadingFlags, this.GERMAN_EXTENDED_NACE, false)
    },

    updateSecondaryNace(secondaryNaceObj) {
      if (
        this.newIndustryDetails.secondaryNace?.value !== secondaryNaceObj?.value
      ) {
        this.$set(this.newIndustryDetails, 'secondaryNace', secondaryNaceObj)
        if (
          !isEqual(
            this.newIndustryDetails.secondaryNace?.value,
            this.industryDetail.secondaryNace?.value
          )
        ) {
          this.mandateReupload()
        }
      }
    },

    updatesic(sicObj) {
      if (this.newIndustryDetails.sic?.value !== sicObj?.value) {
        this.$set(this.newIndustryDetails, 'sic', sicObj)
        if (
          !isEqual(
            this.newIndustryDetails.sic?.value,
            this.industryDetail.sic?.value
          )
        ) {
          this.mandateReupload()
        }
      }
    },

    updateGermanExtNace(germanExtNace) {
      if (
        this.newIndustryDetails.germanExtNace?.value !== germanExtNace?.value
      ) {
        this.$set(this.newIndustryDetails, 'germanExtNace', germanExtNace)
        if (
          !isEqual(
            this.newIndustryDetails.germanExtNace?.value,
            this.industryDetail.germanExtNace?.value
          )
        ) {
          this.mandateReupload()
        }
      }
    },

    /* ACTION BUTTON RELATED METHODS */

    async saveData() {
      this.isMandatory = false
      if (!this.saveValidationCheck()) {
        return
      }

      this.loading = true
      try {
        // TO DO: implement delete api
        // await this.deleteEvidence()
        if (this.tempS3data.length > 0) {
          this.evidenceIdMapping = await this.getEvidenceIdMapping()
          if (this.evidenceIdMapping.sucess === false) {
            Snackbar({
              message: this.evidenceIdMapping.info,
              type: 'is-warning',
              duration: 3000
            })
            this.loading = false
            return
          }
        }

        const {
          sic,
          naics,
          secondaryNaics,
          nace,
          secondaryNace,
          germanExtNace,
          businessNature
        } = this.newIndustryDetails

        const industryEvdId =
          (this.evidenceIdMapping && this.evidenceIdMapping.industryEvdId) ??
          this.industryDetail.industryEvdId

        const updatedIndustryDetail = {
          ...this.newIndustryDetails,
          sic: sic?.value || 'Not Applicable', // cannot not be null db constraint
          naics: naics?.value || 'Not Applicable', // cannot not be null db constraint
          secondaryNaics: secondaryNaics?.value || null,
          nace: nace?.value || 'Not Applicable', // cannot not be null db constraint
          secondaryNace: secondaryNace?.value || null,
          germanExtNace: germanExtNace?.value || null,
          businessNature: businessNature || null,

          industryEvdId: industryEvdId || null
        }

        this.loading = true

        // Check entity status and update it to In Progress, if it's Todo
        await this.checkAndUpdateTodoStatus()

        await this.updateIndustryDetail({
          sgaId: this.sgaId,
          updatedIndustryDetail
        })

        this.resetIndustryVariables()
        this.loading = false
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
    },

    async saveAndNext() {
      this.isMandatory = true

      // all check at once
      const mandatoryValidationPassed = this.mandatoryValidationCheck()
      const genaralValidationPassed = this.saveValidationCheck()

      if (mandatoryValidationPassed && genaralValidationPassed) {
        await this.saveData()
        this.updateStepper(this.activeStepIndexName.identifiers)
        this.isMandatory = false
      }
    },

    saveValidationCheck() {
      // this.hasUnsavedChanges()

      let saveValidation = true

      const {
        naics: newNaics,
        nace: newNace,
        sic: newSic,
        germanExtNace: newGermanExtNace,
        secondaryNaics: newSecondaryNaics,
        secondaryNace: newSecondaryNace,
        businessNature: newBusinessNature
      } = this.newIndustryDetails

      const noChange =
        isEmpty(newNaics) &&
        isEmpty(newNace) &&
        isEmpty(newSic) &&
        isEmpty(newGermanExtNace) &&
        isEmpty(newSecondaryNaics) &&
        isEmpty(newSecondaryNace) &&
        isEmpty(newBusinessNature) &&
        isEmpty(this.industryDetailCopy)

      // empty validation
      if (noChange) {
        Snackbar({
          message:
            'No data to be saved. Please check your input and try again.',
          type: 'is-warning',
          duration: 3000
        })

        saveValidation = false
        return saveValidation
      }

      let itemMismastchFlag = false
      const validationFieldList = [
        { label: this.NAICS, item: newNaics, list: this.naicsList },
        { label: this.NACE, item: newNace, list: this.naceList },
        { label: this.SIC, item: newSic, list: this.sicList },
        {
          label: this.GERMAN_EXTENDED_NACE,
          item: newGermanExtNace,
          list: this.germanExtNaceList
        },
        {
          label: this.SECONDARY_NAICS,
          item: newSecondaryNaics,
          list: this.secondaryNaicsList
        },
        {
          label: this.SECONDARY_NACE,
          item: newSecondaryNace,
          list: this.secondaryNaceList
        }
      ]

      validationFieldList.forEach((obj) => {
        if (!this.isItemInListVerified(obj.item, obj.list)) {
          Snackbar({
            message: `Please select valid ${obj.label} code.`,
            type: 'is-warning',
            indefinite: true
          })
          itemMismastchFlag = true
          return itemMismastchFlag
        }
      })

      // item present in list validation
      if (itemMismastchFlag) {
        saveValidation = false
        return saveValidation
      }

      let {
        naics: oldNaics,
        nace: oldNace,
        sic: oldSic,
        germanExtNace: oldGermanExtNace,
        secondaryNaics: oldSecondaryNaics,
        secondaryNace: oldSecondaryNace,
        businessNature: oldBusinessNature
      } = this.industryDetail

      // for undefined values
      oldNaics = oldNaics ?? null
      oldNace = oldNace ?? null
      oldSic = oldSic ?? null
      oldGermanExtNace = oldGermanExtNace ?? null
      oldSecondaryNaics = oldSecondaryNaics ?? null
      oldSecondaryNace = oldSecondaryNace ?? null
      oldBusinessNature = oldBusinessNature ?? null

      const hasChanges =
        !isEqual(newNaics, oldNaics) ||
        !isEqual(newNace, oldNace) ||
        !isEqual(newSic, oldSic) ||
        !isEqual(newGermanExtNace, oldGermanExtNace) ||
        !isEqual(newSecondaryNaics, oldSecondaryNaics) ||
        !isEqual(newSecondaryNace, oldSecondaryNace) ||
        !isEqual(newBusinessNature, oldBusinessNature)

      const isEqualToInitial = this.compareWithIndustryDetailCopy()

      if (hasChanges || !isEqualToInitial) {
        const isEvidencePresent =
          this.getEvidenceFormStatus(this.INDUSTRY_DETAILS_LABEL) ||
          (this.localEvidenceIdList.includes(this.INDUSTRY_DETAILS_LABEL) &&
            this.evidenceIdList.includes(this.INDUSTRY_DETAILS_LABEL))

        this.$set(
          this.submitStatus,
          this.INDUSTRY_DETAILS_LABEL,
          !isEvidencePresent
        )

        saveValidation = isEvidencePresent
      }

      if (!isEqual(newBusinessNature, oldBusinessNature)) {
        saveValidation =
          saveValidation === false
            ? saveValidation
            : newBusinessNature
            ? newBusinessNature.length <= this.NATURE_OF_BUSINESS_TOTAL_CHARS &&
              isValidEnglishASCII(newBusinessNature)
            : true
      }

      this.localEvidenceIdList.forEach((inputName) => {
        if (!this.evidenceIdList.includes(inputName)) {
          this.$set(this.submitStatus, inputName, true)
          saveValidation = false
        }
      })

      return saveValidation
    }, // endsaveValidation

    mandatoryValidationCheck() {
      const {
        businessNature,
        naics,
        nace,
        sic,
        germanExtNace,
        secondaryNaics,
        secondaryNace
      } = this.newIndustryDetails

      // german extendend nace is optional
      const isMandatory =
        businessNature &&
        naics &&
        nace &&
        sic &&
        (!secondaryNaics || secondaryNace)

      return isMandatory
    },

    hasUnsavedChanges() {
      if (
        !isEqual(
          this.localEvidenceIdList.toSorted(),
          this.evidenceIdList.toSorted()
        )
      ) {
        return true
      }

      // Compare each form field with its initial value
      if (!isEmpty(this.industryDetail)) {
        // not empty then compare
        return !isEqual(this.newIndustryDetails, this.industryDetail)
      }
      return (
        this.newIndustryDetails.naics ||
        this.newIndustryDetails.secondaryNaics ||
        this.newIndustryDetails.secondaryNace ||
        this.newIndustryDetails.sic ||
        this.newIndustryDetails.nace ||
        this.newIndustryDetails.businessNature
      )
    },

    goBack() {
      // Check if there are unsaved changes
      if (this.hasUnsavedChanges()) {
        this.dialog.visible = true
        this.dialog.triggerFunction = () => {
          // User confirmed, discard changes
          this.resetFormData() // Implement a method to reset form data
          this.updateStepper(this.activeStepIndexName.addressDetails)
        }
      } else {
        this.updateStepper(this.activeStepIndexName.addressDetails)
      }
    },

    closeDialog() {
      this.dialog.visible = false
    },

    resetFormData() {
      this.syncData()
    },

    /* EVIDENCE RELATED METHODS */
    async toggleEvidenceForm(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName) // check view or add
      const evidenceIds = this.getEvidenceIds(inputName) // get evidence id coming from backend

      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },

    isAllEvidenceBtnDisabled() {
      if (this.localEvidenceIdList.includes(this.INDUSTRY_DETAILS_LABEL)) {
        // view evidence always enabled
        return false
      }

      const isEqualToInitial = this.compareWithIndustryDetailCopy()

      // disable when no change and enable when there is change
      return isEqualToInitial
    },

    async getEvidenceIdMapping() {
      const response = await this.uploadEvidence() // upload to s3 and data saved in db
      if (response && response.sucess === false) {
        return response
      }

      let evidenceIdMappingArr = response
      evidenceIdMappingArr = evidenceIdMappingArr.map((evidenceObj) => {
        switch (evidenceObj.attributeName) {
          case this.INDUSTRY_DETAILS_LABEL:
            evidenceObj.industryEvdId = evidenceObj.evidenceIds[0].id
            delete evidenceObj.attributeName
            delete evidenceObj.evidenceIds
            return evidenceObj
          default:
            return null
        }
      })

      const evidenceIdMappingObj = Object.assign({}, ...evidenceIdMappingArr)
      return evidenceIdMappingObj
    },

    getEvidenceIds(evidenceId) {
      return this.inputNameToEvdIdMapper[`${evidenceId}`]
    },

    mapInputNameToEvdId(inputName, evidenceId) {
      this.$set(this.inputNameToEvdIdMapper, inputName, evidenceId)
    },

    // return error msg if evidence is not added
    getEvdError(inputName) {
      return !this.evidenceIdList.includes(inputName)
        ? `Please upload evidence for ${inputName}`
        : ''
    },

    updateLocalEvidenceIdList(inputName) {
      const evidenceIdSet = new Set([...this.localEvidenceIdList, inputName])
      this.localEvidenceIdList = [...evidenceIdSet]
    },

    mandateReupload() {
      this.removeEvidence(this.INDUSTRY_DETAILS_LABEL)
    },

    compareWithIndustryDetailCopy() {
      const {
        naics: newNaics,
        nace: newNace,
        sic: newSic,
        germanExtNace: newGermanExtNace,
        secondaryNaics: newSecondaryNaics,
        secondaryNace: newSecondaryNace,
        businessNature: newBusinessNature
      } = this.newIndustryDetails

      const noChange =
        isEmpty(newNaics) &&
        isEmpty(newNace) &&
        isEmpty(newSic) &&
        isEmpty(newGermanExtNace) &&
        isEmpty(newSecondaryNaics) &&
        isEmpty(newSecondaryNace) &&
        isEmpty(newBusinessNature) &&
        isEmpty(this.industryDetailCopy) // industryDetailCopy is the copy of initial data

      let { businessNature: businessNatureNew } = this.newIndustryDetails
      let { businessNature: businessNatureOld } = this.industryDetailCopy

      businessNatureNew = isEmpty(businessNatureNew) ? null : businessNatureNew
      businessNatureOld = isEmpty(businessNatureOld) ? null : businessNatureOld

      let isEqualToInitial = true

      // Iterate over fieldNameList to get fieldName
      for (const property of this.fieldNameList) {
        const newFieldVal = this.newIndustryDetails[property]?.value
          ? this.newIndustryDetails[property].value
          : this.newIndustryDetails[property]
        const oldFieldVal = this.industryDetailCopy[property]?.value
          ? this.industryDetailCopy[property].value
          : this.industryDetailCopy[property]

        const isNonMatching = !isEqual(newFieldVal, oldFieldVal)

        if (isNonMatching) {
          isEqualToInitial = false
          break // exit loop when non-matching found
        }
      }

      // return true when both new and initial are empty or equal
      return (
        noChange ||
        (isEqualToInitial && isEqual(businessNatureNew, businessNatureOld))
      )
    },

    isItemInListVerified(item, list) {
      // item empty no need to check in list
      if (isEmpty(item)) {
        return true
      }

      return list.some((listItem) => {
        return isEqual(listItem.value, item.value)
      })
    }
  } // end methods
}
</script>

<style lang="scss" scoped>
.industry-details-section {
  margin-top: 1rem;
  width: 100%;
  position: relative;

  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .add-evidence {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    width: max-content;
    justify-content: space-between;
    width: 100%;
  }

  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }

  .mandatory-note {
    p {
      font-family: Quicksand;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.14px;
    }
  }

  .mandatory {
    color: red;
    font-size: 17px;
  }

  .industry-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 85%;

    .column-view {
      width: 47%;
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      padding-right: 10px;

      .searchable-select {
        width: 100%;
      }
    }

    .column-view-full {
      margin-top: 2rem;
      width: 100%;
      display: flex;

      .required-error {
        position: relative;
      }

      .dmp-textarea {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .industry-details-section {
    width: 100%;

    .card-header-icon {
      span {
        font: 14px Quicksand;
      }
      .mdi-24px.mdi:before {
        font-size: 24px;
        color: blue;
      }
    }
    .section-title {
      font-size: 24px;
    }
    .mandatory-note {
      p {
        font-size: 13px;
        letter-spacing: 0.14px;
      }
    }
    .mandatory {
      color: red;
      font-size: 12px;
    }
  }
}

@media (min-width: 1700px) {
  .industry-details-section {
    width: 100%;
    .mandatory {
      color: red;
      font-size: 12px;
    }
    .section-title {
      font-size: 26px;
    }
    .card-header-icon {
      span {
        font: 16px;
      }
    }
    .mandatory-note {
      p {
        font-size: 14px;
        letter-spacing: 0.14px;
      }
    }
  }
}
</style>
