<template>
  <div class="button-container">
    <button class="button back-button" v-if="isBackButton" @click="goBack">
      Back
    </button>
    <div class="save-buttons">
      <button
        class="button cancel-button"
        v-if="isCancelButton"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        class="button save-button"
        v-if="isSaveButton"
        @click="saveButton"
        :disabled="isSubmitDisabled"
      >
        Save
      </button>
      <button
        class="button save-next-button"
        v-if="isSaveAndNextButton"
        @click="saveAndNext"
        :disabled="isSubmitDisabled"
      >
        Save & Next
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'ActionButton',
  props: {
    label: {
      type: String
    },
    isSaveButton: {
      type: Boolean,
      default: true
    },
    isSaveAndNextButton: {
      type: Boolean,
      default: true
    },
    isBackButton: {
      type: Boolean,
      default: true
    },
    isCancelButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('assignedEntities', ['isSubmittedEntity']),
    isSubmitDisabled() {
      // If entity status is submit then disable save and save & next button
      return this.isSubmittedEntity
    }
  },
  methods: {
    saveButton() {
      this.$emit('SaveData')
    },
    saveAndNext() {
      this.$emit('saveAndNext')
    },
    goBack() {
      this.$emit('goBack')
    },
    cancel() {
      this.$emit('cancelAction')
    }
  }
}
</script>
<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
  .back-button,
  .cancel-button {
    background: #cccccc 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 14px/17px Quicksand;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;

    &:disabled {
      opacity: 0.7;
    }
  }

  .save-button {
    background: #535eeb 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal 600 14px/17px Quicksand;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;

      &:disabled {
      opacity: 0.7;
    }
  }
  .save-next-button {
    background: #00218a 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal 600 14px/17px Quicksand;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
    
     &:disabled {
      opacity: 0.7;
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .button-container {
    .back-button {
      font: normal normal 600 16px/19px Quicksand;
    }

    .save-button,
    .cancel-button {
      font: normal normal 600 16px/19px Quicksand;
    }
    .save-next-button {
      font: normal normal 600 16px/19px Quicksand;
    }
  }
}
@media (min-width: 1700px) {
  .button-container {
    .back-button {
      font: normal normal 600 18px/23px Quicksand;
    }
    .save-button,
    .cancel-button {
      font: normal normal 600 18px/23px Quicksand;
    }
    .save-next-button {
      font: normal normal 600 18px/23px Quicksand;
    }
  }
}
</style>
