<template>
  <div class="audit-details-container">
    <Grid
      ref="grid"
      :data-items="result"
      :columns="columns"
      :pageable="result.length > 0 ? gridPageable : false"
      :take="take"
      :skip="skip"
      :total="total"
      @pagechange="pageChangeHandler">
    </Grid>
  </div>
</template>
<script>
import { Grid } from '@progress/kendo-vue-grid'
import { mapState } from 'vuex'

export default {
  components: {
    Grid: Grid
  },

  props: {
    auditDetails: {
      type: Array
    }
  },
  data: function () {
    return {
      updatedData: [],
      editID: null,
      group: [{ field: 'UnitsInStock' }],
      expandedItems: [],
      gridPageable: {
        buttonCount: 4,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      result: [],
      skip: 0,
      take: 10,
      total: 0,
      columns: [
        {
          field: 'attributeName',
          title: 'Attribute Name'
        },
        {
          field: 'oldValue',
          title: 'Previous Value'
        },
        {
          field: 'newValue',
          title: 'New Value'
        },

        { field: 'changedBy', title: 'User' },
        {
          field: 'timeStamp',
          title: 'Timestamp'
        }
      ]
    }
  },
  computed: {
    ...mapState('audit', ['auditDetails'])
  },

  watch: {
    auditDetails () {
      this.updateGridData()
    }
  },
  methods: {
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
      this.updateGridData()
    },
    updateGridData () {
      this.result = this.auditDetails.slice(this.skip, this.take + this.skip)
      this.total = this.auditDetails ? this.auditDetails.length : 0
    }
  }
}
</script>

<style lang="scss">
.audit-details-container {
  .k-grid .k-grid-header {
    background: #ebebeb;
  }
  .k-table-td {
    font-size: 14px;
    color: #444444;
  }
  .k-column-title {
    font-size: 14px;
    color: #000000;
  }
}
@media (min-width: 1450px) and (max-width: 1700px) {
  .audit-details-container {
    .k-table-td {
      font-size: 16px;
    }
    .k-column-title {
      font-size: 16px;
    }
  }
}
@media (min-width: 1700px) {
  .audit-details-container {
    .k-table-td {
      font-size: 18px;
    }
    .k-column-title {
      font-size: 18px;
    }
  }
}
</style>
