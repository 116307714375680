<template>
  <div class="relationship-heirachy">
    <TreeView
      :dataItems="treeDataItems"
      :expandIcons="true"
      @expandchange="onExpandChange"
      :ariaMultiselectable="true"
      @itemclick="onItemClick"
      :checkboxes="false"
      @checkchange="onCheckChange"
    />
  </div>
</template>

<script>
import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange
} from '@progress/kendo-vue-treeview'

export default {
  components: {
    TreeView
  },
  props: {
    relationshipHierarchy: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      check: [],
      expand: {
        ids: ['Item2'],
        idField: 'text'
      },
      select: ['']
    }
  },
  computed: {
    treeDataItems() {
      return processTreeViewItems(this.relationshipHierarchy, {
        select: this.select,
        check: this.check,
        expand: this.expand
      })
    }
  },
  methods: {
    onItemClick(event) {
      this.select = [event.itemHierarchicalIndex]
    },
    onExpandChange(event) {
      const ids = this.expand.ids.slice()
      const index = ids.indexOf(event.item.text)
      index === -1 ? ids.push(event.item.text) : ids.splice(index, 1)
      this.expand = {
        ids,
        idField: 'text'
      }
    },
    compactTree() {
      // Reset expand and select properties
      this.expand = {
        ids: ['Item2'], // Reset to default expansion state or an empty array if no default expansion is needed
        idField: 'text'
      }
      this.select = [''] // Reset selection state
    },
    onCheckChange(event) {
      const settings = {
        singleMode: false,
        checkChildren: false,
        checkParents: false
      }
      this.check = handleTreeViewCheckChange(
        event,
        this.check,
        this.tree,
        settings
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.relationship-heirachy {
  margin-left: 15%;

  ::v-deep .k-treeview-toggle {
    margin-left: 5px;
  }
  ::v-deep .k-treeview-item {
    margin-left: 35px;
  }

  ::v-deep .k-treeview {
    white-space: normal;
  }
}
</style>
