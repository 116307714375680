<template>
  <div v-if="open" class="evidence-container">
    <dropdown-wrap-vue @onClickOutside="toggleEvidenceModal">
      <div class="evidence-form-container">
        <!-- preview modal -->
        <div v-if="isPreview" class="preview-image-container">
          <div class="preview-image-header">
            <p>{{ previewImg.name }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" @click="closePreview" v-bind:class="'close-button'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
          </div>
          <p class="preview-image">
            <img :src="previewImg.imageData" alt="" />
          </p>
        </div>

        <div class="evidence-form">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.791" height="18.453" viewBox="0 0 14.791 18.453" v-if="isViewEvidence" @click="removeEvidenceById" v-bind:class="'delete-button'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="delete (2)" fill="#fff"><path data-name="Path 16756" d="M2.567 18.452a.975.975 0 01-.618-1c-.063-1.15-.145-2.3-.222-3.446q-.142-2.118-.289-4.235l-.214-3.14c-.027-.4-.024-.4.389-.4h11.849c.094 0 .127.048.12.137q-.078 1.022-.153 2.045l-.119 1.65q-.1 1.4-.207 2.8-.078 1.068-.153 2.135l-.189 2.673a.886.886 0 01-.577.781zm2.461-6.375v3.455a1.149 1.149 0 00.029.3.479.479 0 00.5.375.465.465 0 00.459-.4 1.688 1.688 0 00.026-.3V8.651a1.634 1.634 0 00-.029-.321.464.464 0 00-.457-.384.479.479 0 00-.5.359 1.124 1.124 0 00-.034.321zm4.707.015v-2.3-1.368a.464.464 0 00-.443-.477.48.48 0 00-.529.385 1.212 1.212 0 00-.023.268v6.965a1.048 1.048 0 00.022.268.5.5 0 00.588.374.486.486 0 00.387-.547q.001-1.788-.001-3.567z"/><path data-name="Path 16757" d="M7.395 5.232H.301c-.3 0-.3 0-.3-.294v-.9a1.2 1.2 0 011.234-1.226h12.246a1.21 1.21 0 011.308 1.315c0 .294-.009.589 0 .882.007.177-.061.227-.229.226h-3.691z"/><path data-name="Path 16758" d="M7.39 1.813H4.565c-.257 0-.267-.01-.268-.259v-.9c.006-.465.2-.654.66-.654h4.876c.447 0 .635.174.652.625.012.323 0 .648.006.972 0 .164-.06.22-.222.219-.959-.006-1.919-.003-2.879-.003z"/></g></svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" @click="toggleEvidenceModal" v-bind:class="'close-button'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
          <div class="form-block">
            <div class="form-header">
              <p class="header-title">
                <span v-if="!isViewEvidence">Add Evidence</span>
                <span v-else>View Evidence</span>
              </p>
            </div>
            <div class="field-block">
              <div class="input-container">
                <InputVue
                  inputId="attributeName"
                  :label="'Attribute Name'"
                  :mandatory="true"
                  :value="attributeName"
                  placeholder="Alias"
                  :disabled="true"
                  class="form-input-field form-control-disabled"
                />
              </div>
              <div class="input-container">
                <InputVue
                  inputId="sourceUrl"
                  label="Source URL"
                  :value="sourceUrl"
                  @onChangeName="updateSourceUrl"
                  :min="1"
                  :max="4000"
                  placeholder="Add URL"
                  class="evidence-input-bg"
                />
              </div>

              <div class="input-container">
                <InputVue
                  inputId="docRefUrl"
                  label="Docurment Reference URL"
                  :value="docRefUrl"
                  @onChangeName="updateDocRefUrl"
                  :min="1"
                  :max="4000"
                  placeholder="Add URL"
                  class="evidence-input-bg"
                />
              </div>

              <EvidenceFormUpload
                :uploadView="isViewEvidence"
                :formName="formName"
                @viewFile="viewFile"
              ></EvidenceFormUpload>

              <div v-if="!isEvidenceValid && showError" class="required-error">
                Evidence required *
              </div>
            </div>
            <div class="footer-group-button">
              <div class="cancel-button">
                <b-button @click="toggleEvidenceModal">
                  <p>Cancel</p>
                </b-button>
              </div>
              <div class="submit-button">
                <b-button :disabled="loading" @click="submit">
                  <div v-if="loading" class="loader-container">
                    <div class="inava-loader"></div>
                  </div>
                  <p v-else>Save</p>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        :visible="dialog.visible"
        :title="dialog.title"
        :bodyText="dialog.text"
        :cancelButtonText="dialog.cancelButtonText"
        :confirmButtonText="dialog.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="dialog.triggerFunction"
      />
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '@/components/Common/DropdownWrap.vue'
import InputVue from '@/views/Admin/components/Input.vue'
import EvidenceFormUpload from '@/components/DMP/EvidenceFormUpload.vue'
import { isEmpty, isEqual, xorWith } from 'lodash'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
/**
 * This component is used to add evidence. It can accept text and images
 */
export default {
  name: 'EvidenceForm',
  components: {
    DropdownWrapVue,
    InputVue,
    EvidenceFormUpload,
    ConfirmationDialog
  },

  props: {
    open: Boolean,
    attributeName: String,
    evidenceId: String,
    isViewEvidence: Boolean,
    formName: String
  },

  data() {
    return {
      loading: false, // yet to decide
      showError: false,
      isSaved: false,
      previewImg: {
        imageData: '',
        name: ''
      },
      previousSourceUrl: null,
      previousdocRefUrl: null,
      isPreview: false,
      dialog: {
        visible: false,
        title: 'Discard changes',
        text: 'Would you like to discard the changes?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },
      isRemoveEvidence: false
    }
  },
  created() {
    this.previousSourceUrl = this.sourceUrl
    this.previousdocRefUrl = this.docRefUrl
  },

  computed: {
    ...mapState('evidence', [
      'selectedUploadFiles',
      'sourceUrl',
      'docRefUrl',
      'isMaxNoOfFileValid',
      'isFileSizeValid',
      'isEvidenceValid',
      'isChangesMadeInForm'
    ])
  },

  watch: {
    sourceUrl(newSourceUrl, oldSourceUrl) {
      this.updateChangesInEvidenceFormFlag(
        newSourceUrl !== this.previousSourceUrl
      )
    },

    docRefUrl(newDocRefUrl, oldDocRefUrl) {
      this.updateChangesInEvidenceFormFlag(
        newDocRefUrl !== this.previousdocRefUrl
      )
    }
  },

  methods: {
    ...mapActions('evidence', [
      'addEvidence',
      'getEvidenceById',
      'updateSourceUrl',
      'updateDocRefUrl',
      'resetEvidenceForm',
      'updateIsEvidenceValid',
      'removeFromEvidenceIdList',
      'removeEvidence',
      'updateChangesInEvidenceFormFlag'
    ]),

    isArrayEqual(firstArray, secondArray) {
      return isEmpty(xorWith(firstArray, secondArray, isEqual))
    },

    /** This method is used to close the component if a click is detected outside its boundaries
     * or click cancel or click close button
     * @public
     */
    toggleEvidenceModal(event) {
      // Check if there are unsaved changes
      if (this.isChangesMadeInForm || this.isRemoveEvidence) {
        this.dialog.visible = true
        this.dialog.triggerFunction = () => {
          // User confirmed, discard changes
          this.updateChangesInEvidenceFormFlag(false)
          this.closePreview() // closing the preview popup
          if (this.isRemoveEvidence) {
            this.removeEvidence(this.evidenceId)
            this.isRemoveEvidence = false
          }
          this.$emit('closeEvidenceForm', false)
        }
      } else {
        // No unsaved changes, navigate back directly
        this.closePreview() // closing the preview popup
        this.updateChangesInEvidenceFormFlag(false)
        this.$emit('closeEvidenceForm', false)
      }
    },

    /**
     * This method is used to submit the contents of the evidence form
     * @public
     */
    async submit() {
      this.showError = true

      if (
        this.isEvidenceValid &&
        this.isFileSizeValid &&
        this.isMaxNoOfFileValid &&
        this.selectedUploadFiles &&
        this.selectedUploadFiles.length > 0
      ) {
        const data = {
          evidenceId: this.evidenceId,
          sourceUrl: this.sourceUrl,
          docRefUrl: this.docRefUrl,
          files: this.selectedUploadFiles
        }

        await this.addEvidence({ evidence: data })

        // evidence form changes saved and now saved value will be shown
        this.updateChangesInEvidenceFormFlag(false)
        this.closePreview() // closing the preview popup
        this.$emit('closeEvidenceForm', false)
      } else {
        // submit failed due to evidence form validation
        this.updateIsEvidenceValid(false) // resetting back to default
      }
    }, // end submit

    viewFile(index) {
      const fileReader = new FileReader()
      fileReader.onload = (file) => {
        this.previewImg.imageData = file.target.result
        this.isPreview = true
      }

      this.previewImg.name = this.selectedUploadFiles[index].name
      fileReader.readAsDataURL(this.selectedUploadFiles[index])
    },

    closePreview() {
      this.isPreview = false
    },

    removeEvidenceById() {
      this.isRemoveEvidence = true
      this.toggleEvidenceModal()
    },

    closeDialog() {
      this.isRemoveEvidence = false // resetting to default
      this.dialog.visible = false
    }
  } // end methods
}
</script>

<style lang="scss" scoped>
.evidence-container {
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 10;

  .evidence-form-container {
    position: relative;

    .preview-image-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 726px;
      height: 558px;
      background-color: #eeefff;
      padding: 54px;
      z-index: 99;

      .preview-image-header {
        display: flex;
        justify-content: space-between;
        align-content: center;

        .close-button {
          width: 12px;
          height: 12px;
          align-self: center;
          cursor: pointer;
        }
      }

      .preview-image {
        margin-top: 35px;

        img {
          width: 619px;
          height: 393px;
        }
      }
    }

    .evidence-form {
      position: absolute;
      right: 15px;
      width: 411px;
      min-height: 440px;
      height: auto;
      bottom: 15px;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #eeefff;
      padding-bottom: 30px;

      .form-block {
        .footer-group-button {
          display: flex;
          padding: 16px 20px 0px;
          justify-content: space-between;

          .cancel-button {
            display: flex;
            flex: 0.5;
            justify-content: flex-start;

            button {
              &[disabled] {
                opacity: 0.6;
              }
              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              outline: none;
              border: none;
              height: 32px;
              background-color: #cccccc;
              padding: 5px;
              border-radius: 4px;

              p {
                opacity: 0.9;
                font-family: Quicksand, sans-serif;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #444444;
                margin: 0px;
              }
              .spinner-border {
                color: #444444;
                width: 15px;
                height: 15px;
                border: 2px solid currentColor;
                border-right-color: transparent;
              }
            }
          }
          .submit-button {
            display: flex;
            flex: 0.5;
            justify-content: flex-end;

            .loader-container {
              display: flex;

              .inava-loader {
                position: relative;
                display: flex;
                margin: auto;
                border-radius: 50%;
                border: none;
                z-index: 900;
                width: 40px;
                height: 40px;
                background-image: '@/assets/Inava-logo-GIF.gif';
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }

              .loader {
                display: inline-block;
                width: 20px;
                height: 20px;
                border: 2px solid white;
                border-right-color: transparent;
                border-radius: 50%;
                -webkit-animation: spinner-border 0.75s linear infinite;
                animation: spin 0.75s linear infinite;
              }

              /* Safari */
              @-webkit-keyframes spin {
                0% {
                  -webkit-transform: rotate(0deg);
                }
                100% {
                  -webkit-transform: rotate(360deg);
                }
              }

              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            }
            button {
              &[disabled] {
                opacity: 0.6;
              }

              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              outline: none;
              border: none;
              height: 32px;
              background-color: #535eeb;
              padding: 5px;
              border-radius: 4px;

              p {
                opacity: 0.9;
                font-family: Quicksand, sans-serif;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
                margin: 0px;
              }
              .spinner-border {
                color: #ffffff;
                width: 15px;
                height: 15px;
                border: 2px solid currentColor;
                border-right-color: transparent;
              }
            }
          }

          .required-size-error {
            color: red;
            position: absolute;
            font-size: 12px;
            margin-top: 30px;
            margin-left: 2px;
          }
        }
        .form-header {
          display: flex;
          flex-direction: column;
          background: #00218a;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding-left: 12px;

          p {
            margin: 0px;
            color: white;
            font-size: 12px;
            font-family: Quicksand, sans-serif;
          }
          .header-title {
            font-weight: 500;
            padding: 10px 0px;
            font-size: 16px;
            font-family: Quicksand, sans-serif;
            font-weight: 600;
          }
        }
        .field-block {
          padding: 16px 20px 20px 20px;
          display: flex;
          position: relative;
          flex-direction: column;

          p {
            margin: 0px;
          }

          .input-container {
            margin-top: 17px;
          }

          .control {
            margin: 0px;
          }
        }
      }

      .close-button {
        position: absolute;
        outline: none;
        width: 16px;
        height: 16px;
        fill: white;
        right: 15px;
        top: 14px;
        cursor: pointer;
      }

      .delete-button {
        position: absolute;
        outline: none;
        width: 18px;
        height: 18px;
        fill: white;
        right: 45px;
        top: 13px;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.evidence-container {
  .evidence-form-container {
    .evidence-form {
      .form-block {
        .field-block {
          .input-container {
            .form-group {
              p {
                color: #9b9b9b;
              }
            }

            .evidence-input-bg > .form-control {
              background-color: #ffffff;
            }

            .form-control-disabled > .form-control {
              background-color: #dfdfdf;
            }

            .input::-moz-placeholder {
              color: #444444;
            }
            .input::-webkit-input-placeholder {
              color: #444444;
            }
            .input:-moz-placeholder {
              color: #444444;
            }
            .input:-ms-input-placeholder {
              color: #444444;
            }
          }
        }
      }
    }
  }
}
</style>
