var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"number-field-container"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"number-field-label"},[_vm._v(" "+_vm._s(_vm.label)),(_vm.isMandatory)?_c('span',{staticClass:"mandatory"},[_vm._v("*")]):_vm._e()]),_c('NumericTextBox',{staticClass:"custom-number-field",style:({ width: '100%' }),attrs:{"placeholder":_vm.placeholder,"spinners":false},on:{"change":function($event){return _vm.validateNumber()}},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1),(_vm.showValidationMessage)?_c('div',{staticClass:"required-error"},[_vm._v(" Ownership % must be between 0 and 100 ")]):_vm._e(),(_vm.showControlValidationMessage)?_c('div',{staticClass:"required-error"},[_vm._v(" Control Ownership % must be between 50 and 100 ")]):_vm._e(),(_vm.showSignificantValidationMessage)?_c('div',{staticClass:"required-error"},[_vm._v(" Significant influence Ownership % must be between 25 and 50 ")]):_vm._e(),(
        _vm.submitted &&
        _vm.isMandatory &&
        _vm.isEmpty &&
        !_vm.showControlValidationMessage &&
        !_vm.showSignificantValidationMessage
      )?_c('div',{staticClass:"required-error"},[_vm._v(" "+_vm._s(_vm.label)+" is required ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }