import { render, staticRenderFns } from "./BVDIdentifiersForm.vue?vue&type=template&id=fde13522&scoped=true&"
import script from "./BVDIdentifiersForm.vue?vue&type=script&lang=js&"
export * from "./BVDIdentifiersForm.vue?vue&type=script&lang=js&"
import style0 from "./BVDIdentifiersForm.vue?vue&type=style&index=0&id=fde13522&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fde13522",
  null
  
)

export default component.exports