<template>
  <div class="bvd-parent-container">
    <div class="title-row" v-if="isDataPresent">
      <div class="title-container">
        <div class="title client">Client Attributes</div>
        <label class="select-all">
          <input
            type="checkbox"
            v-model="selectAllClient"
            :checked="selectAllClient"
            @change="toggleAllClient"
          />
          <span>Select All</span>
        </label>
      </div>
      <div class="title-container">
        <div class="title bvd">
          BVD Attributes
          <span v-if="attributesData?.bvdIdNumber"
            >({{ attributesData.bvdIdNumber }})</span
          >
        </div>
        <label class="select-all">
          <input
            type="checkbox"
            v-model="selectAllBVD"
            :checked="selectAllBVD"
            @change="toggleAllBVDDetails"
          />
          <span>Select All</span>
        </label>
      </div>
    </div>
    <div class="bvd-container" v-if="isDataPresent">
      <div class="attribute-container">
        <!-- Legal Entity Name -->
        <div class="attribute-item">
          <div
            class="client-attributes client-attributes-first-child"
            v-if="attributesData.isClientDataPresent"
          >
            <div class="radio-group" v-if="attributesData.isClientDataPresent">
              <RadioButton
                :name="safeAttributeData.legalEntityName.label"
                :value="selections.legalEntityName.client"
                :checked="
                  selections.legalEntityName.client ===
                    safeAttributeData.legalEntityName.clientData &&
                  safeAttributeData.legalEntityName.clientData !== null
                "
                @change="
                  (event) =>
                    handleClientRadioChange(
                      event,
                      'legalEntityName',
                      safeAttributeData.legalEntityName.clientData
                    )
                "
                :class="'attr-radio'"
                :disabled="
                  safeAttributeData.legalEntityName.readOnly ||
                  safeAttributeData.legalEntityName.clientData === null
                "
              />
            </div>
            <div class="label-group" v-if="attributesData.isClientDataPresent">
              <label class="attr-title">{{
                safeAttributeData.legalEntityName.label
              }}</label>
              <label class="value">{{
                safeAttributeData.legalEntityName.clientData
              }}</label>
            </div>
          </div>
          <div
            v-else
            class="client-attributes client-attributes-first-child no-data-message"
          >
            No data available.
          </div>
          <div
            class="bvd-attributes bvd-attributes-first-child"
            v-if="attributesData.isBvdDataPresent"
          >
            <div class="radio-group" v-if="attributesData.isBvdDataPresent">
              <RadioButton
                :name="safeAttributeData.legalEntityName.label"
                :value="selections.legalEntityName.bvd"
                :checked="
                  selections.legalEntityName.bvd ===
                    safeAttributeData.legalEntityName.bvdData &&
                  safeAttributeData.legalEntityName.bvdData !== null
                "
                @change="
                  (event) =>
                    handleBvdRadioChange(
                      event,
                      'legalEntityName',
                      safeAttributeData.legalEntityName.bvdData
                    )
                "
                :class="'attr-radio'"
                :disabled="
                  safeAttributeData.legalEntityName.readOnly ||
                  safeAttributeData.legalEntityName.bvdData === null
                "
              />
            </div>
            <div
              class="bvd-legal-status"
              v-if="attributesData.isBvdDataPresent"
            >
              <div class="label-group">
                <label class="attr-title">{{
                  safeAttributeData.legalEntityName.label
                }}</label>
                <label class="value">{{
                  safeAttributeData.legalEntityName.bvdData
                }}</label>
              </div>
            </div>
          </div>
          <div
            v-else
            class="bvd-attributes bvd-attributes-first-child no-data-message"
          >
            No data available.
          </div>
        </div>

        <!-- Previous Entity Name -->
        <div class="attribute-item">
          <div class="client-identifier-section">
            <table
              ref="clientPreviousEntityNameTable"
              v-if="attributesData.isClientDataPresent"
            >
              <thead>
                <tr>
                  <th colspan="5">Previous Entity Name</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, index) in parseData(
                    safeAttributeData.previousEntityName.clientData
                  )"
                  :key="index"
                  class="identifier-row"
                >
                  <td>
                    <Checkbox
                      @change="
                        (event) =>
                          handleCheckBoxChange(
                            event,
                            'previousEntityName',
                            'client',
                            option
                          )
                      "
                      :value="option"
                      :checked="
                        selections.previousEntityName.client.includes(option)
                      "
                      :class="'attr-checkbox'"
                      :disabled="safeAttributeData.previousEntityName.readOnly"
                    />
                  </td>
                  <td>{{ option }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bvd-identifier-section">
            <table
              ref="bvdPreviousEntityNameTable"
              v-if="attributesData.isBvdDataPresent"
            >
              <thead>
                <tr>
                  <th colspan="5">Previous Entity Name</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, index) in parseData(
                    safeAttributeData.previousEntityName.bvdData
                  )"
                  :key="index"
                  class="identifier-row"
                >
                  <td>
                    <Checkbox
                      @change="
                        (event) =>
                          handleCheckBoxChange(
                            event,
                            'previousEntityName',
                            'bvd',
                            option
                          )
                      "
                      :value="option"
                      :checked="
                        selections.previousEntityName.bvd.includes(option)
                      "
                      :class="'attr-checkbox'"
                      :disabled="safeAttributeData.previousEntityName.readOnly"
                    />
                  </td>
                  <td>{{ option }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Alias -->
        <div class="attribute-item">
          <div class="client-identifier-section">
            <table
              ref="clientAliasTable"
              v-if="attributesData.isClientDataPresent"
            >
              <thead>
                <tr>
                  <th colspan="5">Alias</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, index) in parseData(
                    safeAttributeData.alias.clientData
                  )"
                  :key="index"
                  :ref="'clientRow' + index"
                  class="identifier-row"
                >
                  <td>
                    <Checkbox
                      @change="
                        (event) =>
                          handleCheckBoxChange(event, 'alias', 'client', option)
                      "
                      :value="option"
                      :checked="selections.alias.client.includes(option)"
                      :class="'attr-checkbox'"
                      :disabled="safeAttributeData.alias.readOnly"
                    />
                  </td>
                  <td>{{ option }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bvd-identifier-section">
            <table ref="bvdAliasTable" v-if="attributesData.isBvdDataPresent">
              <thead>
                <tr>
                  <th colspan="5">Alias</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, index) in parseData(
                    safeAttributeData.alias.bvdData
                  )"
                  :key="index"
                  :ref="'bvdRow' + index"
                  class="identifier-row"
                >
                  <td>
                    <Checkbox
                      @change="
                        (event) =>
                          handleCheckBoxChange(event, 'alias', 'bvd', option)
                      "
                      :value="option"
                      :checked="selections.alias.bvd.includes(option)"
                      :class="'attr-checkbox'"
                      :disabled="safeAttributeData.alias.readOnly"
                    />
                  </td>
                  <td>{{ option }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Business Status Table -->
        <div class="attribute-item">
          <div class="client-attributes"></div>
          <div class="bvd-attributes">
            <table
              class="business-status-table"
              v-if="attributesData.isBvdDataPresent"
            >
              <thead>
                <tr>
                  <th colspan="5">Business Status</th>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>Date Updated (DD/MM/YYYY)</th>
                  <th>Date <br />(DD/MM/YYYY)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, index) in safeAttributeData.businessStatus
                    .bvdData"
                  :key="index"
                >
                  <td>{{ option.status }}</td>
                  <td>{{ formatDate(option.date_updated) }}</td>
                  <td>{{ formatDate(option.date) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--Legal Structure -->
        <div class="attribute-item">
          <div class="client-attributes"></div>
          <div class="bvd-attributes">
            <div
              class="label-group text-only"
              v-if="attributesData.isBvdDataPresent"
            >
              <label class="attr-title">{{
                safeAttributeData.legalStructure.label
              }}</label>
              <label class="value">{{
                parseDataToCSV(safeAttributeData.legalStructure.bvdData)
              }}</label>
            </div>
          </div>
        </div>

        <!--Entity Type -->
        <div class="attribute-item">
          <div class="client-attributes"></div>
          <div class="bvd-attributes">
            <div
              class="label-group text-only"
              v-if="attributesData.isBvdDataPresent"
            >
              <label class="attr-title">{{
                safeAttributeData.entityType.label
              }}</label>
              <label class="value">{{
                parseDataToCSV(safeAttributeData.entityType.bvdData)
              }}</label>
            </div>
          </div>
        </div>

        <!--Website Address -->
        <div class="attribute-item">
          <div class="client-attributes"></div>
          <div class="bvd-attributes">
            <div
              class="label-group text-only"
              v-if="attributesData.isBvdDataPresent"
            >
              <label class="attr-title">{{
                safeAttributeData.website.label
              }}</label>
              <label class="value">{{
                (safeAttributeData.website.bvdData || []).join(', ')
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isDataPresent" class="button-container">
        <b-button
          class="action-button cancel-button"
          :size="'is-small'"
          @click="cancelChanges"
          >Reset</b-button
        >
        <b-button
          class="action-button confirm-button"
          :size="'is-small'"
          @click="saveChanges"
          >Update Form</b-button
        >
      </div>
    </div>
    <div v-else>
      <div v-if="loading">Loading...</div>
      <div v-else>No data available.</div>
    </div>
  </div>
</template>

<script>
import { Checkbox, RadioButton } from '@progress/kendo-vue-inputs'
import { mapActions } from 'vuex'

export default {
  components: {
    Checkbox,
    RadioButton
  },
  props: {
    attributesData: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectAllClient: false,
      selectAllBVD: false,
      selections: {
        legalEntityName: {
          client: null,
          bvd: null
        },
        alias: {
          client: [],
          bvd: []
        },
        previousEntityName: {
          client: [],
          bvd: []
        }
      },
      defaultStateSet: false,
      touchedFields: {
        alias: false,
        previousEntityName: false
      },
      isSaveButtonClicked: false
    }
  },
  computed: {
    isDataLoaded() {
      return (
        !this.loading &&
        this.attributesData &&
        Object.keys(this.attributesData).length > 0
      )
    },
    isDataPresent() {
      return (
        this.attributesData !== null &&
        (this.attributesData.isClientDataPresent ||
          this.attributesData.isBvdDataPresent)
      )
    },
    safeAttributeData() {
      const defaultField = {
        label: '',
        clientData: '',
        bvdData: '',
        readOnly: false
      }

      const bvdBasicDetailsFieldsMetadata = {
        legalEntityName: {
          label: 'Legal Entity Name',
          readOnly: false
        },
        alias: {
          label: 'Alias',
          readOnly: false
        },
        previousEntityName: {
          label: 'Previous Entity Name',
          readOnly: false
        },
        businessStatus: {
          label: 'Business Status',
          readOnly: true
        },
        legalStructure: {
          label: 'Legal Structure',
          readOnly: true
        },
        entityType: {
          label: 'Entity Type',
          readOnly: true
        },
        website: {
          label: 'Website Address',
          readOnly: true
        }
      }

      const mergedData = {}

      for (const key in bvdBasicDetailsFieldsMetadata) {
        if (this.attributesData.data && this.attributesData.data[key]) {
          mergedData[key] = {
            ...defaultField,
            ...bvdBasicDetailsFieldsMetadata[key],
            clientData: this.attributesData.data[key].clientData,
            bvdData: this.attributesData.data[key].bvdData
          }
        } else {
          mergedData[key] = {
            ...defaultField,
            ...bvdBasicDetailsFieldsMetadata[key]
          }
        }
      }

      return mergedData
    },
    combinedSelections() {
      return {
        legalEntityName: this.selections.legalEntityName,
        alias: [...this.selections.alias.client, ...this.selections.alias.bvd],
        previousEntityName: [
          ...this.selections.previousEntityName.client,
          ...this.selections.previousEntityName.bvd
        ]
      }
    }
  },
  watch: {
    attributesData: {
      handler() {
        this.$nextTick(() => {
          if (this.isDataLoaded) {
            this.setDefaultState()
          }
          this.syncRowHeights()
        })
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.isDataLoaded) {
      this.setDefaultState()
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isDataLoaded) {
        this.setDefaultState()
      }
      this.syncRowHeights()
      window.addEventListener('resize', this.syncRowHeights)
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.syncRowHeights)
  },
  activated() {
    // This hook is called when the component is re-activated in keep-alive
    this.resetComponent()
  },
  methods: {
    ...mapActions('basicDetails', [
      'updateBasicDetailsFormData',
      'undoBasicDetailsFormData'
    ]),
    parseData(data) {
      return data ? data.split(/##|\|/) : []
    },
    parseDataToCSV(data) {
      const parsedData = this.parseData(data)
      return parsedData.join(',')
    },
    markFieldAsTouched(field) {
      this.touchedFields[field] = true
    },
    handleCheckBoxChange(event, fieldName, attributeType = 'client', value) {
      const { value: checked } = event

      let currentSelections = [...this.selections[fieldName][attributeType]]

      if (checked) {
        currentSelections.push(value)
      } else {
        currentSelections = currentSelections.filter(
          (selection) => selection !== value
        )
      }

      this.selections[fieldName][attributeType] = currentSelections

      this.touchedFields[fieldName] = true
    },
    setDefaultState() {
      if (!this.isDataLoaded) return
      this.selections.legalEntityName.client =
        this.safeAttributeData.legalEntityName.clientData
      this.selections.legalEntityName.bvd = null // Ensure BVD is unselected by default
      this.selections.alias.client = this.parseData(
        this.safeAttributeData.alias.clientData
      )
      this.selections.alias.bvd = []
      this.selections.previousEntityName.client = this.parseData(
        this.safeAttributeData.previousEntityName.clientData
      )
      this.selections.previousEntityName.bvd = []

      // reset the touched fields
      this.touchedFields = {
        ...this.touchedFields,
        alias: false,
        previousEntityName: false
      }

      this.isSaveButtonClicked = false
    },
    handleClientRadioChange(event, field, value) {
      this.selections[field] = {
        ...this.selections[field],
        client: value,
        bvd: null
      }
      this.markFieldAsTouched(field)
    },
    handleBvdRadioChange(event, field, value) {
      this.selections[field] = {
        ...this.selections[field],
        client: null,
        bvd: value
      }
      this.markFieldAsTouched(field)
    },
    saveChanges() {
      const updatedData = this.buildUpdatedData()
      this.updateBasicDetailsFormData(updatedData)
      this.isSaveButtonClicked = true
    },
    cancelChanges() {
      this.resetComponent()
    },
    resetComponent() {
      this.setDefaultState()
    },
    buildUpdatedData() {
      const selections = this.combinedSelections
      const updatedData = {}

      if (selections.legalEntityName.client !== null) {
        updatedData.legalEntityName = selections.legalEntityName.client
      } else if (selections.legalEntityName.bvd !== null) {
        updatedData.legalEntityName = selections.legalEntityName.bvd
      }

      if (
        selections.alias.length > 0 ||
        (this.isSaveButtonClicked && this.touchedFields.alias)
      ) {
        updatedData.alias =
          selections.alias.length > 0 ? selections.alias.join('|') : ''
      }

      if (
        selections.previousEntityName.length > 0 ||
        (this.isSaveButtonClicked && this.touchedFields.previousEntityName)
      ) {
        updatedData.previousEntityName =
          selections.previousEntityName.length > 0
            ? selections.previousEntityName.join('|')
            : ''
      }
      return updatedData
    },
    toggleAllBVDDetails() {
      //
    },
    toggleAllClient() {
      //
    },
    syncRowHeights() {
      if (!this.isDataLoaded) return

      const rowPairs = [
        ['clientPreviousEntityNameTable', 'bvdPreviousEntityNameTable'],
        ['clientAliasTable', 'bvdAliasTable']
      ]

      rowPairs.forEach(([clientRef, bvdRef]) => {
        const clientRow = this.$refs[clientRef]
        const bvdRow = this.$refs[bvdRef]
        if (clientRow && bvdRow) {
          const maxHeight = Math.max(
            clientRow.offsetHeight,
            bvdRow.offsetHeight
          )
          clientRow.style.height = `${maxHeight}px`
          bvdRow.style.height = `${maxHeight}px`
        }
      })
    },
    formatDate(value) {
      if (value) {
        const date = new Date(value)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.no-data-message {
  display: flex !important;
  justify-content: center !important;
  text-align: center;
  padding: 20px;
  color: #424242;
}

.attr-radio::v-deep,
.attr-checkbox::v-deep {
  input {
    border: 1px solid #707070;
  }
}
table {
  border: 1px solid #cccccc;
  margin: 0.5em;
  width: 100%;
  font-size: 12px;
  thead {
    tr {
      th {
        padding: 0.3em;
        color: #7d7d7d;
      }
    }
  }
  tbody {
    tr,
    .identifier-row {
      td {
        padding: 0.3em;
        color: #1e1e1e;
        vertical-align: middle;
        text-align: left;
      }
      td:first-child {
        vertical-align: middle;
        width: 8.5%;
      }

      td:nth-child(2) {
        // Correctly select the second child
        padding-bottom: 0.6rem;
      }
    }
    .identifier-row {
      td {
        vertical-align: middle;
      }
    }
  }
}

.business-status-table {
  width: 100%;
  thead {
    tr,
    th,
    td {
      border: 1px solid #cccccc;
    }

    th {
      width: 27.5%;
    }
    th:first-child {
      width: 45%;
    }
  }
  tbody {
    border: 1px solid #cccccc;
    tr,
    th,
    td {
      border: 1px solid #cccccc;
    }
  }
}

.title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1em;
  padding-top: 2em;
  padding-bottom: 0;
  height: 1.5em;

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 49%;
    .title {
      font-size: 22px;
    }
    .client {
      color: #00218a;
    }
    .bvd {
      color: #f57777;
    }

    .select-all {
      opacity: 0;
      display: flex;
      align-items: center;
      padding-bottom: 1.5rem;
    }

    .select-all input {
      margin-right: 10px;
      text-align: left;
      font: normal normal medium 18px/22px Quicksand;
      letter-spacing: 0px;
      color: #444444;
      opacity: 1;
    }
  }
  .title-container:first-child {
    width: 44%;
  }
  .title-container:last-child {
    width: 53.8%;
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  height: 5rem;
  padding-bottom: 0.6rem;
  width: 100%;
  gap: 1rem;

  .action-button {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    min-width: 104px;
    height: 46px;
  }
  .confirm-button {
    background-color: #535eeb; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: left;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
  }
  .confirm-button:hover {
    background-color: #00218a; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    opacity: 0.9;
  }

  .cancel-button {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;
  }
  .cancel-button:hover {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    opacity: 0.9;
  }
}

.bvd-container {
  padding: 1em;

  .button-container {
    justify-content: end;
    .save-buttons {
      width: 15%;
      display: flex;
      justify-content: space-around;
    }
  }
}

.attr-radio::v-deep,
.attr-checkbox::v-deep {
  input {
    border: 1px solid #707070;
  }
}
.attribute-container {
  .attribute-item::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    .client-attributes,
    .bvd-attributes,
    .client-identifier-section,
    .bvd-identifier-section {
      width: 49%;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      padding-bottom: 1em;
      background-color: #f9f9f9;
      align-items: center;
      line-height: 1.7em;
      border: 1px solid #cccccc;
      border-width: 0 1px;
      border-top: none;
      .radio-group {
        padding: 1em;
        padding-left: 0.6em;
      }
      .label-group {
        display: flex;
        flex-direction: column;

        .attr-title {
          color: #7d7d7d;
          font-size: 12px;
        }
        .value {
          color: #1e1e1e;
          font-size: 13px;
        }
      }

      .text-only {
        margin-left: 0.6em;
      }
      .attr-radio-td {
        display: inline;
      }
      .bvd-legal-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.3em;
      }
    }
    .client-attributes,
    .client-identifier-section {
      border-top: none;
      width: 45%;
      table {
        line-height: 1.7em;
      }

      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #00218a;
        box-shadow: none;
      }
    }

    .bvd-attributes,
    .bvd-identifier-section {
      width: 53%;
      table {
        line-height: 1.7em;
        tbody {
          tr,
          .identifier-row {
            td:first-child {
              width: 7.1%;
            }
          }
        }
      }

      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #f57777;
        box-shadow: none;
      }
    }
  }
  .attribute-item:last-child {
    .client-attributes,
    .bvd-attributes {
      border-bottom: 1px solid #cccccc;
    }
  }
  .attribute-item .client-attributes-first-child {
    border-top: 1px solid #cccccc;
    padding-top: 1em;
  }
  .attribute-item .bvd-attributes-first-child {
    border-top: 1px solid #cccccc;
    padding-top: 1em;
  }
  .attribute-item:last-child {
    .client-attributes,
    .bvd-attributes {
      padding-bottom: 2em;
    }
  }
  .checkbox-group {
    display: flex;
    flex-direction: column;
  }
}
</style>
