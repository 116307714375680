export const quickReferenceData = {
  partyId: {
    label: 'Party ID',
    value: 'BNYM1234'
  },
  countryOfDomicile: {
    label: 'Country of Domicile',
    value: 'USA'
  },
  leiName: {
    label: 'Legal Entity Name',
    value: 'Private'
  },
  bestAddress: {
    label: 'Best Address',
    value: 'The Trustee for Banksia Avenue Super Fund, Cupertino, California.'
  }
}

// constants.js

export const mockApiData = {
  sgaId: '',
  alias: '',
  previousEntityName: '',
  legalEntityName: '',
  businessStatus: '',
  legalStructure: '',
  entityType: '',
  entitySubType: '',
  branchIndicator: false,
  usGSEFlag: false
}

export const legalStructureOptions = [
  { value: 'LLC', text: 'LLC' },
  { value: 'LTG', text: 'LTG' },
  { value: 'AG', text: 'AG' }
]

export const businessStatusOptions = [
  { value: 'Active', text: 'Active' },
  { value: 'Inactive', text: 'Inactive' }
]

export const entityTypeOptions = [
  { value: 'Government', text: 'Government' },
  { value: 'Financial Institution', text: 'Financial Institution' },
  { value: 'Non-financial', text: 'Non-financial' },
  { value: 'Listed', text: 'Listed' },
  { value: 'Private', text: 'Private' },
  { value: 'SPV', text: 'SPV' }
]

export const entitySubTypeOptions = [
  { value: 'LLC', text: 'LLC' },
  { value: 'LTG', text: 'LTG' },
  { value: 'AG', text: 'AG' }
]

export const usGSEFlagOptions = [
  { value: true, text: 'Yes' },
  { value: false, text: 'No' }
]

export const branchIndicatorOptions = [
  { value: true, text: 'Yes' },
  { value: false, text: 'No' }
]
export const nationalIdentifierTypeOptions = [
  { value: 0, text: 'Yes' },
  { value: 1, text: 'Mock Data' },
  { value: 2, text: 'No' }
]
export const anaCreditInstitutionalSectorOptions = [
  { value: 0, text: 'Yes' },
  { value: 1, text: 'Mock Data' },
  { value: 2, text: 'No' }
]
export const anaCreditLegalFormOptions = [
  { value: 0, text: 'Yes' },
  { value: 1, text: 'Mock Data' },
  { value: 2, text: 'No' }
]
export const statusOfLegalProceedingOptions = [
  { value: 0, text: 'Yes' },
  { value: 1, text: 'Mock Data' },
  { value: 2, text: 'No' }
]
export const anaCreditInstitutionalSubSectorOptions = [
  { value: 0, text: 'Yes' },
  { value: 1, text: 'Mock Data' },
  { value: 2, text: 'No' }
]

export const BVD_FORM_KEYS_MAPPING = {
  BASIC_DETAILS: 'BASIC_DETAILS',
  ADDRESS: 'ADDRESS',
  INDUSTRY_DETAILS: 'INDUSTRY_DETAILS',
  IDENTIFIERS: 'IDENTIFIERS',
  ANACREDIT: 'ANACREDIT',
  RELATIONSHIPS: 'RELATIONSHIPS'
}
