<template>
  <div>
    <div class="number-field-container">
      <div class="wrapper">
        <div class="number-field-label">
          {{ label }}<span v-if="isMandatory" class="mandatory">*</span>
        </div>
        <NumericTextBox
          class="custom-number-field"
          :style="{ width: '100%' }"
          :placeholder="placeholder"
          :spinners="false"
          v-model="number"
          @change="validateNumber()"
        />
      </div>
      <div v-if="showValidationMessage" class="required-error">
        Ownership % must be between 0 and 100
      </div>
      <div v-if="showControlValidationMessage" class="required-error">
        Control Ownership % must be between 50 and 100
      </div>
      <div v-if="showSignificantValidationMessage" class="required-error">
        Significant influence Ownership % must be between 25 and 50
      </div>

      <div
        v-if="
          submitted &&
          isMandatory &&
          isEmpty &&
          !showControlValidationMessage &&
          !showSignificantValidationMessage
        "
        class="required-error"
      >
        {{ label }} is required
      </div>
    </div>
  </div>
</template>
<script>
import { NumericTextBox } from '@progress/kendo-vue-inputs'
import { control, significantInfluence } from '@/constant/data.js'
export default {
  name: 'SearchableSelect',
  components: {
    NumericTextBox
  },
  props: {
    label: String,
    placeholder: String,
    disabled: Boolean,
    value: Number,
    onChangeName: {
      type: Function
    },
    submitted: {
      type: Boolean,
      default: false
    },
    isMandatory: {
      type: Boolean,
      default: false
    },
    isSignificantSelected: {
      type: Boolean,
      default: false
    },
    isControlSelected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      number: this.value,
      showValidationMessage: false,
      showControlValidationMessage: false,
      showSignificantValidationMessage: false,
      isNotValid: false
    }
  },
  computed: {
    isEmpty() {
      return this.number === null || this.number === ''
    }
  },

  watch: {
    number() {
      this.$emit('onChange', this.number, this.isNotValid)
    },
    value() {
      this.number = this.value
    }
  },
  methods: {
    validateNumber() {
      const num = parseFloat(this.number) // Convert input to float
      if (this.isSignificantSelected && this.isControlSelected) {
        this.showSignificantValidationMessage = false
        this.showControlValidationMessage = !(
          num > control.MIN_VALUE && num <= control.MAX_VALUE
        )
        this.isNotValid = !(num > control.MIN_VALUE && num <= control.MAX_VALUE)
      } else if (this.isSignificantSelected && !this.isControlSelected) {
        this.showControlValidationMessage = false
        this.showSignificantValidationMessage = !(
          num >= significantInfluence.MIN_VALUE &&
          num <= significantInfluence.MAX_VALUE
        )
        this.isNotValid = !(
          num >= significantInfluence.MIN_VALUE &&
          num <= significantInfluence.MAX_VALUE
        )
      } else if (this.isControlSelected && !this.isSignificantSelected) {
        this.showSignificantValidationMessage = false
        this.showControlValidationMessage = !(
          num > control.MIN_VALUE && num <= control.MAX_VALUE
        )
        this.isNotValid = !(num > control.MIN_VALUE && num <= control.MAX_VALUE)
      }
    },
    clearMessage() {
      this.showValidationMessage = false
      this.showControlValidationMessage = false
      this.showSignificantValidationMessage = false
    }
  }
}
</script>

<style lang="scss" scoped>
.number-field-container {
  min-width: 135px;
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .number-field-label {
      color: #7d7d7d;
      font-size: 12px;
      font-family: Quicksand;
      padding: 0px 20px 0px 0px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: auto;
    }
    .required-error {
      color: red;
      font-size: 12px;
      margin-top: 4px;
    }
    .custom-number-field {
      width: 100%;
      height: 36px;
      position: relative;
    }
    .k-input {
      border-color: #cccccc;
      opacity: 1;
    }
    .k-disabled {
      background-color: rgb(83, 94, 235, 0.1);

      .k-input-inner {
        color: #00218a;
        opacity: 1;
      }
    }

    .k-input-button {
      background-color: transparent;
      border-left: none;
      border-inline-start-width: 0px;
    }
    .k-button-solid-base {
      border-color: rgba(0, 0, 0, 0);
      color: white;
      background-color: white;
      background-image: none;
    }
    .k-input-inner {
      text-align: left;
      font-size: 13px;
      font-weight: 600;
      color: #1e1e1e;
      opacity: 1;
    }

    .k-input-inner::placeholder {
      text-align: left;
      font: normal normal 13px/16px Quicksand;
      font-weight: 400;
      letter-spacing: 0.18px;
      color: #cccccc;
      opacity: 1;
    }
    .k-input-button:hover {
      background-color: transparent;
    }

    .mandatory {
      color: red;
      font-size: 1rem;
    }
    .k-input-button {
      background-color: transparent;
    }

    .colored-icon {
      color: #a8aab7;
      width: 1rem;
      height: 1rem;
      font-size: larger;
    }
  }
}
@media (min-width: 1450px) and (max-width: 1700px) {
  .number-field-container {
    min-width: 155px;

    .wrapper {
      .number-field-label {
        font-size: 15px;
        min-height: 25px;
      }
      .k-input-inner {
        font-size: 15px;
      }
    }
  }
}
@media (min-width: 1700px) {
  .number-field-container {
    min-width: 185px;

    .wrapper {
      .number-field-label {
        min-height: 28px;
        font-size: 18px;
      }
      .k-input-inner {
        font-size: 18px;
      }
    }
  }
}
</style>
