<template>
  <div class="corporate-action">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <div class="form-not-available" v-if="isCADetails() && !loading">
      Corporate Action details not found.
    </div>
    <div v-else>
      <div class="quick-reference">
        <div class="section-title" :class="{ 'collapsed-title': collapsed }">
          <p>Quick Reference: Received Details</p>

          <button class="collapse-button" @click="collapsed = !collapsed">
            <span v-if="collapsed">
              <span class="k-icon k-i-arrow-60-down icon-size"></span>
            </span>

            <span v-else>
              <span class="k-icon k-i-arrow-60-up icon-size"></span>
            </span>
          </button>
        </div>

        <section
          v-show="!collapsed"
          class="ca-section quick-reference-details-content"
        >
          <div class="section-rows">
            <div class="ca-form-group">
              <label>Corporate Action:</label>

              <span class="blue-text" readonly>
                <b-button class="ca-action-button">{{
                  caDetails.corporateActionType
                }}</b-button>
              </span>
            </div>

            <div class="ca-form-group">
              <label>Legal Entity Name:</label>

              <span class="blue-text" readonly>{{
                caDetails.legalEntityName
              }}</span>
            </div>
          </div>

          <div class="section-rows">
            <div class="ca-form-group">
              <label>Description:</label>
            </div>

            <div class="ca-form-group">
              <label>{{ caDetails.corporateActionName }}</label>
            </div>

            <div class="ca-form-group"></div>
          </div>

          <div class="section-rows">
            <div class="ca-form-group">
              <InputVue
                :label="'Occurrence Date:'"
                :mandatory="true"
                placeholder="Occurrence Date"
                class="form-input-field form-control-disabled"
                :disabled="true"
                :value="caDetails.corporateActionDate"
              />
            </div>
          </div>
        </section>
      </div>
      <!-- v-modal="caDetails.actionComment" -->

      <div class="corporate-action-form">
        <div class="column-view-full">
          <div class="dmp-textarea">
            <DMPTextArea
              :label="'Corporate Action Taken'"
              :type="'textarea'"
              :rows="5"
              :min="1"
              :max="500"
              :value="newCADetails.corporateActionTaken"
              :disabled="caDetails.isAddressed"
              :required="!this.newCADetails.isNotRelevant"
              :placeholder="`Corporate Action Taken`"
              @onChangeName="updateActionTaken"
              :submitted="submitted"
              :isAsciiEnglishCharactersOnly="true"
            ></DMPTextArea>
          </div>
        </div>
        <!-- v-model="caDetails.isAddressed" -->

        <div class="column-view-full">
          <div class="ca-checkbox-group">
            <input
              type="checkbox"
              @change="changeRelevantStatus()"
              v-model="newCADetails.isNotRelevant"
              :disabled="caDetails.isAddressed"
            />
            <span class="label-info">Not Relevant CA</span>
          </div>
        </div>
      </div>

      <template>
        <ActionButton
          v-if="!caDetails.isAddressed"
          :isSaveAndNextButton="false"
          :isBackButton="false"
          :isCancelButton="true"
          @SaveData="saveData"
          @cancelAction="cancelCA"
        >
        </ActionButton>
      </template>
    </div>

    <template>
      <ConfirmationDialog
        :type="dialog.type"
        :visible="dialog.visible"
        :title="dialog.title"
        :bodyText="dialog.text"
        :cancelButtonText="dialog.cancelButtonText"
        :confirmButtonText="dialog.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="dialog.triggerFunction"
      />
    </template>
  </div>
</template>

<script>
import ActionButton from '@/components/DMP/ActionButton.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import DMPTextArea from '@/components/DMP/DMPTextArea'
import { isValidEnglishASCII } from '@/util/util'
import InputVue from '@/views/Admin/components/Input.vue'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CorporateActions',
  components: {
    InputVue,
    ActionButton,
    DMPTextArea,
    ConfirmationDialog
  },
  props: {
    // Define the updateStepper prop
    caDetails: {
      type: Object
    },
    changeTabStrip: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      collapsed: false,
      isMandatory: false,
      submitted: false,
      // confirmation popup
      dialog: {
        type: 'confirm',
        visible: false,
        title: 'Confirmation',
        text: '',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Ok',
        triggerFunction: () => {}
      },
      newCADetails: {
        corporateActionTaken: '',
        isNotRelevant: false
      },
      sgaId: '',
      loading: this.isLoading
    }
  },
  computed: {
    ...mapGetters('dmp', ['getEntityDetails']),
    ...mapState('corporateAction', ['isLoading']),

    isValidForm() {
      return (this.newCADetails.isNotRelevant
        ? true
        : !this.isNullValue(this.newCADetails.corporateActionTaken)) &&
        this.isNullValue(this.newCADetails.corporateActionTaken)
        ? true
        : this.newCADetails.corporateActionTaken.length <= 500 &&
            isValidEnglishASCII(this.newCADetails.corporateActionTaken)
    }
  },
  watch: {
    isLoading(newVal) {
      this.loading = newVal
    },
    caDetails() {
      this.syncCADetails()
    }
  },
  async mounted() {
    try {
      this.submitted = false
      const { sgaId } = this.getEntityDetails
      this.sgaId = sgaId
      await this.getCorporateAction(sgaId)
    } catch (error) {}
  },
  methods: {
    ...mapActions('corporateAction', [
      'getCorporateAction',
      'updateCorporateAction'
    ]),
    syncCADetails() {
      this.newCADetails.corporateActionTaken =
        this.caDetails.corporateActionTaken
      this.newCADetails.isNotRelevant = this.isNullValue(
        this.caDetails.isRelevantCA
      )
        ? false
        : !this.caDetails.isRelevantCA
    },
    saveData() {
      this.submitted = true
      if (!this.isValidForm) return
      this.dialog.text = 'Are you sure you want to save the Details?'
      this.dialog.triggerFunction = async () => {
        const updateResult = await this.updateCorporateAction({
          sgaId: this.sgaId,
          updateData: {
            actionComment: this.newCADetails.corporateActionTaken,
            isNotRelevant: this.newCADetails.isNotRelevant // TODO: this.caDetails.isNotRelevant
          }
        })
        if (updateResult === 200) {
          await this.getCorporateAction(this.sgaId)
          this.submitted = false
        }
      }
      this.dialog.visible = true
    },
    cancelCA() {
      this.changeTabStrip(0)
    },
    changeRelevantStatus() {},
    closeDialog() {
      this.dialog.visible = false
    },
    updateActionTaken(actionComment) {
      this.newCADetails.corporateActionTaken = actionComment
    },
    isNullValue(value) {
      return [null, undefined, ''].includes(value)
    },
    isCADetails() {
      return isEmpty(this.caDetails) || !this.caDetails?.isCorporateAction
    }
  }
}
</script>

<style lang="scss">
.corporate-action {
  .form-not-available {
    text-align: left;
    font: normal normal 13px/16px Quicksand;
    font-weight: 600;
    letter-spacing: 0.18px;
    height: 200px;
    font-size: large;
    color: #f57777;
  }
  margin-bottom: 2em;
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .quick-reference {
    // Accordion Title CSS

    .section-title {
      text-align: left;
      font: normal normal 14px/18px Quicksand;
      font-weight: 500;
      letter-spacing: 0px;
      color: #888888;
      opacity: 1;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      }
    }

    .collapse-button {
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
    }

    .collapsed-title {
      background: #dfdfdf 0% 0% no-repeat padding-box;
    }

    .icon-size {
      font-size: 24px;
      color: #7d7d7d;
    }

    // -------- End Title Section --------

    // -------- Accordion Content Section --------

    .ca-section {
      flex: 1;
      padding: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .quick-reference-details {
      position: relative;
    }

    .quick-reference-details-content {
      border: 1px solid #c4c8f8;
      font-size: 0.9rem;
      font-weight: 100;
    }

    .ca-action-button {
      background: #00218a;
      opacity: 1;
      text-align: left;
      font-size: 13px;
      letter-spacing: 0.18px;
      font-weight: bold;
      color: #fff;
      opacity: 1;
    }

    .section-rows {
      display: flex;
      flex-direction: column;
      width: 30%;
      margin-bottom: 10px;
      .ca-form-group,
      .history-group {
        margin-bottom: 0.5rem;
        align-items: center;
        label {
          text-align: left;
          font: normal normal 13px/16px Quicksand;
          font-weight: 500;
          letter-spacing: 0.18px;
          color: #7d7d7d;
          opacity: 1;
          vertical-align: -moz-middle-with-baseline;
        }

        .blue-text {
          text-align: left;
          font: normal normal bold 13px/16px Quicksand;
          letter-spacing: 0.18px;
          color: #535eeb;
          opacity: 1;
          vertical-align: -moz-middle-with-baseline;
          padding-left: 8px;
        }

        .form-control-disabled > .form-control {
          width: 100%;
          background-color: rgb(83, 94, 235, 0.1);
          .input {
            text-align: left;
            letter-spacing: 0.18px;
            color: #00218a !important;
            opacity: 0.9;
            font: normal normal bold 13px/23px Quicksand;
            font-weight: 600;
            width: 100%;
          }
        }

        .form-input-field {
          width: 70%;
          p {
            text-align: left;
            font: normal normal 13px/16px Quicksand;
            font-weight: 500;
            letter-spacing: 0.18px;
            color: #7d7d7d;
            opacity: 1;
            vertical-align: -moz-middle-with-baseline;
            margin-bottom: 10px;
          }
        }
      }

      .history-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 70%;
        label {
          .history-flag {
            vertical-align: top;
          }
        }

        .ca-button {
          .view-history-btn {
            opacity: 1;
            text-align: left;
            font-size: 13px;
            letter-spacing: 0.18px;
            color: #727272;
            opacity: 1;
            border: 1px solid #00218a;
          }
        }
      }
    }

    .section-rows:nth-child(1) {
      flex: 3;
    }
    .section-rows:nth-child(2) {
      flex: 5;
    }
    .section-rows:nth-child(3) {
      flex: 2;
    }

    // XX Accordion Content Section XX
  }

  .corporate-action-form {
    .column-view-full {
      margin-top: 1rem;
      width: 100%;
      display: flex;

      .dmp-textarea {
        width: 100%;
      }

      .ca-checkbox-group {
        .label-info {
          font-size: 14px;
          padding-left: 4px;
        }
      }
    }
  }

  .button-container {
    justify-content: end;
    .save-buttons {
      width: 15%;
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>
