<template>
  <div class="form-group">
    <p v-if="showLable" class="label-flex">
      <span class="label-info">{{ label }}<span v-if="required" class="display-flex required">*</span>
      </span>
      <span class="label-info secondary-label">
        For SGA Internal use only
      </span>
    </p>
    <b-input
      :type="type"
      :rows="rows"
      v-model="name"
      class="form-control"
      :disabled="disabled"
      :readonly="readonly"
      @keydown.native.enter="onEnterFun"
      :placeholder="placeholder"></b-input>

    <div v-if="errorToDisplay" class="required-error">
      {{ errorToDisplay }}
    </div>
    <span v-if="!$v.name.minLength" class="required-error">{{ label }} must be greater than {{ min }} characters.</span>
    <span v-if="!$v.name.maxLength" class="required-error">{{ label }} must be less than {{ max }} characters.</span>
    <span v-if="!$v.name.email" class="required-error">Email is invalid.</span>
    <span v-if="!$v.name.alphaNum" class="required-error">Only alphanumeric characters allowed.</span>
    <span v-if="!$v.name.url && submitted" class="required-error">Enter correct url.</span>
    <span v-if="!$v.name.asciiEnglishCharactersOnly && $v.name.minLength && $v.name.maxLength" class="required-error">Non English characters are not allowed.</span>
  </div>
</template>
<script>
import {
  required,
  alphaNum,
  email,
  minLength,
  maxLength,
  url,
  helpers
} from 'vuelidate/lib/validators'

/** This component is used to take in user inputs */
export default {
  name: 'DMPTextArea',
  props: {
    /** This prop contains the text for the label of the input */
    label: {
      type: String
    },

    /** This prop defines the height of the input */
    rows: {
      type: Number,
      default: 1
    },

    /** This prop defines the behavior for when the input value is changed */
    onChangeName: {
      type: Function
    },

    /** This prop indicates what type of input is being created */
    type: {
      type: String,
      default: 'text'
    },

    /** This prop indicates the value of the input */
    value: {
      default: null
    },

    /** This prop indicates whether or not a form submission has been attempted */
    submitted: {
      type: Boolean,
      default: false
    },

    /** This prop indicates the min value of the input */
    min: {
      type: Number,
      default: null
    },

    /** This prop indicates the max value of the input */
    max: {
      type: Number,
      default: null
    },

    /** This prop indicates whether a value is required for this input or not */
    required: {
      type: Boolean,
      default: false
    },

    /** This prop indicates whether a value is valid or not */
    isValid: {
      type: Function
    },

    /** This prop indicates whether a value is editable or not */
    readonly: {
      type: Boolean,
      default: false
    },

    /** This prop contains the placeholder text for the input */
    placeholder: {
      type: String,
      default: ''
    },

    /** This prop indicates whether or not this input is editable */
    disabled: {
      type: Boolean,
      default: false
    },

    /** This prop indicates whether or not a label must be shown for this input */
    showLable: {
      type: Boolean,
      default: true
    },

    /** This prop defines the behavior of this input when Enter is pressed (?) */
    onEnter: {
      type: Function
    },

    showSecondaryError: {
      type: Boolean,
      default: false
    },

    secondaryError: {
      type: String,
      default: ''
    },

    isDefaultErrorMessage: {
      type: Boolean,
      default: true
    },
    isAsciiEnglishCharactersOnly: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      name: null
    }
  },

  computed: {
    errorToDisplay () {
      if (
        !this.$v.name.required &&
        this.submitted &&
        this.required &&
        this.isDefaultErrorMessage
      ) {
        return `${this.label} is required`
      } else if (this.showSecondaryError && this.secondaryError) {
        return this.secondaryError
      } else {
        return ''
      }
    }
  },

  watch: {
    name () {
      this.$v.$touch()
      this.$emit('onChangeName', this.name, this.$v.name.$invalid)
    },

    value () {
      this.name = this.value
    }
  },

  mounted () {
    this.$v.$touch()
    this.$emit('onChangeName', this.name, this.$v.name.$invalid)
  },

  validations () {
    return {
      // eslint-disable-next-line no-constant-condition
      name: {
        required: this.required ? required : false,
        email: this.type === 'email' ? email : false,
        url: this.type === 'url' ? url : false,
        alphaNum: this.type === 'password' || 'email' ? false : alphaNum,
        minLength: minLength(this.min),
        maxLength: maxLength(this.max),
        // eslint-disable-next-line no-control-regex
        asciiEnglishCharactersOnly: this.isAsciiEnglishCharactersOnly === true ? helpers.regex('asciiEnglishCharactersOnly', /^[\s\x00-\x84\u201A\u201E\u20AC\u0192]+$/) : false
      }
    }
  },

  methods: {
    onEnterFun () {
      this.$emit('onEnter')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  p {
    display: flex;
    margin: 0px;
    font-family: Quicksand;
    display: flex;
    line-height: 20px;
  }

  .label-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.15rem;

    .label-info {
      color: #7d7d7d;
      font-size: 12px;
    }

    .secondary-label {
      color: #888888;
      font-size: 10px;
      font-weight: normal;
    }
  }
}

.required-error {
  color: red;
  position: relative;
  font-size: 12px;
}

.required {
  color: red;
  font-size: 12px;
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .form-group {
    .label-flex {
      .label-info {
        font-size: 14px;
      }

      .secondary-label {
        font-size: 12px;
      }
    }
  }

  .required {
    font-size: 14px;
  }
}

@media (min-width: 1700px) {
  .form-group {
    .label-flex {
      .label-info {
        font-size: 16px;
      }

      .secondary-label {
        font-size: 14px;
      }
    }
  }

  .required {
    font-size: 16px;
  }
}
</style>
