<template>
  <div class="ana-credit-details-section">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <div class="form-not-available" v-if="!isAnaCredit">
      This form is not available due to non applicability of AnaCredit details.
      See the above quick reference details.
    </div>
    <div v-if="isAnaCredit">
      <h2 class="section-title">AnaCredit Details</h2>
      <div class="mandatory-note">
        <p>
          Text boxes marked as
          <span class="mandatory">*</span> are mandatory.
          <span v-if="!this.registeredAddrCountryId"
            >Country should be filled before entering a value for National
            Identifier Type.</span
          >
        </p>
      </div>
      <div class="tax-input-container">
        <template v-for="(pair, index) in nationalIdentifiersPairs">
          <div class="tax-input-view">
            <div class="column-view">
              <SearchableSelect
                :value="nationalIdentifierTypes[index]"
                label="National Identifier Type"
                :placeholder="'Select National Identifier Type'"
                :isMandatory="pair.isMandatory"
                @onChangeName="
                  (value) => updateNationalIdentifierType(value, index)
                "
                :mandatorySubmit="
                  isMandatory
                    ? isMandatory
                    : !isNullValue(nationalIdentifierIds[index])
                "
                value_field="value"
                text_field="text"
                class="form-input-field"
                :data="pair.typeOption"
                :textLength="dropdownTextLength"
                :isLoading="pair.isDropdownLoading"
                :submitted="submitted"
              />
            </div>
            <div class="column-view">
              <InputVue
                :inputId="'nationalIdentifierNo_' + (index + 1)"
                :label="'National Identifiers'"
                :placeholder="'Enter National Identifier'"
                :disabled="false"
                class="form-input-field"
                :required="
                  nationalIdentifierTypes[index]?.text?.includes(
                    ANA_C_IDENTIFIERS_NA.TEXT
                  ) ||
                  nationalIdentifierTypes[index]?.text?.includes(
                    ANA_C_IDENTIFIERS_NOT_APP.TEXT
                  )
                    ? true
                    : pair.isMandatory
                "
                :submitted="submitted"
                :isDefaultErrorMessage="
                  isMandatory
                    ? isMandatory
                    : !isNullValue(nationalIdentifierTypes[index]?.value)
                "
                :min="1"
                type="text"
                :max="50"
                :alphaNumBracsAndHyphen="true"
                :value="nationalIdentifierIds[index]"
                :showSecondaryError="!isNullValue(pair.evidenceError)"
                :secondaryError="pair.evidenceError"
                @onChangeName="
                  (value) => updateNationalIdentifierID(value, index)
                "
              />
              <div class="add-evidence">
                <AddEvidence
                  :hasEvidenceButton="true"
                  :evidenceFormStatus="
                    evdMethodsForSubField(index).evidenceFormStatus()
                  "
                  @click.native="
                    toggleEvidenceForm(
                      `${anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index + 1}`
                    )
                  "
                  :btnLoading="evdMethodsForSubField(index).btnLoadingStatus()"
                  :isDisabled="
                    isEvidenceBtnDisabled('nationalIdentifierTypes', { index })
                  "
                />
              </div>
            </div>
          </div>
          <div class="tax-button-view">
            <button @click="addInputPair()" class="add-tax-button">
              <span class="button-icon">
                <SvgIcon :icon="plusIcon" :size="'large'" class="icon" />
              </span>
            </button>
            <button @click="removeInputPair(index)" class="add-tax-button">
              <span class="button-icon">
                <SvgIcon :icon="xIcon" :size="'large'" class="icon" />
              </span>
            </button>
          </div>
        </template>
      </div>

      <div class="ana-credit-form">
        <div class="column-view">
          <SearchableSelect
            :value="newAnaCDetails.institutionalSector"
            :showSecondaryError="!isNullValue(evdErrorMsg.institutionalSector)"
            :secondaryError="evdErrorMsg.institutionalSector"
            label="AnaCredit Institutional Sector"
            :placeholder="'Select AnaCredit Institutional Sector'"
            :data="instSectorOpts"
            value_field="value"
            text_field="text"
            class="form-input-field"
            :isMandatory="isAnaCredit"
            :mandatorySubmit="isMandatory"
            @onChangeName="updateAnaCreditInstitutionalSector"
            :textLength="dropdownTextLength"
            :isLoading="dropdownLoader.institutionalSector"
            :submitted="submitted"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(anaCreditEvdIds.INST_SECTOR)
              "
              @click.native="toggleEvidenceForm(anaCreditEvdIds.INST_SECTOR)"
              :btnLoading="getBtnLoadingStatus(anaCreditEvdIds.INST_SECTOR)"
              :isDisabled="isEvidenceBtnDisabled('institutionalSector')"
            />
          </div>
        </div>
        <div class="column-view">
          <SearchableSelect
            :value="newAnaCDetails.legalForm"
            :showSecondaryError="!isNullValue(evdErrorMsg.legalForm)"
            :secondaryError="evdErrorMsg.legalForm"
            label="AnaCredit Legal Form"
            :placeholder="'Select AnaCredit Legal Form'"
            :data="legalFormOpts"
            value_field="value"
            text_field="text"
            class="form-input-field"
            :isMandatory="isAnaCredit"
            :mandatorySubmit="isMandatory"
            @onChangeName="updateLegalForm"
            :textLength="dropdownTextLength"
            :isLoading="dropdownLoader.legalForm"
            :submitted="submitted"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(anaCreditEvdIds.LEGAL_FORM)
              "
              @click.native="toggleEvidenceForm(anaCreditEvdIds.LEGAL_FORM)"
              :btnLoading="getBtnLoadingStatus(anaCreditEvdIds.LEGAL_FORM)"
              :isDisabled="isEvidenceBtnDisabled('legalForm')"
            />
          </div>
        </div>
      </div>

      <div class="anacredit-subsection">
        <p class="ana-credit-subtitle">Legal Proceedings</p>
        <hr />
        <div class="ana-credit-form">
          <div class="column-view">
            <DatePicker
              :label="'Date of Initiation of Legal Proceedings'"
              :date="newAnaCDetails.legalProceedDate"
              @onChangeDate="onChangeLegalDate"
              :isFutureDateAllowed="true"
              :isMandatory="isAnaCredit && isLegalDate"
              :submitted="submitted"
              :isDefaultErrorMessage="isMandatory"
              :showSecondaryError="!isNullValue(evdErrorMsg.legalProceedDate)"
              :secondaryError="evdErrorMsg.legalProceedDate"
              class="form-input-field"
            >
            </DatePicker>
            <div class="add-evidence">
              <AddEvidence
                :hasEvidenceButton="true"
                :evidenceFormStatus="
                  getEvidenceFormStatus(anaCreditEvdIds.LEGAL_PROCEED_DATE)
                "
                @click.native="
                  toggleEvidenceForm(anaCreditEvdIds.LEGAL_PROCEED_DATE)
                "
                :btnLoading="
                  getBtnLoadingStatus(anaCreditEvdIds.LEGAL_PROCEED_DATE)
                "
                :isDisabled="isEvidenceBtnDisabled('legalProceedDate')"
              />
            </div>
          </div>

          <div class="column-view">
            <SearchableSelect
              :value="newAnaCDetails.legalProceedStatus"
              :showSecondaryError="!isNullValue(evdErrorMsg.legalProceedStatus)"
              :secondaryError="evdErrorMsg.legalProceedStatus"
              label="Status of Legal Proceedings"
              :placeholder="'Select Status of legal proceedings'"
              :data="legalProceedStatusOpts"
              value_field="value"
              text_field="text"
              class="form-input-field"
              :isMandatory="isAnaCredit"
              :mandatorySubmit="isMandatory"
              @onChangeName="updateStatusOfLegalProceedings"
              :textLength="dropdownTextLength"
              :isLoading="dropdownLoader.legalProceedStatus"
              :submitted="submitted"
            />
            <div class="add-evidence">
              <AddEvidence
                :hasEvidenceButton="true"
                :evidenceFormStatus="
                  getEvidenceFormStatus(anaCreditEvdIds.LEGAL_PROCEED_STATUS)
                "
                @click.native="
                  toggleEvidenceForm(anaCreditEvdIds.LEGAL_PROCEED_STATUS)
                "
                :btnLoading="
                  getBtnLoadingStatus(anaCreditEvdIds.LEGAL_PROCEED_STATUS)
                "
                :isDisabled="isEvidenceBtnDisabled('legalProceedStatus')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="anacredit-subsection">
        <p class="ana-credit-subtitle">Enterprise Details</p>
        <hr />
        <div class="ana-credit-form">
          <div class="column-view">
            <DatePicker
              :label="'Date of Enterprise Size'"
              :date="newAnaCDetails.enterpriseSizeDate"
              @onChangeDate="onChangeEnterpriseDate"
              :showSecondaryError="!isNullValue(evdErrorMsg.enterpriseSizeDate)"
              :secondaryError="evdErrorMsg.enterpriseSizeDate"
              :isFutureDateAllowed="true"
              :isMandatory="isAnaCredit && isEnterpriseSizeAvailable"
              :submitted="submitted"
              :isDefaultErrorMessage="isMandatory"
              class="form-input-field"
            >
            </DatePicker>
            <div class="add-evidence">
              <AddEvidence
                :hasEvidenceButton="true"
                :evidenceFormStatus="
                  getEvidenceFormStatus(anaCreditEvdIds.ENTERPRISE_SIZE_DATE)
                "
                @click.native="
                  toggleEvidenceForm(anaCreditEvdIds.ENTERPRISE_SIZE_DATE)
                "
                :btnLoading="
                  getBtnLoadingStatus(anaCreditEvdIds.ENTERPRISE_SIZE_DATE)
                "
                :isDisabled="isEvidenceBtnDisabled('enterpriseSizeDate')"
              />
            </div>
          </div>
          <div class="column-view">
            <InputVue
              inputId="noOfEmployees"
              label="Number of Employees"
              :value="newAnaCDetails.noOfEmployees"
              :showSecondaryError="!isNullValue(evdErrorMsg.noOfEmployees)"
              :secondaryError="evdErrorMsg.noOfEmployees"
              @onChangeName="updateNumberOfEmployees"
              placeholder="Enter Number of Employees"
              :hasEvidenceButton="true"
              :submitted="submitted"
              :isDefaultErrorMessage="isMandatory"
              type="number"
              :isPositive="true"
              :isNonZero="true"
              class="form-input-field"
            />
            <div class="add-evidence">
              <AddEvidence
                :hasEvidenceButton="true"
                :evidenceFormStatus="
                  getEvidenceFormStatus(anaCreditEvdIds.NO_OF_EMPL)
                "
                @click.native="toggleEvidenceForm(anaCreditEvdIds.NO_OF_EMPL)"
                :btnLoading="getBtnLoadingStatus(anaCreditEvdIds.NO_OF_EMPL)"
                :isDisabled="isEvidenceBtnDisabled('noOfEmployees')"
              />
            </div>
          </div>
          <div class="column-view">
            <InputVue
              inputId="balanceSheetTotal"
              label="Balance Sheet Total"
              :value="newAnaCDetails.balanceSheetTotal"
              :showSecondaryError="!isNullValue(evdErrorMsg.balanceSheetTotal)"
              :secondaryError="evdErrorMsg.balanceSheetTotal"
              type="number"
              :isPositive="true"
              @onChangeName="updateBalanceSheetTotal"
              placeholder="Enter Balance Sheet Total"
              :submitted="submitted"
              :isDefaultErrorMessage="isMandatory"
              :hasEvidenceButton="true"
              class="form-input-field"
            />
            <div class="add-evidence">
              <AddEvidence
                :hasEvidenceButton="true"
                :evidenceFormStatus="
                  getEvidenceFormStatus(anaCreditEvdIds.BALANCE_SHEET)
                "
                @click.native="
                  toggleEvidenceForm(anaCreditEvdIds.BALANCE_SHEET)
                "
                :btnLoading="getBtnLoadingStatus(anaCreditEvdIds.BALANCE_SHEET)"
                :isDisabled="isEvidenceBtnDisabled('balanceSheetTotal')"
              />
            </div>
          </div>
          <div class="column-separator">
            <h3><b>OR</b></h3>
          </div>
          <div class="column-view">
            <InputVue
              inputId="annualTurnover"
              label="Annual Turnover as Per Year"
              :value="newAnaCDetails.annualTurnover"
              :showSecondaryError="!isNullValue(evdErrorMsg.annualTurnover)"
              :secondaryError="evdErrorMsg.annualTurnover"
              type="number"
              @onChangeName="updateAnnualTurnoverPerYear"
              placeholder="Enter amount"
              :submitted="submitted"
              :isDefaultErrorMessage="isMandatory"
              :hasEvidenceButton="true"
              class="form-input-field"
            />
            <div class="add-evidence">
              <AddEvidence
                :hasEvidenceButton="true"
                :evidenceFormStatus="
                  getEvidenceFormStatus(anaCreditEvdIds.ANNUAL_TURNOVER)
                "
                @click.native="
                  toggleEvidenceForm(anaCreditEvdIds.ANNUAL_TURNOVER)
                "
                :btnLoading="
                  getBtnLoadingStatus(anaCreditEvdIds.ANNUAL_TURNOVER)
                "
                :isDisabled="isEvidenceBtnDisabled('annualTurnover')"
              />
            </div>
          </div>
          <div class="column-view">
            <SearchableSelect
              :value="enterpriseSizeCalculatedObj"
              :showSecondaryError="!isNullValue(evdErrorMsg.enterpriseSize)"
              :secondaryError="evdErrorMsg.enterpriseSize"
              label="Enterprise Size"
              :placeholder="'Enter Enterprise Size'"
              :data="enterpriseSizeOpts"
              value_field="value"
              text_field="text"
              class="form-input-field"
              @onChangeName="updateEnterpriseSize"
              :textLength="dropdownTextLength"
              :submitted="submitted"
            />
            <div class="add-evidence">
              <AddEvidence
                :hasEvidenceButton="true"
                :evidenceFormStatus="
                  getEvidenceFormStatus(anaCreditEvdIds.ENTERPRISE_SIZE)
                "
                @click.native="
                  toggleEvidenceForm(anaCreditEvdIds.ENTERPRISE_SIZE)
                "
                :btnLoading="
                  getBtnLoadingStatus(anaCreditEvdIds.ENTERPRISE_SIZE)
                "
                :isDisabled="isEvidenceBtnDisabled('enterpriseSize')"
              />
            </div>
          </div>
        </div>
      </div>
      <template>
        <ActionButton
          @SaveData="saveData"
          @saveAndNext="saveAndNext"
          @goBack="goBack"
          :isFormValid="isValidForm"
        >
        </ActionButton>
      </template>
      <template>
        <ConfirmationDialog
          :visible="confirmationDialogData.visible"
          :title="confirmationDialogData.title"
          :bodyText="confirmationDialogData.text"
          :cancelButtonText="confirmationDialogData.cancelButtonText"
          :confirmButtonText="confirmationDialogData.confirmButtonText"
          :closeDialog="closeDialog"
          :triggerFunction="confirmationDialogData.triggerFunction"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/DMP/ActionButton.vue'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import DatePicker from '@/components/DMP/DatePicker'
import SearchableSelect from '@/components/DMP/SearchableSelect'
import Snackbar from '@/components/Snackbar'
import {
  activeStepIndexName,
  ANA_C_IDENTIFIERS_NA,
  ANA_C_IDENTIFIERS_NOT_APP,
  anaCreditEvdIds,
  ENTERPRISE_NO_EMPL,
  ENTERPRISE_SIZE_ENUM,
  REVENUE
} from '@/constant/data.js'
import InputVue from '@/views/Admin/components/Input.vue'
import { plusIcon, xIcon } from '@progress/kendo-svg-icons'
import { SvgIcon } from '@progress/kendo-vue-common'
import { isEmpty, isEqual, uniqBy } from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'AnaCreditForm',
  components: {
    SearchableSelect,
    ActionButton,
    AddEvidence,
    InputVue,
    DatePicker,
    ConfirmationDialog,
    SvgIcon
  },
  props: {
    updateStepper: {
      type: Function,
      required: true
    },
    isAnaCreditApplicable: {
      type: Object,
      required: true
    },
    anacreditApplicabilityflag: {
      type: Object,
      required: true
    },
    entityAnaCreditApplicabilityflag: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sgaId: '',
      loading: false,
      dropdownTextLength: 80,
      registeredAddrCountryId: null,
      isAnaCredit: null,
      newAnaCDetails: {
        institutionalSector: null,
        legalForm: null,
        legalProceedStatus: null,
        noOfEmployees: null,
        balanceSheetTotal: null,
        annualTurnover: null,
        enterpriseSize: null,
        enterpriseSizeDate: null,
        legalProceedDate: null // Initiation Legal Proceedings
      },
      evdErrorMsg: {
        institutionalSector: null,
        legalForm: null,
        legalProceedStatus: null,
        noOfEmployees: null,
        balanceSheetTotal: null,
        annualTurnover: null,
        enterpriseSize: null,
        enterpriseSizeDate: null,
        legalProceedDate: null
      },
      dropdownLoader: {
        institutionalSector: false,
        legalForm: false,
        legalProceedStatus: false
      },
      validationMetaData: [
        /**
         * 3 type of formfields considered: text, date, dropdown(!text)
         * Evidence key and mapped field also considered according to formfields
         */
        {
          key: 'institutionalSector',
          isTextField: false,
          optionsArray: 'instSectorOpts',
          evdField: 'institutionalSectorEvd',
          evdKey: anaCreditEvdIds.INST_SECTOR
        },
        {
          key: 'balanceSheetTotal',
          isTextField: true,
          evdField: 'balanceSheetTotalEvd',
          evdKey: anaCreditEvdIds.BALANCE_SHEET,
          isDependentConditional: true
        },
        {
          key: 'annualTurnover',
          isTextField: true,
          evdField: 'annualTurnoverEvd',
          evdKey: anaCreditEvdIds.ANNUAL_TURNOVER,
          isDependentConditional: true
        },
        {
          key: 'noOfEmployees',
          isTextField: true,
          evdField: 'noOfEmployeesEvd',
          evdKey: anaCreditEvdIds.NO_OF_EMPL
        },
        {
          key: 'enterpriseSize',
          isTextField: true,
          evdField: 'enterpriseSizeEvd',
          evdKey: anaCreditEvdIds.ENTERPRISE_SIZE
        },
        {
          key: 'legalForm',
          isTextField: false,
          evdField: 'legalFormEvd',
          optionsArray: 'legalFormOpts',
          evdKey: anaCreditEvdIds.LEGAL_FORM
        },
        {
          key: 'legalProceedStatus',
          isTextField: false,
          evdField: 'legalProceedStatusEvd',
          optionsArray: 'legalProceedStatusOpts',
          evdKey: anaCreditEvdIds.LEGAL_PROCEED_STATUS
        },
        {
          key: 'enterpriseSizeDate',
          isTextField: true,
          evdField: 'enterpriseSizeDateEvd',
          evdKey: anaCreditEvdIds.ENTERPRISE_SIZE_DATE,
          isDateField: true
        },
        {
          key: 'legalProceedDate',
          isTextField: true,
          evdField: 'legalProceedDateEvd',
          evdKey: anaCreditEvdIds.LEGAL_PROCEED_DATE,
          isDateField: true
        }
      ],
      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },
      snackbarPayload: {
        // Set default payload
        message: null,
        type: 'is-warning',
        duration: 3000
      },
      submitted: false,
      isAutoPopulated: false,
      REVENUE,
      ENTERPRISE_NO_EMPL,
      ENTERPRISE_SIZE_ENUM,
      anaCreditEvdIds,
      activeStepIndexName,
      inputNameMapping: {},
      isMandatory: true,
      localEvidenceIdList: [],
      isLegalDate: true,
      xIcon,
      plusIcon,
      nationalIdentifiersPairs: [
        {
          typeValue: null,
          typeOption: [],
          typeError: null,
          idValue: null,
          evidenceError: null,
          isDropdownLoading: false,
          evidenceId: null,
          isMandatory: false
        }
      ],
      nationalIdentifierTypes: {
        0: null
      },
      nationalIdentifierIds: {
        0: null
      },
      identifierIdWatcher: false,
      identifierMinPair: 2,
      isEnterpriseSizeAvailable: false,
      availabeEnterpriseSize: [
        'Small enterprise',
        'Medium enterprise',
        'Large enterprise',
        'Micro enterprise'
      ],
      isEnterpriseSizeCalculatedAllowed: false,
      localEvidenceInitList: null,
      initEvidenceListWatcher: false,
      highestIdentifierRank: 0,
      ANA_C_IDENTIFIERS_NA,
      ANA_C_IDENTIFIERS_NOT_APP
    }
  },
  computed: {
    ...mapState('anaCredit', [
      'nationalITypeOpts',
      'instSectorOpts',
      'instSubSectOpts',
      'legalFormOpts',
      'legalProceedStatusOpts',
      'enterpriseSizeOpts',
      'anaCreditDetails',
      'bvdAnaCreditDetails'
    ]),
    ...mapState('dmp', ['registeredAddress']),
    ...mapState('evidence', ['evidenceIdList', 'storeEvidences']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails']),

    isNationalIdentifierTypeMandatory() {
      // Check if nationalIdentifierNo is not empty or falsy
      return !!this.newAnaCDetails.nationalIdentifierNo
    },
    enterpriseSizeCalculated() {
      const enterpriseSize = this.newAnaCDetails.enterpriseSize || null
      const isSync = this.isAutoPopulated
      const annualTurnover = this.newAnaCDetails.annualTurnover
        ? parseFloat(this.newAnaCDetails.annualTurnover)
        : null
      const balanceSheetTotal = this.newAnaCDetails.balanceSheetTotal
        ? parseFloat(this.newAnaCDetails.balanceSheetTotal)
        : null
      const numberOfEmployees =
        parseFloat(this.newAnaCDetails.noOfEmployees) || 0
      const maxAmount =
        annualTurnover === null && balanceSheetTotal === null
          ? null
          : Math.max(annualTurnover, balanceSheetTotal)
      const isAnnualTMax = maxAmount === annualTurnover
      if (isSync) {
        this.isAutoPopulated = false
        return enterpriseSize
      }
      if (numberOfEmployees <= 0) {
        return this.anaCreditDetails.enterpriseSize ===
          this.ENTERPRISE_SIZE_ENUM.NOT_AVAILABLE
          ? this.getEnterpriseSizeValue(this.ENTERPRISE_SIZE_ENUM.NOT_AVAILABLE)
          : null
      } else if (numberOfEmployees > 0 && maxAmount != null && maxAmount <= 0) {
        return this.getEnterpriseSizeValue(this.ENTERPRISE_SIZE_ENUM.MICRO)
      } else if (
        numberOfEmployees > 0 &&
        ((!isAnnualTMax && maxAmount >= 0) ||
          (isAnnualTMax && maxAmount !== null))
      ) {
        if (
          numberOfEmployees <= ENTERPRISE_NO_EMPL.MICRO &&
          maxAmount <= REVENUE.TWO_MILLION
        ) {
          return this.getEnterpriseSizeValue(this.ENTERPRISE_SIZE_ENUM.MICRO)
        } else if (
          numberOfEmployees <= ENTERPRISE_NO_EMPL.SMALL &&
          maxAmount <= REVENUE.TEN_MILLION
        ) {
          return this.getEnterpriseSizeValue(this.ENTERPRISE_SIZE_ENUM.SMALL)
        } else if (
          (numberOfEmployees <= ENTERPRISE_NO_EMPL.MEDIUM &&
            annualTurnover <= REVENUE.FIFTY_MILLION &&
            !(balanceSheetTotal > REVENUE.FOURTY_THREE_MILLION)) ||
          (numberOfEmployees <= ENTERPRISE_NO_EMPL.MEDIUM &&
            balanceSheetTotal <= REVENUE.FOURTY_THREE_MILLION &&
            !(annualTurnover > REVENUE.FIFTY_MILLION))
        ) {
          return this.getEnterpriseSizeValue(this.ENTERPRISE_SIZE_ENUM.MEDIUM)
        } else {
          return this.getEnterpriseSizeValue(this.ENTERPRISE_SIZE_ENUM.LARGE)
        }
      } else {
        return this.anaCreditDetails.enterpriseSize ===
          this.ENTERPRISE_SIZE_ENUM.NOT_AVAILABLE
          ? this.getEnterpriseSizeValue(this.ENTERPRISE_SIZE_ENUM.NOT_AVAILABLE)
          : null
      }
    },

    enterpriseSizeCalculatedObj() {
      const optObj = this.enterpriseSizeOpts.filter((opt) => {
        return opt.text === this.newAnaCDetails.enterpriseSize // string is compared
      })
      return optObj[0]
    },
    isValidForm() {
      // Field level validation check
      let isValidField = true
      const alphaNumSpace = /^[ A-Za-z0-9\-()&]*$/
      const digitsOnly = /^-?[0-9]+(\.[0-9]+)?$/

      let isFieldNull = false
      const isEnterpriseError = false
      for (const formField of this.validationMetaData) {
        // Field level validations
        if (
          formField.isTextField &&
          !this.isNullValue(this.newAnaCDetails[formField.key])
        ) {
          const fieldValue = this.newAnaCDetails[formField.key]
          switch (formField.key) {
            case 'balanceSheetTotal':
              isValidField = digitsOnly.test(fieldValue) && fieldValue >= 0
              break
            case 'noOfEmployees':
              isValidField = digitsOnly.test(fieldValue) && fieldValue > 0
              break
            case 'annualTurnover':
              isValidField = digitsOnly.test(fieldValue)
              break
          }
        }

        // Mandatory check
        if (this.isAnaCredit && this.isMandatory) {
          // AnaCredit Applicability all field is Mandatory
          let nonMandotoryField = [
            'noOfEmployees',
            'enterpriseSize',
            'legalProceedDate',
            'enterpriseSizeDate'
          ]

          nonMandotoryField = this.isLegalDate
            ? nonMandotoryField.filter((field) => field !== 'legalProceedDate')
            : nonMandotoryField
          nonMandotoryField = this.isEnterpriseSizeAvailable
            ? nonMandotoryField.filter(
                (field) => field !== 'enterpriseSizeDate'
              )
            : nonMandotoryField

          if (
            formField.isDependentConditional ||
            nonMandotoryField.includes(formField.key)
          ) {
            // Skipped because non mandatory
          } else if (formField.isTextField) {
            isFieldNull = this.isNullValue(this.newAnaCDetails[formField.key])
          } else {
            isFieldNull = this.isNullValue(
              this.newAnaCDetails[formField.key]?.value
            )
          }
        }

        if (isFieldNull || !isValidField) break
      }

      if (isValidField) {
        this.nationalIdentifiersPairs.every(
          ({ typeValue, idValue, isMandatory }) => {
            isValidField =
              this.isMandatory && isMandatory
                ? !this.isNullValue(typeValue?.value) &&
                  !this.isNullValue(idValue)
                : (this.isNullValue(typeValue?.value) &&
                    this.isNullValue(idValue)) ||
                  (!this.isNullValue(typeValue?.value) &&
                    !this.isNullValue(idValue))
            isValidField =
              isValidField && !this.isNullValue(idValue)
                ? alphaNumSpace.test(idValue) && idValue?.length <= 50
                : isValidField
            return isValidField
          }
        )
      }
      this.hasUnsavedChanges(true, isEnterpriseError)
      if (!isValidField) return isValidField // Any Field is not valid then form is inValid
      if (isFieldNull) return !isFieldNull // Field is null then form is inValid
      return true
    }
  },

  watch: {
    newAnaCDetails: {
      handler() {
        if (!isEmpty(this.newAnaCDetails)) {
          let fieldNotChanged = true
          const isInitData = Object.keys(this.anaCreditDetails).length != 0
          for (const formField of this.validationMetaData) {
            const formFieldKey = formField.key
            if (!isInitData) {
              // Check if init data is null and field value is changed
              fieldNotChanged = formField.isTextField
                ? this.isNullValue(this.newAnaCDetails[formFieldKey])
                : this.isNullValue(this.newAnaCDetails[formFieldKey]?.value)
            } else {
              // Check if new data is as init data
              fieldNotChanged =
                (formField.isTextField &&
                  formFieldKey == 'enterpriseSize' &&
                  this.anaCreditDetails[formFieldKey] ===
                    this.getEnterpriseSizeValue(
                      this.newAnaCDetails[formFieldKey],
                      false
                    )) ||
                (formField.isDateField &&
                  new Date(this.newAnaCDetails[formFieldKey]).toDateString() ===
                    new Date(
                      this.anaCreditDetails[formFieldKey]
                    ).toDateString()) ||
                (formField.isTextField &&
                  ((this.isNullValue(this.anaCreditDetails[formFieldKey]) &&
                    this.isNullValue(this.newAnaCDetails[formFieldKey])) ||
                    this.newAnaCDetails[formFieldKey] ===
                      this.anaCreditDetails[formFieldKey])) ||
                (!formField.isTextField &&
                  ((this.isNullValue(this.anaCreditDetails[formFieldKey]) &&
                    this.isNullValue(
                      this.newAnaCDetails[formFieldKey]?.value
                    )) ||
                    this.newAnaCDetails[formFieldKey]?.value ===
                      this.anaCreditDetails[formFieldKey]))
            }
            if (
              fieldNotChanged &&
              this.anaCreditDetails[`${formField.evdField}`]
            ) {
              this.updateEvidenceIdList(formField.evdKey)
              this.updateLocalEvidenceIdList(formField.evdKey)
              this.updateInputNameMapping(
                formField.evdKey,
                this.anaCreditDetails[`${formField.evdField}`]
              )
            }
          }
        }
      },
      deep: true
    },

    nationalIdentifierIds: {
      handler() {
        this.nationalIdentifiersPairs.forEach(
          ({ typeValue, idValue, identificationId, evidenceId }, index) => {
            // Mandatory Check
            this.nationalIdentifiersPairs[index].isMandatory =
              !typeValue?.text?.includes(this.ANA_C_IDENTIFIERS_NA.TEXT) &&
              !typeValue?.text?.includes(this.ANA_C_IDENTIFIERS_NOT_APP.TEXT)
                ? !(
                    this.isNullValue(typeValue?.value) &&
                    this.isNullValue(idValue)
                  ) || this.highestIdentifierRank > 0
                : false
            // Reset the evidence
            const { type, value } =
              this.anaCreditDetails?.identifierPairs?.find(
                (pair) => pair.identification_id === identificationId
              ) || {}
            const evdKey = `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${
              index + 1
            }`
            const fieldValueNotChanged =
              (identificationId &&
                value === idValue &&
                type === typeValue?.value) ||
              (this.isNullValue(idValue) && this.isNullValue(typeValue?.value))
            if (fieldValueNotChanged && evidenceId) {
              this.nationalIdentifiersPairs[index].evidenceError = ''
              this.updateEvidenceIdList(evdKey)
              this.updateLocalEvidenceIdList(evdKey)
              this.updateInputNameMapping(evdKey, evidenceId)
            }
          }
        )
      },
      deep: true
    },
    nationalIdentifierTypes: {
      deep: true,
      handler() {
        this.nationalIdentifiersPairs.forEach(
          ({ idValue, typeValue }, index) => {
            this.nationalIdentifiersPairs[index].isMandatory =
              !typeValue?.text?.includes(this.ANA_C_IDENTIFIERS_NA.TEXT) &&
              !typeValue?.text?.includes(this.ANA_C_IDENTIFIERS_NOT_APP.TEXT)
                ? !(
                    this.isNullValue(typeValue?.value) &&
                    this.isNullValue(idValue)
                  ) || this.highestIdentifierRank > 0
                : false
          }
        )
      }
    },
    'newAnaCDetails.noOfEmployees': {
      handler() {
        this.isEnterpriseSizeCalculatedAllowed = true
      },
      deep: true
    },
    'newAnaCDetails.annualTurnover': {
      handler() {
        this.isEnterpriseSizeCalculatedAllowed = true
      },
      deep: true
    },
    'newAnaCDetails.balanceSheetTotal': {
      handler() {
        this.isEnterpriseSizeCalculatedAllowed = true
      },
      deep: true
    },

    isEnterpriseSizeCalculatedAllowed() {
      if (this.isEnterpriseSizeCalculatedAllowed) {
        // string is getting stored
        this.newAnaCDetails.enterpriseSize = this.enterpriseSizeCalculated
        this.isEnterpriseSizeCalculatedAllowed = false
      }
    },

    localEvidenceIdList() {
      if (this.initEvidenceListWatcher) {
        this.localEvidenceInitList = this.localEvidenceIdList
        this.initEvidenceListWatcher = false
      }
    },

    anaCreditDetails: {
      handler(newVal) {
        this.syncAnaCreditDetails()
      },
      deep: true
    },

    bvdAnaCreditDetails: {
      handler() {
        this.syncBVDData()
      },
      deep: true
    }
  },

  async mounted() {
    try {
      this.isAnaCredit = this.getAnacreditValue()
      if (!this.isAnaCredit) {
        return
      }
      this.initEvidenceListWatcher = true
      this.resetEvidenceStore()
      this.loading = true
      const { sgaId } = this.getEntityDetails
      this.sgaId = sgaId
      Object.keys(this.dropdownLoader).forEach(
        (key) => (this.dropdownLoader[key] = true)
      )

      await this.getInstitutionalSectors()
      await this.getLegalForms()
      await this.getLegalProceedingsStatus()
      await this.getEnterpriseSize()
      await this.getAnaCreditDetails(this.sgaId)

      this.registeredAddrCountryId = this.anaCreditDetails?.registeredCountryId
      await this.getNationalIdentifierTypes(this.registeredAddrCountryId)
      this.syncAnaCreditDetails()
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions('anaCredit', [
      'getNationalIdentifierTypes',
      'getInstitutionalSectors',
      'getLegalForms',
      'getLegalProceedingsStatus',
      'getAnaCreditDetails',
      'getEnterpriseSize',
      'updateAnaCreditDetails'
    ]),
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore',
      'removeEvidence'
    ]),
    ...mapActions('assignedEntities', ['checkAndUpdateTodoStatus']),
    ...mapMutations('evidence', {
      updateStoreEvidencesMap: 'SET_STORE_EVIDENCES'
    }),
    getAnacreditValue() {
      let anacreditFlagValue = false
      if ([null, undefined].includes(this.anacreditApplicabilityflag.value)) {
        if (
          [null, undefined].includes(
            this.entityAnaCreditApplicabilityflag.value
          )
        ) {
          anacreditFlagValue = false
        } else if (this.entityAnaCreditApplicabilityflag.value === true) {
          anacreditFlagValue = true
        } else {
          anacreditFlagValue = false
        }
      } else if (this.anacreditApplicabilityflag.value === true) {
        anacreditFlagValue = true
      } else {
        anacreditFlagValue = false
      }
      return anacreditFlagValue
    },
    syncAnaCreditDetails() {
      if (this.anaCreditDetails) {
        this.isAnaCredit = this.getAnacreditValue()
        // Minimum Identifiers Pairs: Taken static as requirement
        const niOptsWONA = this.nationalITypeOpts.filter(
          (opt) =>
            opt.rank !== this.ANA_C_IDENTIFIERS_NA.RANK &&
            opt.rank !== this.ANA_C_IDENTIFIERS_NOT_APP.RANK
        )
        this.highestIdentifierRank =
          niOptsWONA && niOptsWONA.length > 0
            ? Math.max(...niOptsWONA.map((opt) => opt.rank))
            : 0
        this.identifierMinPair = this.highestIdentifierRank > 1 ? 2 : 1

        for (const formField of this.validationMetaData) {
          // Init form fields
          if (formField.isTextField && !formField.isDateField) {
            if (formField.key === 'enterpriseSize') {
              // store the text value of enterprise size
              this.newAnaCDetails[formField.key] =
                this.enterpriseSizeOpts.filter((opt) => {
                  return opt.value === this.anaCreditDetails[formField.key]
                })[0]?.text
              this.isAutoPopulated = true
            } else {
              this.newAnaCDetails[formField.key] =
                this.anaCreditDetails[formField.key]
            }
          } else if (formField.isDateField) {
            this.initializeDateProperty(formField.key)
          } else if (!formField.isTextField) {
            this.updateProperty(formField.key, this[formField?.optionsArray])
          }

          // Init Evidence fields
          this.newAnaCDetails[formField.evdField] =
            this.anaCreditDetails[formField.evdField]

          // Load Evidence data
          if (
            !this.isNullValue(this.anaCreditDetails[`${formField.evdField}`])
          ) {
            this.updateEvidenceIdList(formField.evdKey)
            this.updateLocalEvidenceIdList(formField.evdKey)
            this.updateInputNameMapping(
              formField.evdKey,
              this.anaCreditDetails[`${formField.evdField}`]
            )
          }
        }

        this.nationalIdentifiersPairs = []
        this.identifierIdWatcher = true

        if (this.anaCreditDetails?.identifierPairs) {
          this.anaCreditDetails?.identifierPairs.forEach(
            (
              {
                type,
                value,
                identification_id,
                evidence_id,
                typeCode,
                typeLabel,
                typeRank
              },
              index
            ) => {
              let taxIdTypeValue = this.nationalITypeOpts.find(
                (taxType) => taxType.value == type
              )

              if (!taxIdTypeValue) {
                taxIdTypeValue = {
                  value: type,
                  text: `${
                    typeRank !== this.ANA_C_IDENTIFIERS_NA.RANK &&
                    typeRank !== this.ANA_C_IDENTIFIERS_NOT_APP.RANK
                      ? typeRank + ' - '
                      : ''
                  }${typeCode} - ${typeLabel}`
                }
              }
              this.nationalIdentifiersPairs.push({
                identificationId: identification_id,
                typeValue: taxIdTypeValue,
                typeOption: this.nationalITypeOpts,
                idValue: value,
                evidenceError: null,
                evidenceId: evidence_id,
                isDropdownLoading: false,
                isMandatory: this.highestIdentifierRank > 0
              })
            }
          )
        }
        this.syncIdentifierData()
        this.identifierEvdInit()

        // If Rank is more than 2 or 2 then add 2 pairs
        if (
          (this.highestIdentifierRank >= 1 ||
            this.highestIdentifierRank === 0) &&
          this.nationalIdentifiersPairs.length < this.identifierMinPair
        ) {
          this.addMinIdentifierPairs(this.identifierMinPair)
        }

        this.$nextTick(() => {
          this.identifierIdWatcher = false
        })
      }
    },
    goBack() {
      // Check if there are unsaved changes
      if (this.hasUnsavedChanges()) {
        this.confirmationDialogData.visible = true
        this.confirmationDialogData.title = 'Discard changes?'
        this.confirmationDialogData.text =
          'Would you like to discard the changes & go back <br>to Identifiers screen?'
        this.confirmationDialogData.triggerFunction = this.goToBackStep
      } else {
        this.goToBackStep()
      }
    },
    goToBackStep() {
      this.updateStepper(this.activeStepIndexName.identifiers)
    },
    hasUnsavedChanges(isSubmission = false, isEnterpriseError = false) {
      let fieldNotChanged = true
      const isInitData = Object.keys(this.anaCreditDetails).length != 0
      for (const formField of this.validationMetaData) {
        const formFieldKey = formField.key
        if (!isInitData) {
          // Check if init data is null and field value is changed
          fieldNotChanged = formField.isTextField
            ? this.isNullValue(this.newAnaCDetails[formFieldKey])
            : this.isNullValue(this.newAnaCDetails[formFieldKey]?.value)
        } else {
          // Check if new data is as init data
          fieldNotChanged =
            (formField.isTextField &&
              formFieldKey == 'enterpriseSize' &&
              this.anaCreditDetails[formFieldKey] ===
                this.getEnterpriseSizeValue(
                  this.newAnaCDetails[formFieldKey],
                  false
                )) ||
            (formField.isDateField &&
              new Date(this.newAnaCDetails[formFieldKey]).toDateString() ===
                new Date(this.anaCreditDetails[formFieldKey]).toDateString()) ||
            (formField.isTextField &&
              ((this.isNullValue(this.anaCreditDetails[formFieldKey]) &&
                this.isNullValue(this.newAnaCDetails[formFieldKey])) ||
                this.newAnaCDetails[formFieldKey] ===
                  this.anaCreditDetails[formFieldKey])) ||
            (!formField.isTextField &&
              ((this.isNullValue(this.anaCreditDetails[formFieldKey]) &&
                this.isNullValue(this.newAnaCDetails[formFieldKey]?.value)) ||
                this.newAnaCDetails[formFieldKey]?.value ===
                  this.anaCreditDetails[formFieldKey]))
        }
        if (
          this.submitted &&
          ((formField?.evdKey &&
            !fieldNotChanged &&
            !this.getEvidenceFormStatus(formField.evdKey)) ||
            (this.localEvidenceIdList.find(
              (inputName) => inputName === formField.evdKey
            ) &&
              !this.evidenceIdList.includes(formField.evdKey)))
        ) {
          this.evdErrorMsg[
            formField.key
          ] = `Please upload evidence for ${formField.evdKey}`
        } else {
          this.evdErrorMsg[formField.key] = null
          this.evdErrorMsg[formField.key] =
            formField.key === 'enterpriseSize' &&
            isEnterpriseError &&
            this.submitted
              ? 'Either Balance Sheet Total or Annual Turnover as Per Year is required'
              : null
        }
        if (fieldNotChanged) {
          fieldNotChanged =
            this.localEvidenceIdList.includes(formField.evdKey) ===
            this.evidenceIdList.includes(formField.evdKey)
        }
        if (isSubmission) continue
        if (!fieldNotChanged) break
      }

      // Nationa Identifiers & Type pair changes
      if (fieldNotChanged || isSubmission) {
        for (
          let index = 0;
          index < this.nationalIdentifiersPairs.length;
          index++
        ) {
          const { typeValue, idValue, identificationId } =
            this.nationalIdentifiersPairs[index]
          const { type, value } =
            this.anaCreditDetails?.identifierPairs?.find(
              (pair) => pair.identification_id === identificationId
            ) || {}
          fieldNotChanged = identificationId
            ? value === idValue && type === typeValue?.value
            : this.isNullValue(idValue) && this.isNullValue(typeValue?.value)
          const evdKey = `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${
            index + 1
          }`
          if (
            this.submitted &&
            !fieldNotChanged &&
            !this.getEvidenceFormStatus(evdKey)
          ) {
            this.nationalIdentifiersPairs[
              index
            ].evidenceError = `Please upload evidence for ${evdKey}`
          } else {
            this.nationalIdentifiersPairs[index].evidenceError = null
          }

          if (isSubmission) continue
          if (!fieldNotChanged) break
        }
      }

      return !fieldNotChanged
    },

    resetFormData() {
      // Reset form will be implemented
    },

    async saveAndNext() {
      const saveDataStatus = await this.saveData(true)
      if (saveDataStatus) this.updateStepper(this.activeStepIndexName.aedetails)
    },

    async saveData(isFinalSubmit = false) {
      let isACDetailsUpdated = false
      let processESizeWithNull = false
      this.submitted = true
      this.isMandatory = isFinalSubmit
      let haveNoEvdError = false
      const isValidFormData = this.isValidForm

      haveNoEvdError =
        Object.values(this.evdErrorMsg).every((msg) => msg === null) &&
        this.nationalIdentifiersPairs.every(
          (pair) => pair.evidenceError === null
        )

      const isFormValidated = isValidFormData && haveNoEvdError
      const processForm =
        isFormValidated && this.identifierPairValidations(isFormValidated)

      if (processForm) {
        const proceedFurther = async () => {
          processESizeWithNull =
            this.enterpriseSizeCalculatedObj === null ||
            this.enterpriseSizeCalculatedObj === undefined
          const updatedAnaCreditFields = {
            identifierPairs: []
          }

          for (const formField of this.validationMetaData) {
            if (formField.key === 'enterpriseSize') {
              updatedAnaCreditFields[formField.key] = processESizeWithNull
                ? this.ENTERPRISE_SIZE_ENUM.NOT_AVAILABLE
                : this.enterpriseSizeCalculatedObj?.value
            } else if (
              formField.key === 'enterpriseSizeDate' ||
              formField.key === 'legalProceedDate'
            ) {
              if (!this.isNullValue(this.newAnaCDetails[formField.key])) {
                const date = new Date(this.newAnaCDetails[formField.key])
                const adjustedDate = new Date(date)
                adjustedDate.setMinutes(
                  date.getMinutes() - date.getTimezoneOffset()
                )
                updatedAnaCreditFields[formField.key] =
                  adjustedDate.toISOString()
              } else {
                updatedAnaCreditFields[formField.key] =
                  this.newAnaCDetails[formField.key]
              }
            } else {
              updatedAnaCreditFields[formField.key] = formField.isTextField
                ? this.isNullValue(this.newAnaCDetails[formField.key])
                  ? null
                  : this.newAnaCDetails[formField.key]
                : this.isNullValue(this.newAnaCDetails[formField.key]?.value)
                ? null
                : this.newAnaCDetails[formField.key]?.value
            }
          }
          try {
            this.loading = true
            const evidenceIdMappingResponse = await this.uploadEvidence()
            this.updateEvidenceIdMapping(
              evidenceIdMappingResponse,
              updatedAnaCreditFields
            )

            // Check entity status and update it to In Progress, if it's Todo
            await this.checkAndUpdateTodoStatus()

            const updateAnaCreditPayload = {
              sgaId: this.sgaId,
              updatedAnaCreditFields
            }
            const anaCreditUpdateRes = await this.updateAnaCreditDetails(
              updateAnaCreditPayload
            )
            if (anaCreditUpdateRes === 200) {
              isACDetailsUpdated = true
              this.snackbarPayload.message =
                'AnaCredit records updated successfully'
              this.snackbarPayload.type = 'is-success'
              this.syncAnaCreditDetails()
            } else {
              throw new Error()
            }
            this.loading = false
            this.submitted = false
            this.isMandatory = true
          } catch (error) {
            this.snackbarPayload.message = 'AnaCredit records not updated'
            this.snackbarPayload.type = 'is-danger'
            this.loading = false
          }
          // Snackbar for submission status failure/success
          Snackbar(this.snackbarPayload)
        }

        // Show confirmation popup if dependent fields not updated and dropdown value not selected
        if (
          this.enterpriseSizeCalculated === null &&
          this.isNullValue(this.enterpriseSizeCalculatedObj?.value)
        ) {
          this.confirmationDialogData.visible = true
          this.confirmationDialogData.title = 'Enterprise size Not Available'
          this.confirmationDialogData.text =
            'Enterprise size is "Not Available", would you <br/>like to continue?'
          this.confirmationDialogData.triggerFunction = proceedFurther
        } else {
          await proceedFurther()
        }
      }
      return processForm && isACDetailsUpdated
    },
    updateEvidenceIdMapping(
      evidenceIdMappingResponse,
      payloadData,
      isForIdentifiers = false
    ) {
      const evidenceIdMapping = {}
      if (
        evidenceIdMappingResponse &&
        Array.isArray(evidenceIdMappingResponse)
      ) {
        evidenceIdMappingResponse.forEach((item) => {
          if (item.evidenceIds && item.evidenceIds.length > 0) {
            const evidenceId = item ? Number(item.evidenceIds[0].id) : null
            evidenceIdMapping[item.attributeName] = evidenceId
          }
        })
      }
      if (!isForIdentifiers) {
        for (const formField of this.validationMetaData) {
          payloadData[`${formField.evdField}`] =
            this.isEvidencePresentInInputMapping(formField.evdKey)
              ? evidenceIdMapping[formField.evdKey] ||
                this.newAnaCDetails[formField.evdField]
              : null
        }
      }

      this.nationalIdentifiersPairs.forEach(
        ({ typeValue, idValue, evidenceId }, index) => {
          const evidenceKey = `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${
            index + 1
          }`

          if (isForIdentifiers) {
            this.nationalIdentifiersPairs[index].evidenceId =
              this.isEvidencePresentInInputMapping(evidenceKey)
                ? evidenceIdMapping[evidenceKey] || evidenceId
                : null
          }

          if (
            !isForIdentifiers &&
            ![typeValue, idValue, evidenceId].every((item) =>
              this.isNullValue(item)
            )
          ) {
            payloadData.identifierPairs.push({
              identifierType: typeValue?.value || null,
              identifierId: idValue,
              evidenceId: this.isEvidencePresentInInputMapping(evidenceKey)
                ? evidenceIdMapping[evidenceKey] || evidenceId
                : null
            })
          }
        }
      )
      return payloadData
    },
    getEnterpriseSizeValue(conditionField, isConditionalFieldId = true) {
      if (this.enterpriseSizeOpts && this.enterpriseSizeOpts.length > 0) {
        const currentSize = this.enterpriseSizeOpts.find((opt) =>
          isConditionalFieldId
            ? opt.value === conditionField
            : opt.text === conditionField
        )
        return currentSize
          ? isConditionalFieldId
            ? currentSize.text
            : currentSize.value
          : null
      }
    },
    onChangeLegalDate(legalDate) {
      this.newAnaCDetails.legalProceedDate = legalDate
      if (
        !isEqual(
          new Date(this.newAnaCDetails.legalProceedDate).toDateString(),
          new Date(this.anaCreditDetails?.legalProceedDate).toDateString()
        )
      ) {
        this.removeEvidence(this.anaCreditEvdIds.LEGAL_PROCEED_DATE)
      }
    },
    onChangeEnterpriseDate(enterpriseSizeDate) {
      this.newAnaCDetails.enterpriseSizeDate = enterpriseSizeDate
      if (
        !isEqual(
          new Date(this.newAnaCDetails.enterpriseSizeDate).toDateString(),
          new Date(this.anaCreditDetails?.enterpriseSizeDate).toDateString()
        )
      ) {
        this.removeEvidence(this.anaCreditEvdIds.ENTERPRISE_SIZE_DATE)
      }
    },
    updateNationalIdentifierType(value, index) {
      this.$set(this.nationalIdentifiersPairs[index], 'typeValue', value)
      this.$set(this.nationalIdentifierTypes, index, value)

      if (!this.identifierIdWatcher) {
        this.updateNationalIdentifierID(null, index)
      }

      this.updateDropdownOptions()
    },
    updateNationalIdentifierID(value, index) {
      this.$set(this.nationalIdentifiersPairs[index], 'idValue', value)
      this.$set(this.nationalIdentifierIds, index, value)
      const originValue =
        this.anaCreditDetails?.identifierPairs?.find(
          (pair) =>
            pair.identification_id ===
            this.nationalIdentifiersPairs[index].identificationId
        ) || null
      if (
        !isEqual(
          originValue?.value,
          this.nationalIdentifiersPairs[index].idValue
        )
      ) {
        this.removeEvidence(
          `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index + 1}`
        )
      }
    },
    async updateAnaCreditInstitutionalSector(institutionalSector) {
      this.newAnaCDetails.institutionalSector = institutionalSector
      const nullCheckNotEqual =
        this.newAnaCDetails.institutionalSector === null
          ? !isEqual(
              this.newAnaCDetails.institutionalSector,
              this.anaCreditDetails?.institutionalSector
            )
          : !isEqual(
              this.newAnaCDetails.institutionalSector?.value.toString(),
              this.anaCreditDetails?.institutionalSector?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newAnaCDetails.institutionalSector === 'object' &&
        this.newAnaCDetails.institutionalSector !== null &&
        this.newAnaCDetails.institutionalSector !== undefined &&
        !isEqual(
          this.newAnaCDetails.institutionalSector?.value.toString(),
          this.anaCreditDetails?.institutionalSector?.toString()
        )
      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(this.anaCreditEvdIds.INST_SECTOR)
      }
    },
    updateLegalForm(legalForm) {
      this.newAnaCDetails.legalForm = legalForm
      const nullCheckNotEqual =
        this.newAnaCDetails.legalForm === null
          ? !isEqual(
              this.newAnaCDetails.legalForm,
              this.anaCreditDetails?.legalForm
            )
          : !isEqual(
              this.newAnaCDetails.legalForm?.value.toString(),
              this.anaCreditDetails?.legalForm?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newAnaCDetails.legalForm === 'object' &&
        this.newAnaCDetails.legalForm !== null &&
        this.newAnaCDetails.legalForm !== undefined &&
        !isEqual(
          this.newAnaCDetails.legalForm?.value.toString(),
          this.anaCreditDetails?.legalForm?.toString()
        )
      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(this.anaCreditEvdIds.LEGAL_FORM)
      }
    },
    updateStatusOfLegalProceedings(legalProceedStatus) {
      this.newAnaCDetails.legalProceedStatus = legalProceedStatus

      const NO_LEGAL_ACTIONS_CODE = 'LG002'
      if (
        this.newAnaCDetails.legalProceedStatus?.text.split('-')[0].trim() ===
        NO_LEGAL_ACTIONS_CODE
      ) {
        this.isLegalDate = false
      } else {
        this.isLegalDate = true
      }

      const nullCheckNotEqual =
        this.newAnaCDetails.legalProceedStatus === null
          ? !isEqual(
              this.newAnaCDetails.legalProceedStatus,
              this.anaCreditDetails?.legalProceedStatus
            )
          : !isEqual(
              this.newAnaCDetails.legalProceedStatus?.value.toString(),
              this.anaCreditDetails?.legalProceedStatus?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newAnaCDetails.legalProceedStatus === 'object' &&
        this.newAnaCDetails.legalProceedStatus !== null &&
        this.newAnaCDetails.legalProceedStatus !== undefined &&
        !isEqual(
          this.newAnaCDetails.legalProceedStatus?.value.toString(),
          this.anaCreditDetails?.legalProceedStatus?.toString()
        )

      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(this.anaCreditEvdIds.LEGAL_PROCEED_STATUS)
      }
    },
    updateNumberOfEmployees(noOfEmployees) {
      this.newAnaCDetails.noOfEmployees = noOfEmployees
      if (
        !isEqual(
          this.newAnaCDetails.noOfEmployees,
          this.anaCreditDetails?.noOfEmployees
        )
      ) {
        this.removeEvidence(this.anaCreditEvdIds.NO_OF_EMPL)
      }
    },
    updateBalanceSheetTotal(balanceSheetTotal) {
      this.newAnaCDetails.balanceSheetTotal = balanceSheetTotal
      if (
        !isEqual(
          this.newAnaCDetails.balanceSheetTotal,
          this.anaCreditDetails?.balanceSheetTotal
        )
      ) {
        this.removeEvidence(this.anaCreditEvdIds.BALANCE_SHEET)
      }
    },
    updateAnnualTurnoverPerYear(annualTurnover) {
      this.newAnaCDetails.annualTurnover = annualTurnover
      if (
        !isEqual(
          this.newAnaCDetails.annualTurnover,
          this.anaCreditDetails?.annualTurnover
        )
      ) {
        this.removeEvidence(this.anaCreditEvdIds.ANNUAL_TURNOVER)
      }
    },
    updateEnterpriseSize(enterpriseSize) {
      this.newAnaCDetails.enterpriseSize = enterpriseSize // object is assigned here
      if (
        this.availabeEnterpriseSize.includes(
          this.newAnaCDetails?.enterpriseSize?.text
        )
      ) {
        this.isEnterpriseSizeAvailable = true
      } else {
        this.isEnterpriseSizeAvailable = false
      }

      const nullCheckNotEqual =
        this.newAnaCDetails.enterpriseSize === null
          ? !isEqual(
              this.newAnaCDetails.enterpriseSize,
              this.anaCreditDetails?.enterpriseSize
            )
          : !isEqual(
              this.newAnaCDetails.enterpriseSize?.value.toString(),
              this.anaCreditDetails?.enterpriseSize?.toString()
            )

      const valueCheckNotEqual =
        typeof this.newAnaCDetails.enterpriseSize === 'object' &&
        this.newAnaCDetails.enterpriseSize !== null &&
        this.newAnaCDetails.enterpriseSize !== undefined &&
        !isEqual(
          this.newAnaCDetails.enterpriseSize?.value.toString(),
          this.anaCreditDetails?.enterpriseSize?.toString()
        )
      if (nullCheckNotEqual || valueCheckNotEqual) {
        this.removeEvidence(this.anaCreditEvdIds.ENTERPRISE_SIZE)
      }

      this.newAnaCDetails.enterpriseSize = enterpriseSize?.text // string assigned here
    },
    closeDialog() {
      this.confirmationDialogData = {
        ...this.confirmationDialogData,
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    },
    updateProperty(key, optionsArray) {
      if (this.anaCreditDetails[key]) {
        const value = this.anaCreditDetails[key]
        const option = optionsArray?.find((option) => option.value === value)
        this.newAnaCDetails[key] = option
      }
      this.dropdownLoader[key] = false
    },
    initializeDateProperty(key) {
      if (this.anaCreditDetails[key]) {
        this.newAnaCDetails[key] = new Date(this.anaCreditDetails[key])
      }
    },
    async toggleEvidenceForm(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)

      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },
    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },
    updateInputNameMapping(inputName, evidenceId) {
      this.$set(this.inputNameMapping, inputName, evidenceId)
    },
    isEvidenceBtnDisabled(inputName, subField = null) {
      if (subField) {
        // Conditions for National Identifier Type & ID Pairs
        const currentIndex = subField.index
        const currentSubFieldEvdKey = `${
          this.anaCreditEvdIds.NATIONAL_IDENTIFIERS
        } ${currentIndex + 1}`
        const currentSubField = this.nationalIdentifiersPairs[currentIndex]
        const fetchedValue = this.anaCreditDetails?.identifierPairs?.find(
          (pair) => pair.identification_id === currentSubField.identificationId
        )
        if (this.getEvidenceFormStatus(currentSubFieldEvdKey)) {
          // Return false for View Evidence
          return false
        } else if (
          fetchedValue &&
          !this.isNullValue(currentSubField?.identificationId)
        ) {
          return (
            (this.isNullValue(fetchedValue?.type)
              ? this.isNullValue(currentSubField.typeValue?.value)
              : fetchedValue?.type === currentSubField.typeValue?.value) &&
            (this.isNullValue(fetchedValue?.value)
              ? this.isNullValue(currentSubField?.idValue)
              : fetchedValue?.value === currentSubField?.idValue)
          )
        } else {
          return (
            this.isNullValue(currentSubField?.idValue) &&
            this.isNullValue(currentSubField.typeValue?.value)
          )
        }
      }

      const currentField = this.validationMetaData.find(
        (field) => field.key === inputName
      )
      if (this.getEvidenceFormStatus(currentField.evdKey)) {
        // view evidence always enabled
        return false
      }
      // If init data have evidence then check field validations
      if (this.isNullValue(this.anaCreditDetails[`${currentField.evdField}`])) {
        return (
          (currentField.key === 'enterpriseSize' &&
            this.anaCreditDetails[currentField.key] ===
              this.getEnterpriseSizeValue(
                this.newAnaCDetails[currentField.key],
                false
              )) ||
          (currentField.isDateField &&
            new Date(this.newAnaCDetails[currentField.key]).toDateString() ===
              new Date(
                this.anaCreditDetails[currentField.key]
              ).toDateString()) ||
          (currentField.isTextField &&
            (this.isNullValue(this.anaCreditDetails[currentField.key])
              ? this.isNullValue(this.newAnaCDetails[currentField.key])
              : this.anaCreditDetails[currentField.key] ===
                this.newAnaCDetails[currentField.key])) ||
          (!currentField.isTextField &&
          this.isNullValue(this.anaCreditDetails[currentField.key])
            ? this.isNullValue(this.newAnaCDetails[currentField.key]?.value)
            : this.anaCreditDetails[currentField.key] ===
              this.newAnaCDetails[currentField.key]?.value)
        )
      } else {
        return false
      }
    },
    isNullValue(value) {
      return value === null || value === '' || value === undefined
    },
    clearEvidenceMsg() {
      for (const msgField of Object.keys(this.evdErrorMsg)) {
        this.evdErrorMsg[msgField] = null
      }
    },
    isEvidencePresentInInputMapping(inputName) {
      return this.evidenceIdList.includes(inputName)
    },
    updateLocalEvidenceIdList(inputName) {
      const evidenceIdSet = new Set([...this.localEvidenceIdList, inputName])
      this.localEvidenceIdList = [...evidenceIdSet]
    },
    updateDropdownOptions() {
      const selectedValues = this.nationalIdentifiersPairs.map(
        (item) => item.typeValue?.value
      )

      this.nationalIdentifiersPairs.forEach((item, index) => {
        const availableOptions = this.nationalITypeOpts.filter(
          (option) =>
            !selectedValues.includes(option.value) ||
            option.value === item.typeValue?.value
        )

        // Update options for the current item
        this.$set(
          this.nationalIdentifiersPairs[index],
          'typeOption',
          availableOptions
        )
      })
    },
    addInputPair(isSync = false) {
      // Tax ID pair can be added only as Tax ID Types
      const selectedValues = this.nationalIdentifiersPairs.filter((item) => {
        return this.nationalITypeOpts.find(
          (option) => option.value === item.typeValue?.value
        )
      })
      const availableOptions = this.nationalITypeOpts.filter(
        (option) =>
          !selectedValues.includes(option.value) ||
          ![
            this.ANA_C_IDENTIFIERS_NA.TEXT,
            this.ANA_C_IDENTIFIERS_NOT_APP.TEXT
          ].includes(option.text)
      )
      const addedPairs = this.nationalIdentifiersPairs.filter((pair) =>
        this.isNullValue(pair.identificationId)
      )

      //
      if (
        !isSync &&
        (this.highestIdentifierRank > 0
          ? selectedValues.length === availableOptions.length ||
            addedPairs.length >= availableOptions.length ||
            this.highestIdentifierRank === this.nationalIdentifiersPairs.length
          : this.nationalIdentifiersPairs.length === 1)
      ) {
        this.snackbarPayload.message =
          'Reached the maximum National Identifiers Type limits'
        Snackbar(this.snackbarPayload)
        return
      }
      this.nationalIdentifiersPairs.push({
        typeValue: null,
        typeOption: [],
        typeError: null,
        idValue: null,
        idError: null,
        evidenceId: null,
        isMandatory: this.highestIdentifierRank > 0
      })
      this.updateDropdownOptions()
    },
    async removeInputPair(index) {
      this.identifierIdWatcher = true
      this.loading = true
      await this.uploadIdentifierEvidence()
      const tempStoreEvidences = new Map(this.storeEvidences)
      const alreadyPresentEvidenceName = this.localEvidenceInitList
      const nationalIndetifierLen = this.nationalIdentifiersPairs.length

      this.removeEvd(index)
      this.nationalIdentifiersPairs.splice(index, 1)
      if (
        this.highestIdentifierRank >= 1 || this.highestIdentifierRank === 0
          ? this.nationalIdentifiersPairs.length <= 1
          : false
      ) {
        this.addInputPair()
      }
      this.syncIdentifierData()

      this.$nextTick(() => {
        this.identifierEvdInit()
        this.reAddEvidence(
          tempStoreEvidences,
          index,
          alreadyPresentEvidenceName,
          nationalIndetifierLen
        )
        this.hasUnsavedChanges()

        this.identifierIdWatcher = false
        this.loading = false
      })
      this.updateDropdownOptions()
    },
    reAddEvidence(
      tempStoreEvidences,
      deletedIndex,
      alreadyPresentEvidenceName,
      nationalIndetifierLen
    ) {
      // return if there are no evidence stored in local
      if (tempStoreEvidences.size === 0) {
        return
      }

      for (let index = 0; index < nationalIndetifierLen; index++) {
        const evidence = tempStoreEvidences.get(
          `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index + 1}`
        )
        // skip if the evidence is from backend present && If evidence is reattach then reAdd
        if (
          alreadyPresentEvidenceName?.includes(
            `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index + 1}`
          ) &&
          !tempStoreEvidences.get(
            `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index + 1}`
          )
        ) {
          continue
        }

        if (index < deletedIndex) {
          const newEvidence = {
            ...evidence,
            evidenceId: `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${
              index + 1
            }`
          }
          this.updateStoreEvidencesMap(newEvidence)
        } else if (index > deletedIndex && evidence) {
          const newEvidence = {
            ...evidence,
            evidenceId: `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index}`
          }
          this.updateStoreEvidencesMap(newEvidence)
        }
      }
    },
    evdMethodsForSubField(index) {
      const evdKey = `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index + 1}`
      return {
        toggleEvidence: () => {
          this.toggleEvidenceForm(evdKey)
        },
        evidenceFormStatus: () => {
          return this.getEvidenceFormStatus(evdKey)
        },
        btnLoadingStatus: () => {
          return this.getBtnLoadingStatus(evdKey)
        }
      }
    },
    identifierEvdInit() {
      // Re-initialize the evidence as updated index
      this.nationalIdentifiersPairs.forEach(({ evidenceId }, index) => {
        if (!this.isNullValue(evidenceId)) {
          const currentSubFieldEvdKey = `${
            this.anaCreditEvdIds.NATIONAL_IDENTIFIERS
          } ${index + 1}`
          this.updateEvidenceIdList(currentSubFieldEvdKey)
          this.updateLocalEvidenceIdList(currentSubFieldEvdKey)
          this.updateInputNameMapping(currentSubFieldEvdKey, evidenceId)
        }
      })
    },
    syncIdentifierData() {
      this.nationalIdentifierTypes = {}
      this.nationalIdentifierIds = {}
      this.nationalIdentifiersPairs.forEach(({ typeValue, idValue }, index) => {
        this.$set(this.nationalIdentifierTypes, index, typeValue)
        this.$set(this.nationalIdentifierIds, index, idValue)
      })
    },
    removeEvd() {
      // Reset All Evidences
      this.nationalIdentifiersPairs.forEach(({}, index) => {
        // Reset Store
        this.removeEvidence(
          `${this.anaCreditEvdIds.NATIONAL_IDENTIFIERS} ${index + 1}`
        )

        // Reset Local Evidence List
        const tmpEvdList = this.localEvidenceIdList.filter(
          (id) => !id.includes(this.anaCreditEvdIds.NATIONAL_IDENTIFIERS)
        )
        this.localEvidenceIdList = tmpEvdList

        // Reset Local `inputNameMapping`
        const tmpEvdInputMapping = {}
        tmpEvdList.map((item) => {
          tmpEvdInputMapping[item] = this.inputNameMapping[item]
        })
        this.inputNameMapping = tmpEvdInputMapping
      })
    },
    addMinIdentifierPairs(minPair) {
      do {
        this.addInputPair(true)
      } while (this.nationalIdentifiersPairs.length < minPair)
    },
    async uploadIdentifierEvidence() {
      const evidenceIdMappingResponse = await this.uploadEvidence()
      this.updateEvidenceIdMapping(evidenceIdMappingResponse, null, true)
    },
    identifierPairValidations(isFormValid) {
      // Unique Identifiers Type
      const uniqueIdentiferType = uniqBy(
        this.nationalIdentifiersPairs.map((item) => item.typeValue),
        'value'
      )
      const limitValid =
        this.highestIdentifierRank === 0 ||
        this.nationalIdentifiersPairs.length <= this.highestIdentifierRank
      const uniqValid =
        uniqueIdentiferType.length === this.nationalIdentifiersPairs.length
      const isValidPairs = limitValid && uniqValid

      if (!isValidPairs && isFormValid) {
        this.snackbarPayload.message = !uniqValid
          ? 'Duplicate National Identifier Types Are Not Allowed'
          : 'Exceeding Maximum National Identifiers Type Limit'
        this.snackbarPayload.type = 'is-danger'
        Snackbar(this.snackbarPayload)
      }

      // ==== Country Validation ====
      // Check the selected types belongs to current country options
      let countryNotBelongCount = 0
      const availableOptions = this.nationalITypeOpts?.map((opt) => opt.value)
      uniqueIdentiferType.forEach((opt) => {
        if (opt && !availableOptions.includes(opt?.value)) {
          countryNotBelongCount++
        }
      })

      const countryValidation = countryNotBelongCount === 0
      if (!countryValidation) {
        Snackbar({
          message: `Please select valid national identifier type as per registered country`,
          type: 'is-warning',
          indefinite: true
        })
      }
      // ==XX Country Validation XX==

      return isValidPairs && countryValidation
    },

    syncBVDData() {
      // Trigger Enterprise Size Calculation Watcher
      this.isAutoPopulated = false
      this.identifierIdWatcher = true
      for (const key in this.bvdAnaCreditDetails) {
        if (
          key === 'identifier' &&
          this.bvdAnaCreditDetails[key] &&
          this.bvdAnaCreditDetails[key]?.length > 0
        ) {
          this.nationalIdentifiersPairs = this.nationalIdentifiersPairs.filter(
            ({ idValue, typeValue }) =>
              !(this.isNullValue(idValue) && this.isNullValue(typeValue?.value))
          )

          this.bvdAnaCreditDetails[key].forEach(({ value, type }, index) => {
            let identifierType = this.nationalITypeOpts.find((taxType) =>
              taxType.text.includes(type)
            )

            const isAlreadyExists = this.nationalIdentifiersPairs.find(
              (pair) => {
                return (
                  pair.idValue == value &&
                  pair.typeValue?.value == identifierType?.value
                )
              }
            )

            if (isAlreadyExists?.idValue) return

            this.nationalIdentifiersPairs.push({
              identificationId: null,
              typeValue: identifierType,
              typeOption: this.nationalITypeOpts,
              idValue: value,
              evidenceError: null,
              evidenceId: null,
              isDropdownLoading: false,
              isMandatory: this.highestIdentifierRank > 0
            })
            const currentPairIndex = this.nationalIdentifiersPairs.length - 1
            this.nationalIdentifierTypes[currentPairIndex] = identifierType
            this.nationalIdentifierIds[currentPairIndex] = value
          })

          if (this.nationalIdentifiersPairs.length < this.identifierMinPair) {
            this.addMinIdentifierPairs()
          }
        } else if (!this.isNullValue(this.bvdAnaCreditDetails[key]?.value)) {
          switch (key) {
            case 'legalProceedStatus':
              // Legal Proceed Status
              this.newAnaCDetails[key] = this.legalProceedStatusOpts.find(
                (item) => {
                  return item.text.includes(
                    this.bvdAnaCreditDetails[key]?.value
                  )
                }
              )
              break
            case 'enterpriseSizeDate':
              this.onChangeEnterpriseDate(
                new Date(this.bvdAnaCreditDetails[key]?.value)
              )
              break
            case 'legalProceedDate':
              this.onChangeLegalDate(
                new Date(this.bvdAnaCreditDetails[key]?.value)
              )
              break
            case 'annualTurnover':
            case 'balanceSheetTotal':
              this.newAnaCDetails[key] = Number(
                this.bvdAnaCreditDetails[key]?.value
              ).toFixed(2) // Fixing 2 decimal places
              break
            default:
              this.newAnaCDetails[key] = this.bvdAnaCreditDetails[key]?.value
              break
          }
        }
      }

      this.$nextTick(() => {
        this.identifierIdWatcher = false
      })
    }
  }
}
</script>

<style lang="scss">
.form-not-available {
  text-align: left;
  font: normal normal 13px/16px Quicksand;
  font-weight: 600;
  letter-spacing: 0.18px;
  height: 200px;
  font-size: large;
  color: #f57777;
}
.ana-credit-details-section {
  margin-top: 1rem;
  position: relative;
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }

  .add-evidence {
    width: 25%;
  }

  .mandatory-note {
    p {
      font: normal normal normal 12px/15px Quicksand;
      letter-spacing: 0.14px;
    }

    .mandatory {
      color: red;
      font-size: 17px;
    }
  }

  .anacredit-subsection {
    margin-top: 2rem;

    hr {
      background-color: #535eeb;
      border: none;
      display: block;
      height: 1px;
      margin: 0.5rem 0rem;
    }

    .ana-credit-subtitle {
      text-align: left;
      font-size: 15px;
      font-family: Quicksand;
      font-weight: 600;
      line-height: 15px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #535eeb;
      opacity: 1;
    }
  }

  .ana-credit-form,
  .tax-input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 87%;

    .column-view {
      width: 45%;
      margin-top: 2rem;
      display: flex;
      flex-direction: row;

      .required-error {
        position: relative;
      }

      .input-container {
        width: 100%;
      }

      .form-group {
        width: 100%;

        p {
          display: flex;
          margin: 0px;
          color: #7d7d7d;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          line-height: 20px;
          min-height: 21px;
          margin-bottom: 0.15rem;
        }

        .input {
          text-align: left;
          font-size: 13px;
          font-family: Quicksand;
          font-weight: 600;
          color: #1e1e1e;
          opacity: 1;
        }

        input[type='number'] {
          appearance: textfield;
          -moz-appearance: textfield;
        }

        input[type='number']::-webkit-inner-spin-button {
          display: none;
        }

        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .form-control-disabled > .form-control {
        width: 100%;
        background-color: rgb(83, 94, 235, 0.1);

        p {
          display: flex;
          margin: 0px;
          color: #7d7d7d;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          line-height: 20px;
          min-height: 21px;
          margin-bottom: 0.15rem;
        }

        .input {
          text-align: left;
          letter-spacing: 0.18px;
          color: #00218a !important;
          opacity: 0.9;
          font: normal normal 700 13px/23px Quicksand;
          font-weight: 600;
          width: 100%;
        }
      }

      .form-input-field {
        width: 75%;
      }
      .add-evidence {
        width: 25%;
        margin-top: 1.65rem;
      }
    }

    .tax-input-view {
      width: 87%;
      display: flex;
      justify-content: space-between;
    }

    .tax-button-view {
      width: 10%;
      display: flex;
      align-items: start;
      justify-content: space-around;
      margin-top: 3.8rem;
      .add-tax-button {
        background: var(--inava-primary);
        color: white;
        cursor: pointer;
        border: 0px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }

    .column-separator {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3.625rem;
    }
  }

  .tax-input-container {
    width: 100%;
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .ana-credit-details-section {
    border-radius: 6px;

    .section-title {
      font-size: 24px;
    }

    .mandatory-note {
      p {
        font-size: 13px;

        letter-spacing: 0.14px;
      }
    }

    .ana-credit-form,
    .tax-input-container {
      .ana-credit-subtitle {
        font-size: 17px;
      }

      .column-view {
        .radio-toolbar {
          p {
            font-size: 14px;
            font-weight: 600;
          }

          label {
            font-size: 14px;
            font-weight: 600;
          }
        }

        .form-group {
          p {
            font-size: 14px;
            min-height: 25px;
          }

          .input {
            font-size: 16px;
          }
        }

        .form-control-disabled > .form-control {
          p {
            font-size: 14px;
            min-height: 25px;
          }
          .input {
            font: normal normal 16px/23px Quicksand;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .ana-credit-details-section {
    .section-title {
      font-size: 26px;
    }

    .mandatory-note {
      p {
        font-size: 14px;
        letter-spacing: 0.14px;
      }
    }

    .ana-credit-form,
    .tax-input-container {
      .ana-credit-subtitle {
        font-size: 20px;
      }

      .column-view {
        width: 44%;

        .radio-toolbar {
          p {
            font-size: 16px;
            font-weight: 600;
          }

          label {
            font-size: 16px;
            font-weight: 600;
          }
        }

        .form-group {
          p {
            font-size: 16px;
            min-height: 25px;
          }

          .input {
            font-size: 18px;
            font-weight: 600;
          }
        }

        .form-control-disabled > .form-control {
          p {
            font-size: 16px;
            min-height: 25px;
          }
          .input {
            font: normal normal 18px/23px Quicksand;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
