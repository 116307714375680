<template>
  <div class="bvd-container" v-if="hasClientData || hasBVDData">
    <div class="title-row">
      <div class="title-container client">
        <div class="title">Client Attributes</div>
        <label class="select-all" v-if="false">
          <Checkbox
            :checked="selectAllClient"
            @change="toggleAllClient"
            class="attr-checkbox"
          />
          Select All
        </label>
      </div>
      <div class="title-container bvd">
        <div class="title">
          BVD Attributes
          <span v-if="attributesData?.bvdIdNumber"
            >({{ attributesData.bvdIdNumber }})</span
          >
        </div>
        <label class="select-all" v-if="false">
          <Checkbox
            type="checkbox"
            :checked="selectAllBVD"
            @change="toggleAllBVDDetails"
            class="attr-checkbox"
          />
          Select All
        </label>
      </div>
    </div>
    <div class="attribute-container">
      <div class="attribute-item">
        <div class="client-identifier-section" v-if="hasClientData">
          <table>
            <thead>
              <tr>
                <th colspan="5">{{ fieldMapping.identifier.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in clientData?.taxIdPair"
                :key="index"
                class="identifier-row"
              >
                <td>
                  <Checkbox
                    @change="handleFieldSelect($event, index, false)"
                    :checked="fieldMapping.identifier.client[index]"
                    :name="'client'"
                    :class="'attr-checkbox'"
                  />
                </td>
                <td>{{ item.value }}</td>
                <td>{{ item.type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="client-attributes no-data-message" v-else>
          <div>No data available.</div>
        </div>
        <div class="bvd-identifier-section" v-if="hasBVDData">
          <table>
            <thead>
              <tr>
                <th colspan="5">{{ fieldMapping.identifier.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in bvdData?.taxIdPair"
                :key="index"
                class="identifier-row"
              >
                <td>
                  <Checkbox
                    @change="handleFieldSelect($event, index, true)"
                    :class="'attr-checkbox'"
                    :name="'bvd'"
                    :checked="!isNull(taxIdTypes[index])"
                    :disabled="isNull(taxIdTypes[index])"
                  />
                </td>
                <td>{{ item.label }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.value }}</td>
                <td>
                  <SearchableSelect
                    :value="taxIdTypes[index]"
                    placeholder="Select Tax ID Type"
                    value_field="value"
                    text_field="text"
                    class="form-input-field"
                    @onChangeName="(value) => updateTaxIdType(value, index)"
                    :data="identifiersTaxTypes"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bvd-attributes no-data-message" v-else>
          <div>No data available.</div>
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <template v-if="hasClientData">
            <div class="radio-group">
              <RadioButton
                :name="fieldMapping.lei.name"
                :value="'client'"
                @change="handleFieldSelect($event)"
                :class="'attr-radio'"
                :disabled="isDisabled(clientData?.lei)"
                :checked="
                  !selectedValues[fieldMapping.lei.name]?.isBVD &&
                  !isDisabled(clientData?.lei)
                "
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{ fieldMapping.lei.label }}</label>
              <label class="value">{{ clientData?.lei }}</label>
            </div>
          </template>
        </div>
        <div class="bvd-attributes">
          <template v-if="hasBVDData">
            <div class="radio-group">
              <RadioButton
                :name="fieldMapping.lei.name"
                :value="'bvd'"
                @change="handleFieldSelect($event)"
                :class="'attr-radio'"
                :disabled="isDisabled(bvdData?.lei)"
                :checked="
                  selectedValues[fieldMapping.lei.name]?.isBVD &&
                  !isDisabled(bvdData?.lei)
                "
              />
            </div>
            <div class="label-group">
              <label class="attr-title">{{ fieldMapping.lei.label }}</label>
              <label class="value">{{ bvdData?.lei }}</label>
            </div>
          </template>
        </div>
      </div>

      <div class="attribute-item identifier-title" v-if="hasBVDData">
        <div class="client-attributes"></div>
        <div class="bvd-attributes crn-field">
          <div class="radio-group">
            <div class="attr-radio">&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </div>
          <div class="identifier-label-group">
            <div><span>National ID Label</span></div>
            <div><span>National ID type</span></div>
            <div><span>National ID</span></div>
          </div>
        </div>
      </div>
      <template v-for="(item, index) in Object.keys(identifierMapping)">
        <div class="attribute-item">
          <div
            :class="[
              'client-attributes no-padding-attr ',
              index === 0 ? 'crn-section' : ''
            ]"
          >
            <template v-if="hasClientData">
              <div class="radio-group">
                <RadioButton
                  :name="item"
                  :value="'client'"
                  :checked="
                    !selectedValues[item]?.isBVD &&
                    !isDisabled(clientData[item])
                  "
                  @change="handleFieldSelect($event)"
                  :class="'attr-radio'"
                  :disabled="isDisabled(clientData[item])"
                />
              </div>
              <div class="label-group">
                <label class="attr-title">{{
                  identifierMapping[item].label
                }}</label>
                <label class="value">{{ clientData[item] }}</label>
              </div>
            </template>
          </div>
          <div
            :class="[
              'bvd-attributes no-padding-attr',
              hasBVDData && Array.isArray(bvdData[item])
                ? 'bvd-attr-column'
                : '',
              index === 0 ? 'crn-section' : ''
            ]"
          >
            <template v-if="hasBVDData && Array.isArray(bvdData[item])">
              <div
                :class="'crn-field'"
                v-for="(identifier, index) in bvdData[item]"
                :key="index"
              >
                <div class="radio-group">
                  <RadioButton
                    :name="'crn'"
                    :value="'bvd'"
                    @change="handleFieldSelect($event, index)"
                    :class="'attr-radio'"
                    :checked="bvdCRN[index] && !isDisabled(identifier?.value)"
                    :disabled="isDisabled(identifier?.value)"
                  />
                </div>
                <div class="identifier-label-group">
                  <div>{{ identifier.label }}</div>
                  <div>{{ identifier.type }}</div>
                  <div>{{ identifier.value }}</div>
                </div>
              </div>
              <div class="crn-field" v-if="bvdData[item].length === 0">
                <div class="radio-group">
                  <div class="attr-radio">
                    <RadioButton
                      :name="'crn'"
                      :value="'bvd'"
                      :class="'attr-radio'"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="identifier-label-group">
                  <div>
                    {{ identifierMapping[item].label }}
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </template>
            <template v-else-if="hasBVDData">
              <div class="crn-field">
                <div class="radio-group">
                  <RadioButton
                    :name="item"
                    :value="'bvd'"
                    @change="handleFieldSelect($event)"
                    :class="'attr-radio'"
                    :disabled="isDisabled(bvdData[item]?.value)"
                    :checked="
                      selectedValues[item]?.isBVD &&
                      !isDisabled(bvdData[item]?.value)
                    "
                  />
                </div>
                <div class="identifier-label-group">
                  <div>
                    {{ bvdData[item]?.label || identifierMapping[item].label }}
                  </div>
                  <div>{{ bvdData[item]?.type }}</div>
                  <div>{{ bvdData[item]?.value }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <div class="attribute-item">
        <div class="client-attributes"></div>
        <div class="bvd-attributes"></div>
      </div>
    </div>
    <div v-if="hasClientData || hasBVDData" class="button-container">
      <b-button
        class="action-button cancel-button"
        :size="'is-small'"
        @click="resetBVD"
        >Reset</b-button
      >
      <b-button
        class="action-button confirm-button"
        :size="'is-small'"
        @click="saveData"
        >Update Form</b-button
      >
    </div>
  </div>
  <div v-else>No data available.</div>
</template>

<script>
import SearchableSelect from '@/components/DMP/SearchableSelect'
import { Checkbox, RadioButton } from '@progress/kendo-vue-inputs'
import { isEmpty } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'BVDIdentifiersForm',
  components: {
    RadioButton,
    SearchableSelect,
    Checkbox
  },
  props: {
    attributesData: {
      type: Object,
      required: true,
      default: () => {}
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState('identifiers', ['identifiersTaxTypes']),
    hasClientData() {
      return (
        this.clientData &&
        !Object.keys(this.clientData).every((item) =>
          isEmpty(this.clientData[item])
        )
      )
    },
    hasBVDData() {
      return this.bvdData && Object.keys(this.bvdData).length > 0
    }
  },
  mounted() {
    this.resetBVD()
  },
  data() {
    return {
      clientData: null,
      bvdData: null,
      identifierMapping: {
        crn: {
          label: 'Company Registration Number'
        },
        cik: {
          label: 'CIK'
        },
        crd: {
          label: 'CRD'
        },
        rssd: {
          label: 'RSSD'
        }
      },
      selectAllClient: true,
      selectAllBVD: true,
      taxIdTypes: {
        0: null
      },
      fieldMapping: {
        identifier: {
          name: 'identifier',
          label: 'EIN (Tax ID)',
          client: {}
        },
        lei: {
          name: 'lei',
          label: 'LEI'
        }
      },
      selectedValues: {
        identifier: [],
        crn: null,
        lei: null,
        cik: null,
        rssd: null,
        crd: null
      },
      bvdCRN: {
        0: null
      }
    }
  },
  watch: {
    attributesData: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.clientData = newVal?.data?.clientData
        this.bvdData = newVal?.data?.bvdDataFinal
        this.resetBVD()
      }
    }
  },
  methods: {
    ...mapActions('identifiers', ['patchIdentifiersDetails']),
    toggleAllClient() {
      this.selectAllClient = !this.selectAllClient
      this.selectAllBVD = false
    },
    toggleAllBVDDetails() {},
    isNull(value) {
      return [undefined, null, ''].includes(value)
    },
    handleFieldSelect(event, index, dropdownChange = null) {
      if (!this.isNull(index) && !this.isNull(dropdownChange)) {
        const checkbox = event ? event.e.target : null
        const key = 'identifier'
        const isChecked = checkbox
          ? checkbox.checked
          : !this.isNull(this.taxIdTypes[index]?.value)
        this.selectedValues[key] = this.selectedValues[key]?.length
          ? [...this.selectedValues[key]]
          : []

        const isAlreadyAdded = this.selectedValues[key].find(
          ({ value, type }) => {
            return (
              value ===
                (dropdownChange
                  ? this.bvdData?.taxIdPair[index].value
                  : this.clientData?.taxIdPair[index]?.value) &&
              type ===
                (dropdownChange
                  ? this.taxIdTypes[index]?.text
                  : this.clientData?.taxIdPair[index]?.type)
            )
          }
        )

        if (isChecked && !isAlreadyAdded) {
          this.selectedValues[key].push(
            dropdownChange
              ? {
                  type: this.taxIdTypes[index]?.text,
                  value: this.bvdData?.taxIdPair[index]?.value
                }
              : this.clientData?.taxIdPair[index]
          )
        } else if (!isChecked) {
          this.selectedValues[key] = this.selectedValues[key].filter(
            (item) =>
              item?.value !==
              (!dropdownChange
                ? this.clientData?.taxIdPair[index]?.value
                : this.bvdData?.taxIdPair[index]?.value)
          )

          if (checkbox && !checkbox.checked && dropdownChange) {
            this.taxIdTypes = {
              ...this.taxIdTypes,
              [index]: null
            }
          }
        }

        if (checkbox && !dropdownChange) {
          this.fieldMapping.identifier.client = {
            ...this.fieldMapping.identifier.client,
            [index]: isChecked
          }
        }
      } else {
        const radioButton = event.event.target
        const key = radioButton?.name
        const source = radioButton?.value
        switch (source) {
          case 'bvd':
            switch (key) {
              case 'lei':
                this.selectedValues[key] = this.bvdData[key]
                break
              case 'crn':
                this.selectedValues[key] = this.bvdData[key][index]?.value
                Object.keys(this.bvdData?.crn)?.forEach((item, crnIndex) => {
                  this.bvdCRN = {
                    ...this.bvdCRN,
                    [crnIndex]: crnIndex === index
                  }
                })
                break
              default:
                this.selectedValues[key] = this.bvdData[key]?.value
            }
            break
          case 'client':
            this.selectedValues[key] = this.clientData[key]
            switch (key) {
              case 'crn':
                Object.keys(this.bvdCRN)?.forEach((item, crnIndex) => {
                  this.bvdCRN = {
                    ...this.bvdCRN,
                    [item]: false
                  }
                })
                break
            }
            break
        }
        this.selectedValues[key] = {
          value: this.selectedValues[key],
          isBVD: source === 'bvd'
        }
      }
    },

    isDisabled(value) {
      return this.isNull(value)
    },

    saveData() {
      this.patchIdentifiersDetails(this.selectedValues)
    },

    resetBVD() {
      for (let key in this.clientData) {
        switch (key) {
          case 'taxIdPair':
            this.selectedValues['identifier'] = [...this.clientData[key]]
            this.clientData[key]?.forEach((item, index) => {
              this.fieldMapping.identifier.client = {
                ...this.fieldMapping.identifier.client,
                [index]: true
              }
            })
            Object.keys(this.taxIdTypes)?.forEach((item, index) => {
              this.taxIdTypes[item] = null
            })
            break
          case 'crn':
            Object.keys(this.bvdCRN)?.forEach((item, crnIndex) => {
              this.bvdCRN = {
                ...this.bvdCRN,
                [item]: null
              }
            })
            this.selectedValues[key] = {
              value: this.clientData[key],
              isBVD: false
            }
            break
          default:
            this.selectedValues[key] = {
              value: this.clientData[key],
              isBVD: false
            }
        }
      }
    },

    updateTaxIdType(taxIdTypeValue, taxIndex) {
      // Remove old selected value
      const identifierValue = this.bvdData?.taxIdPair[taxIndex]?.value
      const identifierType = this.taxIdTypes[taxIndex]?.text
      this.selectedValues.identifier = this.selectedValues.identifier?.filter(
        (item) => item.value !== identifierValue && item.type !== identifierType
      )

      // Update dropdown value
      this.taxIdTypes = {
        ...this.taxIdTypes,
        [taxIndex]: taxIdTypeValue
      }
      this.handleFieldSelect(null, taxIndex, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-data-message::v-deep {
  display: flex;
  align-items: start !important;
  justify-content: center;
  padding: 20px;
  color: #424242;
}

.attr-radio::v-deep,
.attr-checkbox::v-deep {
  input {
    border: 1px solid #707070;
  }
}

.bvd-container {
  padding: 0.5em;
  .title-row::v-deep {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 49%;
      .title {
        font-size: 22px;
      }
    }
    .title-container:first-child {
      width: 45%;
    }
    .title-container:last-child {
      width: 53%;
    }
    .client {
      .title {
        color: #00218a;
      }
      .k-checkbox:checked {
        background-color: #00218a;
        border-color: transparent;
        box-shadow: none;
      }
    }
    .bvd {
      .title {
        color: #f57777;
      }
      .k-checkbox:checked {
        background-color: #f57777;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .button-container {
    justify-content: end;
    .save-buttons {
      width: 15%;
      display: flex;
      justify-content: space-around;
    }
  }
}

.attribute-container {
  .attribute-item::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .client-attributes,
    .bvd-attributes,
    .client-identifier-section,
    .bvd-identifier-section {
      width: 49%;
      display: flex;
      flex-direction: row;
      padding-bottom: 1em;
      background-color: #f9f9f9;
      align-items: center;
      line-height: 1.7em;
      border: 1px solid #cccccc;
      border-width: 0 1px;
      .radio-group {
        padding: 1em;
      }
      .label-group {
        display: flex;
        flex-direction: column;

        .attr-title {
          color: #7d7d7d;
          font-size: 12px;
        }
        .value {
          color: #1e1e1e;
          font-size: 12px;
        }
      }
      .attr-radio-td {
        display: inline;
      }
      .crn-field {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      .identifier-label-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        div {
          flex-basis: 100%;
          color: #1e1e1e;
          font-size: 12px;
          span {
            color: #7d7d7d;
            font-size: 12px;
          }
        }
        div:first-child {
          color: #7d7d7d;
          font-size: 12px;
        }
      }
    }
    .client-attributes,
    .client-identifier-section {
      width: 45%;
      table {
        line-height: 3.6em;
      }
      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #00218a;
        box-shadow: none;
      }
    }
    .bvd-attributes,
    .bvd-identifier-section {
      width: 53%;
      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #f57777;
        box-shadow: none;
      }
    }
    .bvd-attr-column {
      display: flex;
      flex-direction: column;
    }
    .no-padding-attr {
      padding: 0;
    }
    .crn-section {
      border-bottom: 1px solid #cccccc;
    }
  }
  .attribute-item:first-child {
    .client-attributes,
    .bvd-attributes,
    .client-identifier-section,
    .bvd-identifier-section {
      background-color: #f9f9f9;
      border: 1px solid #cccccc;
      border-bottom: none;
    }
  }
  .attribute-item:last-child {
    .client-attributes,
    .bvd-attributes {
      border-bottom: 1px solid #cccccc;
      margin: 0;
      padding: 0;
    }
  }
  .identifier-title {
    .client-attributes,
    .bvd-attributes {
      padding: 0;
      border-top: 1px solid #cccccc;
      .identifier-label-group {
        div > span {
          color: #7d7d7d;
          font-size: 12px;
        }
      }
    }
  }
  .identifier-caption {
    .client-attributes,
    .bvd-attributes {
      border-top: 1px solid #cccccc;
      padding: 0;
    }
    .bvd-attributes {
      justify-content: center;
      align-items: center;
      color: #7d7d7d;
      font-size: 12px;
    }
  }
}

table {
  border: 1px solid #cccccc;
  margin: 0.5em;
  width: 100%;
  font-size: 12px;
  thead {
    tr {
      th {
        padding: 0.3em;
        color: #7d7d7d;
      }
    }
  }
  tbody {
    tr,
    .identifier-row {
      td {
        vertical-align: middle;
        padding: 0.3em;
        color: #1e1e1e;
        border: 1px solid #cccccc;
      }
      td:first-child {
        width: 5%;
      }

      td:not(:first-child) {
        vertical-align: middle;
        padding-bottom: 0.8em;
      }
    }
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  height: 5rem;
  padding-bottom: 0.6rem;
  width: 100%;
  gap: 1rem;

  .action-button {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    min-width: 104px;
    height: 46px;
  }
  .confirm-button {
    background-color: #535eeb; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: left;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
  }
  .confirm-button:hover {
    background-color: #00218a; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    opacity: 0.9;
  }

  .cancel-button {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */

    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;
  }
  .cancel-button:hover {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    opacity: 0.9;
  }
}
</style>
