<template>
  <div class="entities-container">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <EntityFormHeader
      :reviewMode="isReviewSubmit"
      @onSubmit="submitForReview"
      @onEdit="backToForm"
      @onConfirm="onConfirm"
      v-if="!isAddEntitiesEnabled"
    />

    <!-- main form container -->
    <div class="form-container">
      <div class="tab-container">
        <div
          class="worktype-container"
          v-if="!isAddEntitiesEnabled && !isReviewSubmit"
        >
          <p>
            Work type: <span>{{ workType }}</span>
          </p>
        </div>
        <TabStrip
          v-if="!isAddEntitiesEnabled && !isReviewSubmit"
          :selected="selected"
          @select="onSelect"
          :tabs="tabs"
        >
          <template v-slot:TabOne>
            <div class="entity-details-container">
              <EntityDetails :auditDetails="auditDetails"></EntityDetails>
            </div>
          </template>
          <template v-slot:TabTwo>
            <RelationshipHierarchy
              :relationshipHierarchy="relationshipHierarchy"
              ref="hierarchyComponentRef"
            ></RelationshipHierarchy>
          </template>
          <template v-slot:TabThree>
            <CorporateActions
              :caDetails="caDetails"
              :changeTabStrip="changeTabStrip"
            ></CorporateActions>
          </template>
          <template v-slot:TabFour>
            <audit></audit>
          </template>
          <template v-slot:TabFive>
            <p>Coming Soon...</p>
          </template>
        </TabStrip>
      </div>
    </div>

    <!-- Submit for Review component -->
    <div class="form-review-container" v-if="isReviewSubmit">
      <EntityDetailsReview ref="submitReviewForm"></EntityDetailsReview>
    </div>

    <ConfirmationDialog
      class="mandatory-popup"
      :visible="dialog.visible"
      :title="dialog.title"
      :bodyText="dialog.text"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="dialog.triggerFunction"
    />
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import EntityFormHeader from '@/components/DMP/EntityFormHeader.vue'
import Snackbar from '@/components/Snackbar'
import { checkPathPermission } from '@/util/permissions.utils'
import Audit from '@/views/DataManagement/Audit/Audit.vue'
import CorporateActions from '@/views/DataManagement/CorporateAction/CorporateAction.vue'
import EntityDetails from '@/views/DataManagement/EntityDetails.vue'
import EntityDetailsReview from '@/views/DataManagement/EntityDetailsReview.vue'
import RelationshipHierarchy from '@/views/DataManagement/RelationshipHierarchy.vue'
import { TabStrip } from '@progress/kendo-vue-layout'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'DMPEntities',
  components: {
    EntityFormHeader,
    TabStrip,
    EntityDetails,
    Audit,
    EntityDetailsReview,
    ConfirmationDialog,
    RelationshipHierarchy,
    CorporateActions
  },
  data() {
    return {
      isEnabled: false,
      sgaId: '',
      selected: 0,
      isReviewSubmit: false,
      loading: false,
      workType: null,

      // confirmation popup
      dialog: {
        visible: false,
        title: 'Missing Mandatory Details',
        text: '',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Edit Form',
        triggerFunction: () => {}
      },

      tabs: [
        {
          title: 'Entity Details',
          content: 'TabOne'
        },
        {
          title: 'Hierarchy/Relationships Details',
          content: 'TabTwo'
        },
        {
          title: 'Corporate Actions',
          content: 'TabThree'
        },
        {
          title: 'Audit Details',
          content: 'TabFour'
        },
        {
          title: 'Version History',
          content: 'TabFive'
        }
      ],

      snackbarPayload: {
        // Set default payload
        message: null,
        type: 'is-warning',
        duration: 3000
      }
    }
  },
  computed: {
    ...mapState('dmp', ['isAddEntitiesEnabled']),
    ...mapState('user', ['userDetails']),
    ...mapState('assignedEntities', ['entityDetails']),
    ...mapState('audit', ['auditDetails']),
    ...mapState('relationship', ['relationshipHierarchy']),
    ...mapState('corporateAction', ['caDetails']),
    ...mapGetters('dmp', ['getEntityDetails'])
  },
  mounted() {
    // Getting worktype from store
    const { workType, sgaId, isCAWorkType } = this.getEntityDetails
    this.getRelationships(sgaId)
    this.workType = workType
    this.sgaId = sgaId

    if (isCAWorkType) {
      this.changeTabStrip(2)
    }
  },
  methods: {
    ...mapActions('review', ['updateEntityStatus', 'updateEntityFlag']),
    ...mapActions('dmp', [
      'updateFullAddress',
      'getAddressDetails',
      'updateEntitiesStatus'
    ]),
    ...mapActions('audit', ['getAuditDetails']),
    ...mapActions('relationship', ['getRelationships']),
    ...mapActions('corporateAction', ['getCorporateAction']),
    onChangeModal(value) {
      this.modal = value
    },

    onAddEntities(sgaId) {
      this.$router.push({
        name: 'addEntities',
        params: { sgaId: sgaId }
      })
    },

    onSelect(e) {
      this.selected = e.selected
      if (e.selected === 3) {
        this.getAuditDetails(this.sgaId)
      } else if (e.selected === 2) {
        this.getCorporateAction(this.sgaId)
      } else if (e.selected === 1) {
        this.$refs.hierarchyComponentRef.compactTree()
      }
    },

    submitForReview(isSubmit) {
      this.isReviewSubmit = isSubmit
    },

    backToForm(isEdit) {
      this.isReviewSubmit = isEdit
      this.selected = 0 // back to entity details tab
    },

    async onConfirm() {
      const { isMandatoryPassed, errorFields } =
        this.$refs.submitReviewForm.mandatoryValidation()
      if (isMandatoryPassed) {
        // To Do: action to be performed
        // make api call for submit for review
        // redirect to assigned entities page
        const { assignmentId, sgaId } = this.getEntityDetails
        const entityStatusData = {
          assignmentId: assignmentId,
          userRole: this.userDetails.roleName,
          currentStatus: this.entityDetails[0]?.currentStatus
        }

        try {
          this.loading = true

          let isSuccess = false

          if (
            checkPathPermission(
              'submit-for-delivery',
              this.userDetails.roleName
            )
          ) {
            const entityFlagObj = {
              sgaId: sgaId,
              flag: false
            }
            // Verify the response of update submit-for-delivery flag
            isSuccess = await this.updateEntityFlag(entityFlagObj)
            if (isSuccess) {
              const addressObj = {
                sgaId: sgaId,
                fullAddress: '##CLEAR##'
              }
              await this.updateFullAddress(addressObj)
              await this.getAddressDetails(sgaId)
            }
          }

          isSuccess = await this.updateEntityStatus(entityStatusData)

          if (isSuccess) {
            this.$router.go(-1)
            this.snackbarPayload.message = 'Details submitted successfully.'
          } else {
            // In-case of update failure
            throw new Error()
          }
        } catch (error) {
          this.snackbarPayload.message = 'Failed to submit the Details.'
        } finally {
          this.loading = false
        }
        Snackbar(this.snackbarPayload)
      } else {
        // show warning msg
        let popUpMsg = ''
        for (const formName in errorFields) {
          const title = `<br> <br> <strong> ${formName}</strong> <br>`
          const errorMsg = errorFields[formName].join('<br>')
          popUpMsg += `${title} ${errorMsg}`
        }
        this.dialog.visible = true
        this.dialog.text = `Please provide below mandatory details: ${popUpMsg}`
        this.dialog.triggerFunction = () => {
          // User confirmed, go back to edit form
          this.backToForm()
        }
      }
    },

    closeDialog() {
      this.dialog.visible = false
    },

    changeTabStrip(strapIndex) {
      this.selected = strapIndex
    }
  },
  destroyed() {
    // Reset the entity details
    this.updateEntitiesStatus({
      entityStatus: false,
      entityDetails: { sgaId: null, assignmentId: null, workType: null }
    })
  }
}
</script>
<style lang="scss" scoped>
.entities-container {
  max-width: 1450px;
  margin: 0 auto;
  background-color: var(--background-color);

  .entities-header {
    display: flex;
    align-items: center;

    .entities-add-btn {
      margin-left: auto;
    }
  }
}
</style>
<style lang="scss">
.entities-container {
  max-width: 1450px;
  margin: 0 auto;
  background-color: var(--background-color);

  .entities-header {
    display: flex;
    align-items: center;

    .entities-add-btn {
      margin-left: auto;
    }
  }
}

.tab-container {
  position: relative;

  .worktype-container {
    position: absolute;
    top: 10px;
    right: 4px;
    padding: 10px;

    p {
      font-size: 16px;
      color: black;

      span {
        font-size: 16px;
        color: var(--inava-primary-dark);
        border: 1px solid var(--inava-primary-dark);
        border-radius: 4px;
        padding: 5px;
      }
    }
  }
}

.entities-container {
  .k-tabstrip {
    font-size: 16px;
    color: #000000;
    background-color: var(--dmp-entities-tab-menu-bg-color);

    .k-tabstrip-items {
      padding: 12px;
      width: 65%;
    }

    .k-tabstrip-items-wrapper .k-item {
      color: #7d7d7d;
    }

    .k-tabstrip-items-wrapper .k-item:active,
    .k-tabstrip-items-wrapper .k-item.k-active,
    .k-tabstrip-items-wrapper .k-item.k-selected {
      border-color: rgba(0, 0, 0, 0.08);
      border-bottom-color: rgba(0, 0, 0, 0.08);
      color: #000000;
      background-color: #dfdfdf;
    }
  }
}

.entities-container {
  .mandatory-popup {
    .k-dialog-wrapper {
      .k-window-content {
        .popup-msg {
          text-align: left;
        }
      }
    }
  }
}
</style>
