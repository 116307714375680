<!-- CollapsibleDynamicForm.vue -->
<template>
  <CollapsibleSection
    :title="title"
    :collapsed="isCollapsed"
    :showCollapsibleSection="shouldShowCollapsibleForm"
    :loading="loading"
  >
    <component
      :is="formComponent"
      :attributesData="details"
      :loading="loading"
      @update:data="$emit('update:data', $event)"
      @save="$emit('save', $event)"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </CollapsibleSection>
</template>

<script>
import CollapsibleSection from '@/components/DMP/CollapsibleComponent.vue'
import BVDBasicDetailsForm from '@/views/DataManagement/BasicDetails/BVDBasicDetailsForm.vue'
import BVDIndustryForm from '@/views/DataManagement/IndustryDetails/BVDIndustryForm.vue'
import BVDRelationships from '@/views/DataManagement/Relationships/BVDRelationships.vue'
import BVDAnaCredit from '@/views/DataManagement/AnaCreditDetails/BVDAnaCredit.vue'
import BVDIdentifiersForm from '@/views/DataManagement/Identifiers/BVDIdentifiersForm.vue'
import BVDAddressForm from '@/views/DataManagement/AddressDetails/BVDAddressForm.vue'
import { isEmpty } from 'lodash'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex/dist/vuex.common.js'
import { BVD_FORM_KEYS_MAPPING } from './constants'

export default {
  name: 'CollapsibleDynamicForm',
  components: {
    CollapsibleSection,
    BVDBasicDetailsForm,
    BVDRelationships,
    BVDAddressForm,
    BVDIndustryForm,
    BVDAnaCredit,
    BVDIdentifiersForm
  },
  props: {
    title: {
      type: String,
      required: true
    },
    formName: {
      type: String,
      required: true
    },
    sgaId: {
      type: String,
      required: true
    },
    entityAnaCreditApplicabilityflag: {
      type: Object,
      required: false
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      BVD_FORM_KEYS_MAPPING: BVD_FORM_KEYS_MAPPING,
      details: {},
      loading: false,
      currentSgaId: this.sgaId,
      isCollapsed: true
    }
  },
  computed: {
    formComponent() {
      switch (this.formName) {
        case 'BasicDetailsForm':
          return 'BVDBasicDetailsForm'
        case 'AdderessForm':
          return 'BVDAddressForm'
        case 'IndustryForm':
          return 'BVDIndustryForm'
        case 'IdentifiersForm':
          return 'BVDIdentifiersForm'
        case 'ADetailsForm':
          return 'BVDADetailsForm'
        case ' AnaCreditDetailsForm':
          return 'BVDAnacreditForm'
        case 'RelationshipsForm':
          return 'BVDRelationships'
        case 'AnaCreditDetailsForm':
          return 'BVDAnaCredit'
        default:
          return 'BVDBasicDetailsForm' // Default to basic details form
      }
    },
    shouldBeCollapsed() {
      if (this.details === undefined || this.details === null) {
        return true // Always collapsed while loading or if details are undefined
      }

      switch (this.formName) {
        case 'RelationshipsForm':
          return Array.isArray(this.details) && this.details.length === 0
        case 'AnaCreditDetailsForm':
          return isEmpty(this.details?.bvd)
        case 'AdderessForm':
          return (
            [null, undefined].includes(this.details) ||
            Object.keys(this.details).length === 0
          )
        case 'IndustryForm':
          return (
            isEmpty(this.details?.bvd) &&
            isEmpty(this.details?.entityChangeHistory)
          )
        case 'BasicDetailsForm':
          return (
            [null, undefined].includes(this.details) ||
            Object.keys(this.details).length === 0
          )
        case 'IdentifiersForm':
          return isEmpty(this.details?.data?.bvdDataFinal)
        default:
          return true // Default to expanded for other forms when data is loaded
      }
    },
    shouldShowCollapsibleForm() {
      switch (this.formName) {
        case 'AnaCreditDetailsForm':
          return this.entityAnaCreditApplicabilityflag?.value
        default:
          return true // Default to expanded for other forms when data is loaded
      }
    }
  },
  watch: {
    sgaId: {
      immediate: true,
      async handler(newSgaId) {
        if (newSgaId !== this.currentSgaId) {
          await this.getData(newSgaId)
          this.currentSgaId = newSgaId
        }
      }
    },
    formName: {
      immediate: true,
      async handler(newVal) {
        await this.getData(this.sgaId)
      }
    },
    data: {
      immediate: true,
      handler(newVal) {
        this.details = newVal
        this.updateCollapsedState()
      }
    }
  },
  emits: ['update:formData', 'save'],
  methods: {
    ...mapActions('industry', ['updateBVDData']),
    async getData(sgaId) {
      if (!this.sgaId) {
        return
      }
      this.loading = true
      const bvdFormKey = this.getFormKey()
      this.details = {}
      this.updateBVDData(this.details)
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/common/bvd-details?sga_id=${sgaId}&key=${bvdFormKey}`
        )
        if (response.data.status === 200 && response.data.data) {
          this.details = response.data.data
          switch (bvdFormKey) {
            case this.BVD_FORM_KEYS_MAPPING.INDUSTRY_DETAILS:
              this.updateBVDData(this.details)
              break
          }
        }
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        throw error
      } finally {
        this.loading = false
        this.updateCollapsedState()
      }
    },
    getFormKey() {
      switch (this.formName) {
        case 'BasicDetailsForm':
          return this.BVD_FORM_KEYS_MAPPING.BASIC_DETAILS
        case 'AdderessForm':
          return this.BVD_FORM_KEYS_MAPPING.ADDRESS
        case 'IndustryForm':
          return this.BVD_FORM_KEYS_MAPPING.INDUSTRY_DETAILS
        case 'IdentifiersForm':
          return this.BVD_FORM_KEYS_MAPPING.IDENTIFIERS
        case 'AnaCreditDetailsForm':
          return this.BVD_FORM_KEYS_MAPPING.ANACREDIT
        case 'RelationshipsForm':
          return this.BVD_FORM_KEYS_MAPPING.RELATIONSHIPS
        default:
          return ''
      }
    },
    updateCollapsedState() {
      this.isCollapsed = this.shouldBeCollapsed
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>
