<template>
  <div class="bvd-container" v-if="hasClientData || hasBVDData">
    <div class="title-row">
      <div class="title-container client">
        <div class="title client">Client Attributes</div>
        <label class="select-all" v-if="false">
          <input
            type="checkbox"
            v-model="selectAllClient"
            @change="toggleAllClient"
          />
          Select All
        </label>
        <label class="select-all" v-else>
          <!-- blank  -->
        </label>
      </div>
      <div class="title-container bvd">
        <div class="title bvd">
          BVD Attributes
          <span v-if="bvdData?.bvdIdNumber">({{ bvdData.bvdIdNumber }})</span>
        </div>
        <label class="select-all" v-if="false">
          <input
            type="checkbox"
            v-model="selectAllBVD"
            @change="toggleAllBVD"
          />
          Select All
        </label>
        <label class="select-all" v-else>
          <!-- blank  -->
        </label>
      </div>
    </div>
    <div class="attribute-container">
      <div class="attribute-item">
        <div class="client-attributes" v-if="hasClientData">
          <div class="radio-group">
            <RadioButton
              :name="'naics'"
              :value="'first'"
              :checked="
                isRadioSelected(
                  selectedClientValues?.naics,
                  clientData.naics.value
                )
              "
              :disabled="
                clientData.naics.value && clientRadioFields.naics.radioDisabled
              "
              @change="selectRadio('naics', clientData.naics, true)"
              :label="''"
              :class="'attr-radio'"
            />
          </div>
          <div class="label-group">
            <label class="attr-title">NAICS</label>
            <label class="value">{{ clientData.naics.value.text }}</label>
          </div>
        </div>
        <div class="client-attributes no-client-attributes" v-else>
          No data available.
        </div>
        <div class="bvd-attributes grouping" v-if="hasBVDData">
          <div class="group-title">Select one</div>
          <div
            class="bvd-group-attributes"
            :key="index"
            v-for="(naics, index) in computedBvdData.naics"
          >
            <div class="radio-group">
              <RadioButton
                :name="'naics'"
                :value="'first'"
                :checked="
                  isRadioSelected(selectedBVDFields?.naics, naics.value)
                "
                :disabled="
                  bvdRadioFields?.naics
                    ? bvdRadioFields?.naics[index]?.radioDisabled
                    : false
                "
                @change="selectRadio('naics', naics, false)"
                :label="''"
                :class="'attr-radio'"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">
                {{ `NAICS  ${index + 1}  ` }}
              </label>
              <label class="value">{{ naics.value.text }}</label>
            </div>
          </div>
        </div>
        <div class="bvd-attributes no-bvd-attributes" v-else>
          No data available.
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes" v-if="hasClientData">
          <div class="radio-group">
            <RadioButton
              :name="'nace'"
              :value="'first'"
              :checked="
                isRadioSelected(
                  selectedClientValues?.nace,
                  clientData.nace.value
                )
              "
              :disabled="
                clientData.nace.value && clientRadioFields.nace.radioDisabled
              "
              @change="selectRadio('nace', clientData.nace, true)"
              :label="''"
              :class="'attr-radio'"
            />
          </div>
          <div class="label-group">
            <label class="attr-title">NACE</label>
            <label class="value">{{ clientData.nace.value.text }}</label>
          </div>
        </div>
        <div class="client-attributes" v-else>
          <!-- blank -->
        </div>
        <div class="bvd-attributes grouping" v-if="hasBVDData">
          <div class="group-title">Select one</div>
          <div
            class="bvd-group-attributes"
            :key="index"
            v-for="(nace, index) in computedBvdData.nace"
          >
            <div class="radio-group">
              <RadioButton
                :name="'nace'"
                :value="'first'"
                :checked="isRadioSelected(selectedBVDFields?.nace, nace.value)"
                :disabled="
                  bvdRadioFields?.nace
                    ? bvdRadioFields?.nace[index]?.radioDisabled
                    : false
                "
                @change="selectRadio('nace', nace, false)"
                :label="''"
                :class="'attr-radio'"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">
                {{ `NACE  ${index + 1}  ` }}
              </label>
              <label class="value">{{ nace.value.text }}</label>
            </div>
          </div>
        </div>
        <div class="bvd-attributes" v-else>
          <!-- blank -->
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes" v-if="hasClientData">
          <div class="radio-group">
            <RadioButton
              :name="'sic'"
              :value="'first'"
              :checked="
                isRadioSelected(selectedClientValues?.sic, clientData.sic.value)
              "
              :disabled="
                clientData.sic.value && clientRadioFields.sic.radioDisabled
              "
              @change="selectRadio('sic', clientData.sic, true)"
              :label="''"
              :class="'attr-radio'"
            />
          </div>
          <div class="label-group">
            <label class="attr-title">SIC</label>
            <label class="value">{{ clientData.sic.value.text }}</label>
          </div>
        </div>
        <div class="client-attributes" v-else>
          <!-- blank -->
        </div>
        <div class="bvd-attributes grouping" v-if="hasBVDData">
          <div class="group-title">Select one</div>
          <div
            class="bvd-group-attributes"
            :key="index"
            v-for="(sic, index) in computedBvdData.sic"
          >
            <div class="radio-group">
              <RadioButton
                :name="'sic'"
                :value="'first'"
                :checked="isRadioSelected(selectedBVDFields?.sic, sic.value)"
                :disabled="
                  bvdRadioFields?.sic
                    ? bvdRadioFields?.sic[index]?.radioDisabled
                    : false
                "
                @change="selectRadio('sic', sic, false)"
                :label="''"
                :class="'attr-radio'"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">
                {{ `SIC  ${index + 1}  ` }}
              </label>
              <label class="value">{{ sic.value.text }}</label>
            </div>
          </div>
        </div>
        <div class="bvd-attributes" v-else>
          <!-- blank -->
        </div>
      </div>

      <!--  -->
      <div
        class="attribute-item"
        v-if="industryFieldList.includes('secondaryNaics')"
      >
        <div class="client-attributes" v-if="hasClientData">
          <div class="radio-group">
            <RadioButton
              :name="'secondaryNaics'"
              :value="'first'"
              :checked="
                isRadioSelected(
                  selectedClientValues?.secondaryNaics,
                  clientData.secondaryNaics.value
                )
              "
              :disabled="
                clientData.secondaryNaics.value &&
                clientRadioFields.secondaryNaics.radioDisabled
              "
              @change="
                selectRadio('secondaryNaics', clientData.secondaryNaics, true)
              "
              :label="''"
              :class="'attr-radio'"
            />
          </div>
          <div class="label-group">
            <label class="attr-title">Secondary NAICS</label>
            <label class="value">{{
              clientData.secondaryNaics.value.text
            }}</label>
          </div>
        </div>
        <div class="client-attributes" v-else>
          <!-- blank -->
        </div>
        <div class="bvd-attributes grouping" v-if="hasBVDData">
          <div class="group-title">Select one</div>
          <div
            class="bvd-group-attributes"
            :key="index"
            v-for="(secondaryNaics, index) in computedBvdData.secondaryNaics"
          >
            <div class="radio-group">
              <RadioButton
                :name="'secondaryNaics'"
                :value="'first'"
                :checked="
                  isRadioSelected(
                    selectedBVDFields?.secondaryNaics,
                    secondaryNaics.value
                  )
                "
                :disabled="
                  bvdRadioFields?.secondaryNaics
                    ? bvdRadioFields?.secondaryNaics[index]?.radioDisabled
                    : false
                "
                @change="selectRadio('secondaryNaics', secondaryNaics, false)"
                :label="''"
                :class="'attr-radio'"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">
                {{ `Secondary NAICS  ${index + 1}  ` }}
              </label>
              <label class="value">{{ secondaryNaics.value.text }}</label>
            </div>
          </div>
        </div>
        <div class="bvd-attributes" v-else>
          <!-- blank -->
        </div>
      </div>
      <div
        class="attribute-item"
        v-if="industryFieldList.includes('secondaryNace')"
      >
        <div class="client-attributes" v-if="hasClientData">
          <div class="radio-group">
            <RadioButton
              :name="'secondaryNace'"
              :value="'first'"
              :checked="
                isRadioSelected(
                  selectedClientValues?.secondaryNace,
                  clientData.secondaryNace.value
                )
              "
              :disabled="
                clientData.secondaryNace.value &&
                clientRadioFields.secondaryNace.radioDisabled
              "
              @change="
                selectRadio('secondaryNace', clientData.secondaryNace, true)
              "
              :label="''"
              :class="'attr-radio'"
            />
          </div>
          <div class="label-group">
            <label class="attr-title">Secondary NACE</label>
            <label class="value">
              {{ clientData.secondaryNace.value.text }}
            </label>
          </div>
        </div>
        <div class="client-attributes" v-else>
          <!-- blank -->
        </div>
        <div class="bvd-attributes grouping" v-if="hasBVDData">
          <div class="group-title">Select one</div>
          <div
            class="bvd-group-attributes"
            :key="index"
            v-for="(secondaryNace, index) in computedBvdData.secondaryNace"
          >
            <div class="radio-group">
              <RadioButton
                :name="'secondaryNace'"
                :value="'first'"
                :checked="
                  isRadioSelected(
                    selectedBVDFields?.secondaryNace,
                    secondaryNace.value
                  )
                "
                :disabled="
                  bvdRadioFields?.secondaryNace
                    ? bvdRadioFields?.secondaryNace[index]?.radioDisabled
                    : false
                "
                @change="selectRadio('secondaryNace', secondaryNace, false)"
                :label="''"
                :class="'attr-radio'"
              />
            </div>
            <div class="label-group">
              <label class="attr-title">
                {{ `Secondary NACE  ${index + 1}  ` }}
              </label>
              <label class="value"> {{ secondaryNace.value.text }} </label>
            </div>
          </div>
        </div>
        <div class="bvd-attributes" v-else>
          <!-- blank -->
        </div>
      </div>

      <!--  -->
      <div class="attribute-item">
        <div class="client-attributes">
          <!-- not present in client -->
        </div>
        <div class="bvd-attributes bvd-attributes-read-only" v-if="hasBVDData">
          <div class="label-group">
            <!-- <div class="radio-group"></div> -->
            <label class="attr-title"> Products & Services </label>
            <label class="value">
              {{ computedBvdData.productsAndServices }}
            </label>
          </div>
        </div>
        <div class="bvd-attributes" v-else>
          <!-- blank -->
        </div>
      </div>
      <div class="attribute-item">
        <div class="client-attributes">
          <!-- not present in client -->
        </div>
        <div class="bvd-attributes bvd-attributes-read-only" v-if="hasBVDData">
          <div class="label-group">
            <label class="attr-title"> Trade Description (English) </label>
            <label class="value">
              {{ computedBvdData.tradeDescription }}
            </label>
          </div>
        </div>
        <div class="bvd-attributes" v-else>
          <!-- blank -->
        </div>
      </div>
    </div>
    <div v-if="hasClientData || hasBVDData" class="button-container">
      <b-button
        class="action-button cancel-button"
        :size="'is-small'"
        @click="onReset"
      >
        Reset
      </b-button>

      <b-button
        class="action-button confirm-button"
        :size="'is-small'"
        @click="onSavingDynamicForm"
        :disabled="!isUpdateBtnReady"
      >
        Update Form
      </b-button>
    </div>
  </div>
  <div v-else>No data available.</div>
</template>

<script>
import { RadioButton } from '@progress/kendo-vue-inputs'
import { mapActions, mapState } from 'vuex'
import { isEqual, isEmpty, cloneDeep, isArray } from 'lodash'

export default {
  name: 'DynamicIndustryForm',

  components: {
    RadioButton
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      INDUSTRY_DETAILS_LABEL: 'Industry Details',
      selectedClientValues: {},
      selectedBVDFields: {},
      selectAllClient: false,
      selectAllBVD: false,
      industryFieldList: [
        'naics',
        'secondaryNaics',
        'nace',
        'secondaryNace',
        'sic'
      ],
      fieldNameList: [
        'id',
        'sicEvdId',
        'naicsEvdId',
        'secondaryNaicsEvdId',
        'naceEvdId',
        'secondaryNaceEvdId',
        'germanExtNace',
        'germanExtNaceEvdId',
        'businessNatureEvdId',
        'industryEvdId'
      ],
      dynamicFormOutputData: {},
      selectAllToggleCheck: false,
      bvdRadioFields: null,
      clientRadioFields: null,
      fromBvdSelect: false,
      clientData: null,
      computedBvdData: null,
      isDynamic: true,
      isClientDataLoaded: false,
      isBvdDataLoaded: false,
      isUpdateBtnReady: true
    }
  },

  computed: {
    ...mapState('industry', [
      'bvdData',
      'industryDetail',
      'isNotHoldingCompanyDisabled',
      'dynamicNaicsList',
      'dynamicSecondaryNaicsList',
      'dynamicNaceList',
      'dynamicSecondaryNaceList',
      'dynamicSicList',
      'isBVDReady',
      'businessNature',
      'industryDetailCopy'
    ]),
    ...mapState('evidence', ['isBVD']),

    hasClientData() {
      return this.clientData && Object.keys(this.clientData).length > 0
    },

    hasBVDData() {
      return (
        this.computedBvdData && Object.keys(this.computedBvdData).length > 0
      )
    },

    bvdReadyFlag() {
      return (
        this.isBVDReady &&
        (!isEmpty(this.bvdData.bvd) ||
          !isEmpty(this.bvdData.entityChangeHistory))
      )
    },

    isSelectAllEnabled() {
      return this.isClientDataLoaded && this.isBvdDataLoaded
    }
  },

  watch: {
    isNotHoldingCompanyDisabled() {
      if (this.isNotHoldingCompanyDisabled) {
        for (let i = 0; i < this.industryFieldList.length; i++) {
          if (
            this.industryFieldList[i] === 'secondaryNaics' ||
            this.industryFieldList[i] === 'secondaryNace'
          ) {
            this.fieldNameList.splice(0, 0, this.industryFieldList[i])
            this.industryFieldList.splice(i, 1)
          }
        }
      }
    },

    bvdReadyFlag() {
      if (this.isBVDReady) {
        this.copyDropdownListToDynamicForm()
        this.clientData = this.computeClientData()
        this.computedBvdData = this.computeBvdData()
      }
    },

    clientData() {
      this.clientRadioFields = cloneDeep(this.clientData)
      let foundNaics = true
      let foundNace = true
      let foundSic = true

      if (!isEmpty(this.clientRadioFields)) {
        for (let i = 0; i < this.dynamicNaicsList.length; i++) {
          if (
            isEqual(
              this.clientData.naics.value.value,
              this.dynamicNaicsList[i].value
            )
          ) {
            foundNaics = true
            break
          }
        }

        if (foundNaics) {
          // this.getNaceAndSicList(this.clientData.naics.value.value)
          this.clientRadioFields.naics.radioDisabled = false
        } else {
          this.clientRadioFields.naics.radioDisabled = true
          this.clientRadioFields.nace.radioDisabled = true
          this.clientRadioFields.sic.radioDisabled = true
        }

        for (let i = 0; i < this.dynamicNaceList.length; i++) {
          if (
            isEqual(
              this.clientData.nace.value.value,
              this.dynamicNaceList[i].value
            )
          ) {
            foundNace = true
            break
          }
        }

        for (let i = 0; i < this.dynamicSicList.length; i++) {
          if (
            isEqual(
              this.clientData.sic.value.value,
              this.dynamicSicList[i].value
            )
          ) {
            foundSic = true
            break
          }
        }

        if (foundNace) {
          this.clientRadioFields.nace.radioDisabled = false
        } else {
          this.clientRadioFields.nace.radioDisabled = true
        }

        if (foundSic) {
          this.clientRadioFields.sic.radioDisabled = false
        } else {
          this.clientRadioFields.sic.radioDisabled = true
        }

        let foundSecondaryNaics = true
        for (let i = 0; i < this.dynamicSecondaryNaicsList.length; i++) {
          if (
            isEqual(
              this.clientData.secondaryNaics.value.value,
              this.dynamicSecondaryNaicsList[i].value
            )
          ) {
            foundSecondaryNaics = true
            break
          }
        }

        if (foundSecondaryNaics) {
          this.clientRadioFields.secondaryNaics.radioDisabled = false
        } else {
          this.clientRadioFields.secondaryNaics.radioDisabled = true
        }

        let foundSecondaryNace = true
        for (let i = 0; i < this.dynamicSecondaryNaceList.length; i++) {
          if (
            isEqual(
              this.clientData.secondaryNace.value.value,
              this.dynamicSecondaryNaceList[i].value
            )
          ) {
            foundSecondaryNace = true
            break
          }
          this.clientRadioFields.secondaryNace.radioDisabled = true
        }

        if (foundSecondaryNace) {
          this.clientRadioFields.secondaryNace.radioDisabled = false
        } else {
          this.clientRadioFields.secondaryNace.radioDisabled = true
        }
      }

      this.isClientDataLoaded = true
    },

    computedBvdData() {
      this.bvdRadioFields = cloneDeep(this.computedBvdData)

      if (!isEmpty(this.bvdRadioFields) && !isEmpty(this.computedBvdData)) {
        for (let i = 0; i < this.computedBvdData.naics.length; i++) {
          let foundNaics = false
          for (let j = 0; j < this.dynamicNaicsList.length; j++) {
            if (
              isEqual(
                this.computedBvdData.naics[i].value.value,
                this.dynamicNaicsList[j].value
              )
            ) {
              foundNaics = true
              break
            }
          }
          if (foundNaics) {
            this.bvdRadioFields.naics[i].radioDisabled = false
          } else {
            this.bvdRadioFields.naics[i].radioDisabled = true
            for (let i = 0; i < this.computedBvdData.nace.length; i++) {
              this.bvdRadioFields.nace[i].radioDisabled = true
            }
            for (let i = 0; i < this.computedBvdData.sic.length; i++) {
              this.bvdRadioFields.sic[i].radioDisabled = true
            }
          }
        }

        for (let i = 0; i < this.computedBvdData.secondaryNaics.length; i++) {
          let foundSecondaryNaics = false
          for (let j = 0; j < this.dynamicSecondaryNaicsList.length; j++) {
            if (
              isEqual(
                this.computedBvdData.secondaryNaics[i].value.value,
                this.dynamicSecondaryNaicsList[j].value
              )
            ) {
              foundSecondaryNaics = true
              break
            }
          }

          if (foundSecondaryNaics) {
            this.bvdRadioFields.secondaryNaics[i].radioDisabled = false
          } else {
            this.bvdRadioFields.secondaryNaics[i].radioDisabled = true
          }
        }

        for (let i = 0; i < this.computedBvdData.secondaryNace.length; i++) {
          this.bvdRadioFields.secondaryNace[i].radioDisabled = true
        }
      }

      this.isBvdDataLoaded = true
    },

    selectAllToggleCheck() {
      if (
        Object.keys(this.selectedClientValues).length ===
        this.industryFieldList.length
      ) {
        this.selectAllClient = true
      } else {
        this.selectAllClient = false
      }
      if (
        Object.keys(this.selectedBVDFields).length ===
        this.industryFieldList.length
      ) {
        this.selectAllBVD = true
      } else {
        this.selectAllBVD = false
      }
    },

    dynamicSecondaryNaceList() {
      if (this.dynamicSecondaryNaceList.length > 0) {
        if (!isEmpty(this.computedBvdData)) {
          for (let i = 0; i < this.computedBvdData.secondaryNace.length; i++) {
            let foundsecondaryNace = false
            for (let j = 0; j < this.dynamicSecondaryNaceList.length; j++) {
              if (
                isEqual(
                  this.computedBvdData.secondaryNace[i].value.value,
                  this.dynamicSecondaryNaceList[j].value
                )
              ) {
                foundsecondaryNace = true
                break
              }
            }

            if (foundsecondaryNace) {
              this.bvdRadioFields.secondaryNace[i].radioDisabled = false
            } else {
              this.bvdRadioFields.secondaryNace[i].radioDisabled = true
            }
          }
        }

        if (!isEmpty(this.clientData)) {
          let foundSecondaryNace = false
          for (let i = 0; i < this.dynamicSecondaryNaceList.length; i++) {
            if (
              isEqual(
                this.clientData.secondaryNace.value.value,
                this.dynamicSecondaryNaceList[i].value
              )
            ) {
              foundSecondaryNace = true
              break
            }
            this.clientRadioFields.secondaryNace.radioDisabled = true
          }
          if (foundSecondaryNace) {
            this.clientRadioFields.secondaryNace.radioDisabled = false
          } else {
            this.clientRadioFields.secondaryNace.radioDisabled = true
          }
        }
      }
    },

    dynamicNaceList() {
      if (this.dynamicNaceList.length > 0 && this.fromBvdSelect) {
        if (!isEmpty(this.computedBvdData)) {
          for (let i = 0; i < this.computedBvdData.nace.length; i++) {
            let foundNace = false
            for (let j = 0; j < this.dynamicNaceList.length; j++) {
              if (
                isEqual(
                  this.computedBvdData.nace[i].value.value,
                  this.dynamicNaceList[j].value
                )
              ) {
                foundNace = true
                break
              }
            }

            if (foundNace) {
              this.bvdRadioFields.nace[i].radioDisabled = false
            } else {
              this.bvdRadioFields.nace[i].radioDisabled = true
            }
          }
        }

        // if (!isEmpty(this.clientData)) {
        //   let foundNace = false
        //   for (let i = 0; i < this.dynamicNaceList.length; i++) {
        //     if (
        //       isEqual(
        //         this.clientData.nace.value.value,
        //         this.dynamicNaceList[i].value
        //       )
        //     ) {
        //       foundNace = true
        //       break
        //     }
        //     this.clientRadioFields.nace.radioDisabled = true
        //   }
        //   if (foundNace) {
        //     this.clientRadioFields.nace.radioDisabled = false
        //   } else {
        //     this.clientRadioFields.nace.radioDisabled = true
        //   }
        // }
      }
    },

    dynamicSicList() {
      if (this.dynamicSicList.length > 0 && this.fromBvdSelect) {
        if (!isEmpty(this.computedBvdData)) {
          for (let i = 0; i < this.computedBvdData.sic.length; i++) {
            let foundSic = false
            for (let j = 0; j < this.dynamicSicList.length; j++) {
              if (
                isEqual(
                  this.computedBvdData.sic[i].value.value,
                  this.dynamicSicList[j].value
                )
              ) {
                foundSic = true
                break
              }
            }

            if (foundSic) {
              this.bvdRadioFields.sic[i].radioDisabled = false
            } else {
              this.bvdRadioFields.sic[i].radioDisabled = true
            }
          }
        }

        // if (!isEmpty(this.clientData)) {
        //   let foundSic = false
        //   for (let i = 0; i < this.dynamicSicList.length; i++) {
        //     if (
        //       isEqual(
        //         this.clientData.sic.value.value,
        //         this.dynamicSicList[i].value
        //       )
        //     ) {
        //       foundSic = true
        //       break
        //     }
        //     this.clientRadioFields.sic.radioDisabled = true
        //   }
        //   if (foundSic) {
        //     this.clientRadioFields.sic.radioDisabled = false
        //   } else {
        //     this.clientRadioFields.sic.radioDisabled = true
        //   }
        // }
      }
    },

    clientRadioFields: {
      handler() {
        this.deselectDisabledRadioField(
          this.clientRadioFields,
          this.selectedClientValues
        )
      },
      deep: true
    },

    bvdRadioFields: {
      handler() {
        this.deselectDisabledRadioField(
          this.bvdRadioFields,
          this.selectedBVDFields
        )
      },
      deep: true
    },

    isSelectAllEnabled() {
      if (this.isSelectAllEnabled) {
        this.selectAllClient = true
        this.toggleAllClient()
      }
    }
  },

  async mounted() {
    if (this.isNotHoldingCompanyDisabled) {
      for (let i = 0; i < this.industryFieldList.length; i++) {
        if (
          this.industryFieldList[i] === 'secondaryNaics' ||
          this.industryFieldList[i] === 'secondaryNace'
        ) {
          this.fieldNameList.splice(0, 0, this.industryFieldList[i])
          this.industryFieldList.splice(i, 1)
        }
      }
    }
  },

  methods: {
    ...mapActions('industry', [
      'updateIndustryDetailState',
      'resetIndustryVariables',
      'getNaceList',
      'getSicList',
      'updateNaceList',
      'updateSicList',
      'updateSecondaryNaceList',
      'getSecondaryNaceList',
      'copyDropdownListToDynamicForm'
    ]),

    ...mapActions('evidence', ['updateIsBVD', 'checkBVDEvidence']),

    computeClientData() {
      const ech = this.bvdData?.entityChangeHistory
      if (ech) {
        const formattedData = {
          nace: this.formatClientDataField(ech.naceCode, ech.naceName),
          naics: this.formatClientDataField(ech.naicsCode, ech.naicsName),
          sic: this.formatClientDataField(ech.sicCode, ech.sicName),
          secondaryNaics: this.formatClientDataField(
            ech.secondaryNaicsCode,
            ech.secondaryNaicsName
          ),
          secondaryNace: this.formatClientDataField(
            ech.secondaryNaceCode,
            ech.secondaryNaceName
          )
        }
        return formattedData
      } else {
        return null
      }
    },

    computeBvdData() {
      const bvd = this.bvdData?.bvd
      if (bvd) {
        const naics = bvd.naics.map((naics) =>
          this.formatBVDDataField(naics.code, naics.label)
        )
        const nace = bvd.nace.map((nace) =>
          this.formatBVDDataField(nace.code, nace.label)
        )
        const sic = bvd.sic.map((sic) =>
          this.formatBVDDataField(sic.code, sic.label)
        )
        const secondaryNaics = bvd.secondaryNaics.map((secondaryNaics) =>
          this.formatBVDDataField(secondaryNaics.code, secondaryNaics.label)
        )
        const secondaryNace = bvd.secondaryNace.map((secondaryNace) =>
          this.formatBVDDataField(secondaryNace.code, secondaryNace.label)
        )
        const formattedData = {
          nace: nace,
          naics: naics,
          sic: sic,
          secondaryNaics: secondaryNaics,
          secondaryNace: secondaryNace,
          productsAndServices: bvd.productsAndServices,
          tradeDescription: bvd.tradeDescription
        }
        return formattedData
      } else {
        return null
      }
    },

    toggleAllClient() {
      if (this.selectAllClient) {
        this.selectAllBVD = false
        this.selectedBVDFields = {}
        const fromSelectAll = true
        const isClient = true
        this.industryFieldList.forEach((fieldName) => {
          if (
            this.clientData[`${fieldName}`].value &&
            !this.clientRadioFields[`${fieldName}`].radioDisabled
          ) {
            this.selectRadio(
              fieldName,
              this.clientData[`${fieldName}`],
              isClient,
              fromSelectAll
            )
          }
        })
      } else {
        this.selectedClientValues = {}
      }
    },

    toggleAllBVD() {
      if (this.selectAllBVD) {
        this.selectAllClient = false
        this.selectedClientValues = {}
        this.industryFieldList.forEach((fieldName) => {
          this.selectRadio(
            fieldName,
            Array.isArray(this.computedBvdData[`${fieldName}`])
              ? this.computedBvdData[`${fieldName}`][
                  this.computedBvdData[`${fieldName}`].length - 1 // index
                ]
              : this.computedBvdData[`${fieldName}`],
            false
          )
        })
      } else {
        this.selectedBVDFields = {}
      }
    },

    async selectRadio(key, obj, isClient, fromSelectAll = false) {
      if (isClient) {
        this.$set(this.selectedClientValues, key, obj.value)

        if (!fromSelectAll) {
          const selectedNaics = this.pickKeyPair(this.selectedClientValues, [
            'naics'
          ])

          if (!isEmpty(selectedNaics)) {
            this.isUpdateBtnReady = false
            await this.getNaceAndSicList(selectedNaics.naics.value)
            this.isUpdateBtnReady = true
          }

          const selectedSecondaryNaics = this.pickKeyPair(
            this.selectedClientValues,
            ['secondaryNaics']
          )
          if (!isEmpty(selectedSecondaryNaics)) {
            this.updateSecondaryNaceList({
              secondaryNaceList: [],
              isDynamic: this.isDynamic
            })
            this.isUpdateBtnReady = false
            await this.getSecondaryNaceList({
              naicsId: selectedSecondaryNaics.secondaryNaics.value,
              isDynamic: this.isDynamic
            })
            this.isUpdateBtnReady = true
          }
        }

        if (!isEmpty(this.selectedBVDFields[key])) {
          this.$delete(this.selectedBVDFields, key)
        }
      } else {
        this.$set(this.selectedBVDFields, key, obj.value)

        const selectedNaics = this.pickKeyPair(this.selectedBVDFields, [
          'naics'
        ])
        this.fromBvdSelect = false // initial value
        if (!isEmpty(selectedNaics) && !this.fromBvdSelect) {
          this.isUpdateBtnReady = false

          this.fromBvdSelect = true
          await this.getNaceAndSicList(selectedNaics.naics.value)
          this.isUpdateBtnReady = true
        }

        const selectedSecondaryNaics = this.pickKeyPair(
          this.selectedBVDFields,
          ['secondaryNaics']
        )
        if (!isEmpty(selectedSecondaryNaics)) {
          this.updateSecondaryNaceList({
            secondaryNaceList: [],
            isDynamic: this.isDynamic
          })

          this.isUpdateBtnReady = false
          await this.getSecondaryNaceList({
            naicsId: selectedSecondaryNaics.secondaryNaics.value,
            isDynamic: this.isDynamic
          })
          this.isUpdateBtnReady = true
        }

        if (!isEmpty(this.selectedClientValues[key])) {
          this.$delete(this.selectedClientValues, key)
        }
      }

      // toggle select all checkbox
      this.selectAllToggleCheck = !this.selectAllToggleCheck

      // toggle bvd flag
      // if (!isEmpty(this.selectedBVDFields)) {
      //   this.updateIsBVD(true)
      // } else {
      //   this.updateIsBVD(false)
      // }
    },

    updateDynamicFormOutputData(selectedClientValues, selectedBVDFields) {
      const collectedField = { ...selectedBVDFields, ...selectedClientValues }
      const collectedFieldNameList = Object.keys(collectedField)
      // field name that are not radio selected
      let fieldToBeCollectedFromIndustry = [
        'naics',
        'secondaryNaics',
        'nace',
        'secondaryNace',
        'sic'
      ].filter((element) => !collectedFieldNameList.includes(element))

      fieldToBeCollectedFromIndustry = [
        ...fieldToBeCollectedFromIndustry,
        ...this.fieldNameList
      ]

      const fieldFromIndustryDetail = this.pickKeyPair(
        this.industryDetailCopy,
        fieldToBeCollectedFromIndustry
      )

      this.dynamicFormOutputData = {
        ...fieldFromIndustryDetail,
        ...collectedField
      }
    },

    pickKeyPair(industryDetail, fieldNameList) {
      return fieldNameList.reduce(
        (industryFieldCollected, industryField) => (
          industryField in industryDetail &&
            (industryFieldCollected[industryField] =
              industryDetail[industryField]),
          industryFieldCollected
        ),
        {}
      )
    },

    async onSavingDynamicForm() {
      this.updateDynamicFormOutputData(
        this.selectedClientValues,
        this.selectedBVDFields
      )
      this.resetIndustryVariables()
      if (!isEmpty(this.selectedBVDFields)) {
        // const bvdEvidenceId = await this.checkBVDEvidence({
        //   inputName: this.INDUSTRY_DETAILS_LABEL,
        //   bvdId: this.bvdData?.bvdIdNumber ? this.bvdData.bvdIdNumber : '',
        //   updatedAt: '08022024'
        // })
        // if (bvdEvidenceId) {
        //   this.resetIndustryVariables()
        this.dynamicFormOutputData = {
          ...this.dynamicFormOutputData,
          businessNature: isEmpty(this.businessNature)
            ? null
            : this.businessNature,
          industryEvdId: null
        }
        //  this.dynamicFormOutputData = {
        //    ...this.dynamicFormOutputData,
        //    businessNature: this.businessNature,
        //    industryEvdId: bvdEvidenceId
        //  }
        this.updateIndustryDetailState(this.dynamicFormOutputData)
        //   // this.updateIsBVD(true)
        // }
      } else {
        this.dynamicFormOutputData = {
          ...this.dynamicFormOutputData,
          businessNature: isEmpty(this.businessNature)
            ? null
            : this.businessNature,
          industryEvdId: null
        }
        this.updateIndustryDetailState(this.dynamicFormOutputData)
        // this.updateIsBVD(false)
      }
    },

    formatClientDataField(code, name) {
      return {
        value:
          code && name
            ? { text: `${code} - ${name.trim()}`, value: `${code}` }
            : '',
        radioDisabled: false
      }
    },

    formatBVDDataField(code, label) {
      return {
        value:
          code && label
            ? { text: `${code} - ${label.trim()}`, value: `${code}` }
            : '',
        radioDisabled: true
      }
    },

    async getNaceAndSicList(naicsValue) {
      // resetting the existing nace and sic list in store
      this.updateNaceList({ naceList: [], isDynamic: this.isDynamic })
      this.updateSicList({ sicList: [], isDynamic: this.isDynamic })

      this.isUpdateBtnReady = false
      // make api call to get a list of nace and sic
      await this.getNaceList({
        naicsId: naicsValue,
        isDynamic: this.isDynamic
      })
      await this.getSicList({ naicsId: naicsValue, isDynamic: this.isDynamic })
      this.isUpdateBtnReady = true
    },

    onReset() {
      this.selectAllClient = true
      this.toggleAllClient()
    },

    isRadioSelected(selectedField, originalField) {
      return selectedField ? selectedField.value === originalField.value : false
    },

    deselectDisabledRadioField(radioFieldObj, selectedRadioFieldObj) {
      for (const key in radioFieldObj) {
        if (isArray(radioFieldObj[key])) {
          for (let i = 0; i < radioFieldObj[key].length; i++) {
            if (
              radioFieldObj[key][i].radioDisabled &&
              selectedRadioFieldObj[key] &&
              isEqual(
                radioFieldObj[key][i].value.value,
                selectedRadioFieldObj[key].value
              )
            ) {
              this.$delete(selectedRadioFieldObj, key)
            }
          }
        } else if (
          radioFieldObj[key].radioDisabled &&
          selectedRadioFieldObj[key]
        ) {
          this.$delete(selectedRadioFieldObj, key)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dynamic-form {
  max-width: 100%;
  margin: 0 auto;
}

.attr-radio::v-deep,
.attr-checkbox::v-deep {
  input {
    border: 1px solid #707070;
  }
}

table {
  border: 1px solid #cccccc;
  margin: 0.5em;
  width: 100%;
  font-size: 12px;

  thead {
    tr {
      th {
        padding: 0.3em;
        color: #7d7d7d;
      }
    }
  }

  tbody {
    tr,
    .identifier-row {
      td {
        padding: 0.3em;
        color: #1e1e1e;
      }
      td:first-child {
        vertical-align: middle;
      }
    }
    .identifier-row {
      td {
        vertical-align: middle;
      }
    }
  }
}

.bvd-container {
  padding: 0.5em;

  .title-row::v-deep {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 49%;

      .title {
        font-size: 22px;
      }
    }

    .title-container:first-child {
      width: 45%;
    }
    .title-container:last-child {
      width: 53%;
    }
    .client {
      .title {
        color: #00218a;
      }
      .k-checkbox:checked {
        background-color: #00218a;
        border-color: transparent;
        box-shadow: none;
      }
    }
    .bvd {
      .title {
        color: #f57777;
      }
      .k-checkbox:checked {
        background-color: #f57777;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .button-container {
    justify-content: end;
    .save-buttons {
      width: 15%;
      display: flex;
      justify-content: space-around;
    }
  }
}
.attribute-container {
  .attribute-item::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .client-attributes,
    .bvd-attributes,
    .identifier-client-attr,
    .bvd-identifier-section {
      width: 49%;
      display: flex;
      flex-direction: row;
      padding-bottom: 1em;
      background-color: #f9f9f9;
      align-items: center;
      line-height: 1.7em;
      border: 1px solid #cccccc;
      border-width: 0 1px;

      .radio-group {
        padding: 1em;
      }

      .label-group {
        display: flex;
        flex-direction: column;

        .attr-title {
          color: #7d7d7d;
          font-size: 12px;
        }

        .value {
          color: #1e1e1e;
          font-size: 13px;
        }
      }
      .attr-radio-td {
        display: inline;
      }
    }

    .bvd-attributes-read-only {
      padding-left: 48px;
    }

    .bvd-group-attributes {
      display: flex;
      flex-direction: row;
    }

    .grouping {
      flex-direction: column;
      align-items: start;
      border: 1px solid #cccccc;
      padding-top: 10px;
    }

    .group-title {
      font-size: 16px;
      color: #7d7d7d;
      padding-left: 10px;
    }

    .client-attributes,
    .client-identifier-section {
      width: 45%;
      table {
        line-height: 3.6em;
      }

      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #00218a;
        box-shadow: none;
      }
    }

    .bvd-attributes,
    .bvd-identifier-section {
      width: 53%;
      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #f57777;
        box-shadow: none;
      }
    }
  }

  .attribute-item:first-child {
    .client-attributes,
    .bvd-attributes {
      border-top: 1px solid #cccccc;
      padding-top: 1em;
    }
  }
  .attribute-item:last-child {
    .client-attributes,
    .bvd-attributes {
      border-bottom: 1px solid #cccccc;
    }
  }

  .attribute-item:nth-last-child(2) {
    .bvd-attributes {
      padding-top: 10px;
    }
  }

  .attribute-item {
    .client-identifier-section {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 48%;
      .identifier-client-attr {
        width: 100%;
        height: 100%;
      }
    }
  }

  .no-client-attributes,
  .no-bvd-attributes {
    justify-content: center;
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  height: 5rem;
  padding-bottom: 0.6rem;
  width: 100%;
  gap: 1rem;

  .action-button {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    min-width: 104px;
    height: 46px;
  }

  .confirm-button {
    background-color: #535eeb;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: left;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
  }

  .confirm-button:hover {
    background-color: #00218a;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    opacity: 0.9;
  }

  .cancel-button {
    background-color: #cccccc;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;
  }

  .cancel-button:hover {
    background-color: #cccccc;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    opacity: 0.9;
  }
}

.no-data-message {
  text-align: center;
  padding: 20px;
  color: #291818;
  font-style: italic;
}
</style>
