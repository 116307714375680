<template>
  <div class="bvd-container" v-if="hasClientData || hasBVDData">
    <div class="title-row">
      <div class="title-container">
        <div class="title client">Client Attributes</div>
        <!-- <label class="select-all">
          <input
            type="checkbox"
            v-model="selectAllClient"
            :checked="selectAllClient"
            @change="toggleAllClient"
          />
          <span>Select All</span>
        </label> -->
      </div>
      <div class="title-container">
        <div class="title bvd">
          BVD Attributes
          <span v-if="attributesData?.bvdIdNumber"
            >({{ attributesData.bvdIdNumber }})</span
          >
        </div>
        <!-- <label class="select-all">
          <input
            type="checkbox"
            v-model="selectAllBVD"
            :checked="selectAllBVD"
            @change="toggleAllBVDDetails"
          />
          <span>Select All</span>
        </label> -->
      </div>
    </div>
    <div class="attribute-container">
      <div
        class="attribute-item"
        v-for="(detail, key) in BVDAddressDetails"
        :key="key"
      >
        <div class="client-attributes" v-if="hasClientData">
          <div class="radio-group">
            <RadioButton
              type="radio"
              :name="`${key}-client`"
              :value="detail.client"
              :checked="detail.isClientSelectable"
              :disabled="detail.isClientDisable"
              :class="'attr-radio'"
              v-if="detail.type === 'radio'"
              class="kendo-radio-button"
              @change="() => handleRadioButtonChange(key, 'client')"
            ></RadioButton>
          </div>
          <div class="label-group">
            <label class="attr-title">{{ detail.title }}</label>
            <label class="value">
              {{
                typeof detail.client === 'object'
                  ? detail.client?.text
                  : detail.client
              }}</label
            >
          </div>
        </div>
        <div class="client-attributes no-client-attributes" v-else>
          <p v-if="detail.title === 'Address'">No data available.</p>
        </div>
        <div class="bvd-attributes" v-if="hasBVDData">
          <div class="radio-group">
            <RadioButton
              type="radio"
              :name="`${key}-bvd`"
              :value="detail.bvd"
              :checked="detail.isBvdSelectable"
              v-if="detail.type === 'radio'"
              :disabled="detail.isBVDDisabled"
              @change="() => handleRadioButtonChange(key, 'bvd')"
              class="kendo-radio-button"
              :class="'attr-radio'"
            ></RadioButton>
          </div>
          <div class="label-group">
            <label class="attr-title">{{ detail.title }}</label>
            <label class="value">
              {{
                typeof detail.bvd === 'object' ? detail.bvd?.text : detail.bvd
              }}</label
            >
          </div>
        </div>
        <div class="bvd-attributes no-bvd-attributes" v-else>
          <p v-if="detail.title === 'Address'">No data available.</p>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button class="cancel-button" @click="resetBVDForm()">Reset</button>
      <BVDModal
        field="both"
        title="Copy Both"
        @copyDataToAddress="copyDataToAddress"
        :selected="selected"
        :selectedKey="selectedKey"
      ></BVDModal>
      <BVDModal
        field="registered"
        title="Save to Registered"
        @copyDataToAddress="copyDataToAddress"
        :selected="selected"
        :selectedKey="selectedKey"
      ></BVDModal>
      <BVDModal
        field="physical"
        title="Save to Physical"
        @copyDataToAddress="copyDataToAddress"
        :selectedKey="selectedKey"
      ></BVDModal>
    </div>
  </div>
  <div v-else>No data available.</div>
</template>

<script>
import { ADDRESS_KEY_MAPPING } from '../constants'
import { mapActions, mapState, mapGetters } from 'vuex'
import { RadioButton } from '@progress/kendo-vue-inputs'
import { isEmpty } from 'lodash'
import BVDModal from './BVDModal.vue'

export default {
  name: 'DynamicAddressForm',
  components: {
    RadioButton: RadioButton,
    BVDModal
  },
  props: {
    attributesData: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      BVDAddressDetails: {},
      selected: {}, // To store selected radio button values
      selectAllLeft: false, // Flag for BVD container "Select all"
      selectAllRight: false, // Flag for Client container "Select all",
      collapsed: false,
      selectedKey: {}
    }
  },
  computed: {
    ...mapState('dmp', ['country', 'countrySubDivision', 'nuts3']),
    ...mapState('evidence', ['evidenceIdList']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails']),

    hasClientData() {
      return this.attributesData?.isClientDataPresent
    },
    hasBVDData() {
      return this.attributesData?.isBvdDataPresent
    }
  },
  watch: {
    async attributesData() {
      await this.updateBVDAddressDetails()
    }
  },
  methods: {
    ...mapActions('dmp', [
      'copyFieldToAddress',
      'getStateList',
      'getNuts3List',
      'getCountryList'
    ]),

    copyDataToAddress(field, shouldOverride) {
      // Emit save event with the form data
      const obj = {}
      obj.data = this.selected
      obj.field = field
      obj.shouldOverride = shouldOverride

      this.copyFieldToAddress(obj)
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed
    },

    async handleRadioButtonChange(key, type) {
      // Update the BVDAddressDetails based on the type
      this.$set(this.BVDAddressDetails, key, {
        ...this.BVDAddressDetails[key],
        isClientSelectable: type === 'client',
        isBvdSelectable: type === 'bvd'
      })

      if (key === 'country') {
        // Handle fetching state and NUTS3 lists

        this.resetSubdivAndNuts3()
        await this.handleCountryChange(type)

        // Validate subdivision and NUTS3
        await this.validateSubDivisionAndNuts3(type)
        this.$set(this.selected, key, this.BVDAddressDetails[key]?.[type])
      } else {
        // Set the selected value for other keys
        this.$set(this.selected, key, this.BVDAddressDetails[key]?.[type])
      }
      this.getSelectedTitle()
    },
    async resetSubdivAndNuts3() {
      this.$delete(this.selected, 'subDivision')
      this.$delete(this.selected, 'nuts3')
      this.$set(this.BVDAddressDetails, 'nuts3', {
        ...this.BVDAddressDetails.nuts3,
        isClientSelectable: false,
        isBvdSelectable: false
      })
      this.$set(this.BVDAddressDetails, 'subDivision', {
        ...this.BVDAddressDetails.subDivision,
        isClientSelectable: false,
        isBvdSelectable: false
      })
    },

    async handleCountryChange(type) {
      const countryCode = this.BVDAddressDetails?.country?.[type]?.code
      const foundCountry = this.country.find(
        (c) => c.countryCode === countryCode
      )

      if (foundCountry) {
        await this.getStateList(foundCountry.value)
        await this.getNuts3List(foundCountry.value)
      }
    },

    async validateSubDivisionAndNuts3(type) {
      const isBvdType = type === 'bvd'

      // Extract values for validation
      const bvdSubdivision = this.BVDAddressDetails.subDivision.bvd.value
      const clientSubdivision = this.BVDAddressDetails.subDivision.client.value
      const bvdNuts3 = this.BVDAddressDetails.nuts3.bvd.value
      const clientNuts3 = this.BVDAddressDetails.nuts3.client.value

      // Check if subdivisions and NUTS3 codes are valid
      const isBvdSubDivisionFound = this.countrySubDivision.some(
        (subDivision) => subDivision.value === Number(bvdSubdivision)
      )
      const isClientSubDivisionFound = this.countrySubDivision.some(
        (subDivision) => subDivision.value === Number(clientSubdivision)
      )

      const isBvdNuts3 = this.nuts3.some((nuts3) => nuts3.value === bvdNuts3)
      const isClientNuts3Found = this.nuts3.some(
        (nuts3) => nuts3.value === clientNuts3
      )

      // Update subDivision based on the type
      this.updateSubDivision(
        isBvdType,
        isBvdSubDivisionFound,
        isClientSubDivisionFound
      )

      // Update nuts3 based on the type
      this.updateNuts3(isBvdType, isBvdNuts3, isClientNuts3Found)
    },

    updateSubDivision(
      isBvdType,
      isBvdSubDivisionFound,
      isClientSubDivisionFound
    ) {
      // Create a copy of the current subDivision object
      const updatedSubDivision = { ...this.BVDAddressDetails.subDivision }

      if (isBvdType) {
        // For BVD type
        if (!isBvdSubDivisionFound) {
          updatedSubDivision.isBVDDisabled = true
          updatedSubDivision.isBvdSelectable = false
        } else {
          updatedSubDivision.isBVDDisabled = false
        }

        if (!isClientSubDivisionFound) {
          updatedSubDivision.isClientDisable = true
          updatedSubDivision.isClientSelectable = false
        } else {
          updatedSubDivision.isClientDisable = false
        }
      } else {
        // For Client type
        if (!isClientSubDivisionFound) {
          updatedSubDivision.isClientDisable = true
          updatedSubDivision.isClientSelectable = false
        } else {
          updatedSubDivision.isClientDisable = false
        }

        if (!isBvdSubDivisionFound) {
          updatedSubDivision.isBVDDisabled = true
          updatedSubDivision.isBvdSelectable = false
        } else {
          updatedSubDivision.isBVDDisabled = false
        }
      }

      // Use $set to update the subDivision object in one go
      this.$set(this.BVDAddressDetails, 'subDivision', updatedSubDivision)
    },
    updateNuts3(isBvdType, isBvdNuts3, isClientNuts3Found) {
      // Create a copy of the current nuts3 object
      const updatedNuts3 = { ...this.BVDAddressDetails.nuts3 }

      if (isBvdType) {
        // For BVD type
        if (!isBvdNuts3) {
          updatedNuts3.isBVDDisabled = true
          updatedNuts3.isBvdSelectable = false
        } else {
          updatedNuts3.isBVDDisabled = false
        }

        if (!isClientNuts3Found) {
          updatedNuts3.isClientDisable = true
          updatedNuts3.isClientSelectable = false
        } else {
          updatedNuts3.isClientDisable = false
        }
      } else {
        // For Client type
        if (!isClientNuts3Found) {
          updatedNuts3.isClientDisable = true
          updatedNuts3.isClientSelectable = false
        } else {
          updatedNuts3.isClientDisable = false
        }

        if (!isBvdNuts3) {
          updatedNuts3.isBVDDisabled = true
          updatedNuts3.isBvdSelectable = false
        } else {
          updatedNuts3.isBVDDisabled = false
        }
      }

      // Set the updated nuts3 object
      this.$set(this.BVDAddressDetails, 'nuts3', updatedNuts3)
    },
    async resetBVDForm() {
      // Reset selected values to default from clientData
      this.updateBVDAddressDetails()
    },

    async updateBVDAddressDetails() {
      const bvdAddressDetails = this.attributesData.details
      if (!bvdAddressDetails) {
        return
      }
      await this.getCountryList()

      const foundCountry = await this.country.find(
        (c) => c.countryCode === bvdAddressDetails?.country?.bvd?.code
      )

      if (foundCountry) {
        await this.getStateList(foundCountry.value)
        await this.getNuts3List(foundCountry.value)
      }
      const bvdSubDivCode = await this.countrySubDivision.find(
        (item) => item.stateCode === bvdAddressDetails?.subDivision?.bvd?.code
      )
      bvdAddressDetails.country.bvd.value = foundCountry?.value
      bvdAddressDetails.subDivision.bvd.value = bvdSubDivCode?.value

      for (const key in bvdAddressDetails) {
        const detail = bvdAddressDetails[key]
        detail.type = 'radio'
        if (key === 'subDivision' || key === 'nuts3') {
          detail.isClientSelectable = false
          detail.isClientDisable = false
        } else {
          detail.isClientSelectable = true
          detail.isClientDisable = false
        }
        detail.isBVDDisabled = false
        detail.isBvdSelectable = false
      }
      this.BVDAddressDetails = bvdAddressDetails
      await this.selectAllClient()
      await this.getSelectedTitle()
    },
    async selectAllClient() {
      await this.handleCountryChange('client')
      await this.validateSubDivisionAndNuts3('client')

      for (const key in this.BVDAddressDetails) {
        const detail = this.BVDAddressDetails[key]
        if (!detail.isClientDisable) {
          detail.isClientSelectable = true
          this.selected[key] = detail.client
        }
      }
    },
    async getSelectedTitle() {
      this.selectedKey = {}
      for (const key in this.selected) {
        this.selectedKey[key] = this.BVDAddressDetails[key].title
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dynamic-form {
  max-width: 100%;
  margin: 0 auto;
}

.attr-radio::v-deep,
.attr-checkbox::v-deep {
  input {
    border: 1px solid #707070;
  }
}

table {
  border: 1px solid #cccccc;
  margin: 0.5em;
  width: 100%;
  font-size: 12px;

  thead {
    tr {
      th {
        padding: 0.3em;
        color: #7d7d7d;
      }
    }
  }

  tbody {
    tr,
    .identifier-row {
      td {
        padding: 0.3em;
        color: #1e1e1e;
      }
      td:first-child {
        vertical-align: middle;
      }
    }
    .identifier-row {
      td {
        vertical-align: middle;
      }
    }
  }
}

.bvd-container {
  padding: 1em;

  .title-row::v-deep {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 49%;
      .client {
        color: #00218a;
      }
      .bvd {
        color: #f57777;
      }

      .title {
        font-size: 22px;
      }
    }

    .title-container:first-child {
      width: 45%;
    }
    .title-container:last-child {
      width: 53%;
    }
    .client {
      .title {
        color: #00218a;
      }
      .k-checkbox:checked {
        background-color: #00218a;
        border-color: transparent;
        box-shadow: none;
      }
    }
    .bvd {
      .title {
        color: #f57777;
      }
      .k-checkbox:checked {
        background-color: #f57777;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .button-container {
    justify-content: end;
    .save-buttons {
      width: 15%;
      display: flex;
      justify-content: space-around;
    }
  }
}
.attribute-container {
  .attribute-item::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .client-attributes,
    .bvd-attributes,
    .identifier-client-attr,
    .bvd-identifier-section {
      width: 49%;
      display: flex;
      flex-direction: row;
      padding-bottom: 1em;
      background-color: #f9f9f9;
      align-items: center;
      line-height: 1.7em;
      border: 1px solid #cccccc;
      border-width: 0 1px;

      .radio-group {
        padding: 1em;
      }

      .label-group {
        display: flex;
        flex-direction: column;

        .attr-title {
          color: #7d7d7d;
          font-size: 12px;
        }

        .value {
          color: #1e1e1e;
          font-size: 13px;
        }
      }
      .attr-radio-td {
        display: inline;
      }
    }

    .bvd-attributes-read-only {
      padding-left: 48px;
    }

    .bvd-group-attributes {
      display: flex;
      flex-direction: row;
    }

    .grouping {
      flex-direction: column;
      align-items: start;
      border: 1px solid #cccccc;
      padding-top: 10px;
    }

    .group-title {
      font-size: 16px;
      color: #7d7d7d;
      padding-left: 10px;
      margin-bottom: 10px;
    }

    .client-attributes,
    .client-identifier-section {
      width: 45%;
      table {
        line-height: 3.6em;
      }

      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #00218a;
        box-shadow: none;
      }
    }

    .bvd-attributes,
    .bvd-identifier-section {
      width: 53%;
      .k-radio:checked,
      .k-checkbox:checked {
        background-color: #f57777;
        box-shadow: none;
      }
    }
  }

  .attribute-item:first-child {
    .client-attributes,
    .bvd-attributes {
      border-top: 1px solid #cccccc;
      padding-top: 1em;
    }
  }
  .attribute-item:last-child {
    .client-attributes,
    .bvd-attributes {
      border-bottom: 1px solid #cccccc;
    }
  }

  .attribute-item:nth-last-child(2) {
    .bvd-attributes {
      padding-top: 10px;
    }
  }

  .attribute-item {
    .client-identifier-section {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 48%;
      .identifier-client-attr {
        width: 100%;
        height: 100%;
      }
    }
  }

  .no-client-attributes,
  .no-bvd-attributes {
    justify-content: center;
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 1rem;

  .cancel-button {
    background: #cccccc;
    font: 14px Quicksand;
    border-radius: 8px;
    opacity: 1;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    cursor: pointer;
    opacity: 1;
    border: none;
  }

  .button {
    background: #535eeb 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
    opacity: 1;
    text-align: left;
    font: normal normal 600 14px/17px Quicksand;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
  }
}

.no-data-message {
  text-align: center;
  padding: 20px;
  color: #291818;
  font-style: italic;
}
</style>
