<template>
  <div class="form-group upload-container">
    <p>{{ label }}<sup v-if="required" class="required">*</sup></p>
    <b-upload
      :disabled="disabled"
      accept=".png,.jpg,.jpeg"
      :value="localFile"
      @input="changeInput"
      class="file-label form-control"
      :class="{
        'is-invalid': submitted
      }"
      :multiple="multiple"
      :drag-drop="dragDrop"
      :expanded="expanded">
      <section class="">
        <div class="content upload-content">
          <p class="upload-icon-custom">
            <b-icon icon="plus" size="is-medium"> </b-icon>
          </p>
          <p>Click to upload or drag & drop</p>
        </div>
      </section>
    </b-upload>
    <div v-if="required && submitted" class="required-error">
      {{ label }} is required.
    </div>
  </div>
</template>

<script>
/** This component contains code that allows for users to upload files and images to the iNava platform */
export default {
  name: 'UploadBox',
  // props: ['label', 'image', 'onChangeFiles', 'required', 'submitted', 'disabled'],
  props: {
    /** This prop contains the text for the label of the upload button */
    label: String,
    /** This prop defines the behavior for when a change is detected in the input */
    onChangeFiles: Function,
    /** This prop indicates whether or not the upload option is required or not */
    required: Boolean,
    /** This prop indicates whether a form submission has been attempted or not */
    submitted: Boolean,
    /** This prop indicates whether the upload option is available or not */
    disabled: Boolean,
    /** This prop indicates whether the drag and drop option is available or not */
    dragDrop: {
      type: Boolean,
      default () {
        return false
      }
    },
    /** This prop indicates whether the multiple upload option is available or not */
    multiple: {
      type: Boolean,
      default () {
        return false
      }
    },
    /** This prop indicates whether the upload size is full width */
    expanded: {
      type: Boolean,
      default () {
        return false
      }
    },
    /** This prop used for handling the files */
    localFile: Array
  },

  methods: {
    changeInput (files) {
      this.$emit('onChangeFiles', files)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  .required-error {
    color: red;
    position: absolute;
    font-size: 12px;
  }
  p {
    display: flex;
    margin: 0px;
    display: flex;
    margin: 0px;
    color: #7f8fa4;
    font-size: 12px;
    font-family: Quicksand;
    padding: 0px 20px 0px 0px;
    display: flex;
    line-height: 20px;
  }
  .required {
    color: red;
  }
  .upload {
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 4px;
    box-shadow: none;
    img {
      width: 200px;
      height: 60px;
      object-fit: contain;
      padding: 5px;
    }
  }

  .upload-icon-custom {
    padding: 10px 5px 0px 5px;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3;
    width: 35px;
    height: 35px;
    margin-right: 14px;
  }

  .upload-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
  }
}
</style>
<style lang="scss">
.upload-container {
  .upload .upload-draggable {
    border: none;
  }

  .upload {
    border: var(--tertiary-border-color);
    color: var(--secondary-text-color);
    background: #ffffff;
  }
}
</style>
