<template>
  <div>
    <div
      v-if="btnLoading && formName === 'Relationship'"
      class="local-loader-container"
    >
      <div class="inava-loader"></div>
    </div>

    <div v-if="hasEvidenceButton" class="evidence-button-container">
      <b-button
        :disabled="isDisabled"
        :loading="btnLoading"
        size="is-small"
        class="evidence-button"
        :class="[
          evidenceFormStatus ? 'view-evidence-button' : 'add-evidence-button'
        ]"
      >
        <span v-if="!evidenceFormStatus"> Add Evidence </span>
        <span v-else> View Evidence </span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddEvidence',
  components: {},
  props: {
    hasEvidenceButton: Boolean,
    evidenceFormStatus: Boolean,
    isDisabled: {
      type: Boolean,
      default: true
    },
    btnLoading: {
      type: Boolean,
      default: false
    },
    formName: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.evidence-button-container {
  .evidence-button {
    background-color: #fff;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.16px;
    color: #cccccc;
    opacity: 1;
    height: 36px;
    width: 114px;
  }

  .view-evidence-button {
    background-color: #e1e3ff;
    color: #535eeb;
  }

  .evidence-button:not([disabled]) {
    background-color: #e1e3ff;
    color: #535eeb;
  }
}

.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  background: rgba(0, 0, 0, 0.4);
  z-index: 9;
  .inava-loader {
    position: relative;
    display: flex;
    margin: auto;
    border-radius: 50%;
    border: none;
    z-index: 900;
    width: 70px;
    height: 70px;
    background-image: '@/assets/Inava-logo-GIF.gif';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .evidence-button-container {
    .add-evidence-button {
      font: normal normal 14px/20px Quicksand;
      font-weight: 600;
    }
  }
}
@media (min-width: 1700px) {
  .evidence-button-container {
    .add-evidence-button {
      font: normal normal 16px/20px Quicksand;
      font-weight: 600;
    }
  }
}
</style>
