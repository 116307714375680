<!-- CollapsibleSection.vue -->
<template>
  <div class="collapsible-section" v-if="showCollapsibleSection">
    <div class="section-title" :class="{ 'collapsed-title': isCollapsed }">
      <p>{{ title }}</p>

      <div class="toggle-button-container">
        <loader
          v-if="loading"
          :size="'medium'"
          :theme-color="'light'"
          :type="'infinite-spinner'"
        />

        <button class="collapse-button" @click="toggleCollapse">
          <span v-if="isCollapsed">
            <span class="k-icon k-i-arrow-60-down icon-size"></span>
          </span>
          <span v-else>
            <span class="k-icon k-i-arrow-60-up icon-size"></span>
          </span>
        </button>
      </div>
    </div>
    <section v-show="!isCollapsed" class="section-content">
      <slot></slot>
    </section>
  </div>
</template>

<script>
import { Loader } from '@progress/kendo-vue-indicators'

export default {
  name: 'CollapsibleSection',
  components: {
    loader: Loader
  },
  props: {
    title: {
      type: String,
      required: true
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    showCollapsibleSection: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Internal state to manage collapsed state within the component
      isCollapsed: this.collapsed
    }
  },
  watch: {
    // Watcher to sync prop changes with internal state
    collapsed: {
      immediate: true,
      handler(newVal) {
        this.isCollapsed = newVal
      }
    }
  },
  created() {
    // Initialize internal state based on the prop value
    this.isCollapsed = this.collapsed
  },
  methods: {
    // Method to toggle collapsed state and emit the change to parent
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible-section {
  .section-title {
    text-align: left;
    font: normal normal 14px/18px Quicksand;
    font-weight: 500;
    letter-spacing: 0px;
    color: #888888;
    opacity: 1;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
  }
  .collapse-button {
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
  }
  .collapsed-title {
    background: #dfdfdf 0% 0% no-repeat padding-box;
  }
  .icon-size {
    font-size: 24px;
    color: #7d7d7d;
  }
  .section-content {
    border: 1px solid #c4c8f8;
    padding: 10px;
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .toggle-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .k-loader {
      color: #7d7d7d;
    }
  }
  .collapsible-section {
    .section-title {
      font: normal normal 15px/21px Quicksand;
    }
  }
}
@media (min-width: 1700px) {
  .collapsible-section {
    .section-title {
      font: normal normal 19px/24px Quicksand;
    }
  }
}
</style>
