<template>
  <div class="bvd-modal">
    <span>
      <button class="button" @click="toggleDialog">{{ title }}</button>
    </span>
    <k-dialog v-if="visibleDialog" :title="title" @close="toggleDialog">
      <p :style="{ marginLeft: '25px', width: '35vw' }">
        Once YES is clicked, it will clear the existing filled attributes.<br/>
        Once NO is clicked, it will NOT clear the existing filled attributes. </p>
      <!-- {{selectedKey  }} -->
      <dialog-actions-bar>
        <button class="button" @click="copyDatatoTheAddress(true)">No</button>
        <button
          class="button"
          :theme-color="'primary'"
          @click="copyDatatoTheAddress(false)"
        >
          Yes
        </button>
      </dialog-actions-bar>
    </k-dialog>
  </div>
</template>
<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { Button } from '@progress/kendo-vue-buttons'

export default {
  name: 'BVDAddressPopup',
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
    kbutton: Button
  },
  props: {
    field: {
      type: String
    },
    title: {
      type: String
    },
    selectedKey: {
      type: Object
    },
    copyDataToAddress: {
      type: Function
    }
  },
  data: function () {
    return {
      visibleDialog: false
    }
  },
  computed: {
    valuesArray() {
      // Return an array of values from selectedKey
      return Object.values(this.selectedKey).join(', ')
    }
  },
  methods: {
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog
    },
    copyDatatoTheAddress(shouldOverride) {
      this.visibleDialog = !this.visibleDialog
      this.$emit('copyDataToAddress', this.field, shouldOverride)
    }
  }
}
</script>
<style lang="scss" scoped>
.button {
  background: #535eeb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
  opacity: 1;
  text-align: left;
  font: normal normal 600 14px/17px Quicksand;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
}
</style>
